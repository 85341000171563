import React from 'react';
import Cell from "../global/listitems/Cell";
import BoldText from "../global/listitems/BoldText";
import BoxText from "../global/listitems/BoxText";
import NormalText from "../global/listitems/NormalText";
import moment from "moment";
import RoundText from "../global/listitems/RoundText";
import DetailsButton from "../global/listitems/DetailsButton";
import Row from "../global/listitems/Row";

function ReservatieProblemenListItem({ reservatie}) {

    return (
        <Row>
            <Cell>
                <BoldText text={(reservatie.id + '').padStart(6, '0')}/>
            </Cell>
            <Cell>
                <BoxText text={reservatie.klant.vereniging}/>
            </Cell>
            <Cell>
                <NormalText
                    text={
                        !reservatie.betaald ?
                            `Reservatie nog niet betaald. (${moment().diff(reservatie.reservatiedatum, 'days') - 5} dagen te laat)` :
                            !reservatie.waarborgTerugbetaald && `Waarborg nog niet teruggestort. (${moment().diff(reservatie.einddatum, 'days') - 5} dagen te laat)`}/>
            </Cell>
            <Cell>
                <RoundText text={"B"} color={reservatie.betaald ? "green" : "red"}/>
                <RoundText text={"W"} color={reservatie.waarborgTerugbetaald ? "green" : "red"}/>
            </Cell>
            <Cell justify={"justify-end"}>
                <DetailsButton to={`/bekerverhuur/reservaties/${reservatie.id}`}/>
            </Cell>
        </Row>
    );
}

export default ReservatieProblemenListItem;
