import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import TabContainer from "../../../components/global/TabContainer";
import useUpsertDrankVdMaand from "../../../data/dranken/drankvdmaand/useUpsertDrankVdMaand";
import DrankVdMaandForm from "../../../components/dranken/drankenvdmaand/DrankVdMaandForm";
import useFetchDrankVdMaand from "../../../data/dranken/drankvdmaand/useFetchDrankVdMaand";

function DrankVdMaandEditPage() {
    const {maand, jaartal} = useParams();
    const [drank, setDrank] = useState({naam: "", prijs: 0, jaartal, maand, omschrijving: "", afbeelding: "", afbeeldingType: ""})
    const {isSuccess, data} = useFetchDrankVdMaand(jaartal, maand)
    const {mutate: updateDrank, isLoading: isUpdating, isSuccess: isUpdated} = useUpsertDrankVdMaand()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            setDrank(data)
        }
    }, [isSuccess, data])

    useEffect(() => {
        if (isUpdated) navigate("/dranken/drankenvdmaand")
    }, [isUpdated, navigate])

    return (
        <TabContainer>
            <h4>Drank van de maand wijzigen</h4>
            <DrankVdMaandForm
                buttonLabel={"Wijzigen"}
                drank={drank}
                setDrank={setDrank}
                action={updateDrank}
                isLoading={isUpdating}
            />
        </TabContainer>
    );
}

export default DrankVdMaandEditPage;
