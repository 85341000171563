import {useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const fetchTapkalenderWeek = (jaar, weeknummer) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/tapkalender/${jaar}/${weeknummer}`, {headers: authHeader()})
}

function useOpvragenTapkalenderWeek(jaar, weeknummer) {
    const {setAlert} = useAlerts()
    return useQuery(['fetchTapkalenderWeek', jaar, weeknummer], () => fetchTapkalenderWeek(jaar, weeknummer), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: (data => data.data)
    })
}

export default useOpvragenTapkalenderWeek
