import React from 'react';
import InputField from "../../global/forms/InputField";
import SelectField from "../../global/forms/SelectField";
import SubmitButton from "../../global/forms/SubmitButton";
import {validateAantal, validateDoosNaam, validateDoosType} from "../../../util/validator";
import {useAlerts} from "../../global/alerts/AlertContext";
import useFetchAllBekertypes from "../../../data/bekerverhuur/bekertypes/useFetchAllBekertypes";
import BackButton from "../../global/forms/BackButton";

function DoosForm({doos, setDoos, isLoading, action, buttonLabel}) {
    const {isSuccess: bekertypesIsSuccess, data: bekertypes} = useFetchAllBekertypes()
    const {setAlert} = useAlerts()

    const onSubmit = (e) => {
        e.preventDefault()
        if (controlData()) {
            action(doos)
        }
    }

    const onChange = (e) => {
        e.preventDefault();
        setDoos(old => ({...old, [e.target.name]: e.target.value}))
    }

    const controlData = () => {
        const naam = validateDoosNaam(doos.naam)
        if (!naam) {
            return setAlert("Ongeldige doosnaam.", "ERROR")
        } else {
            setDoos(old => ({...old, naam}))
        }
        const aantal = validateAantal(doos.aantalBekers)
        if (!aantal) {
            return setAlert("Ongeldig aantal bekers per doos.", "ERROR")
        } else {
            setDoos(old => ({...old, aantalBekers: parseInt(aantal)}))
        }
        const type = validateDoosType(doos.bekertypeNaam)
        if (!type) {
            return setAlert("Ongeldig bekertype.", "ERROR")
        }
        const aantalKeerGebruikt = validateAantal(doos.aantalKeerGebruikt, true)
        if (aantalKeerGebruikt === false) {
            return setAlert("Ongeldig aantal keer gebruikt.", "ERROR")
        }
        return true
    }

    return (
        <form>
            <div className={"mt-5 grid xl:grid-cols-2 xl:gap-6"}>
                <div className={"w-full"}>
                    <InputField
                        type={"text"}
                        label={"Naam"}
                        placeholder={"Naam..."}
                        name={"naam"}
                        value={doos.naam}
                        onChange={onChange}
                        required
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        type={"number"}
                        label={"Aantal bekers"}
                        placeholder={"Aantal bekers..."}
                        name={"aantalBekers"}
                        value={doos.aantalBekers}
                        min={"0"}
                        onChange={onChange}
                        required
                    />
                </div>
                <div className={"w-full"}>
                    <SelectField
                        name={"bekertypeNaam"}
                        label={"Bekertype"}
                        options={bekertypesIsSuccess ? bekertypes : []}
                        value={doos.bekertypeNaam}
                        onChange={onChange}
                        optionMapper={(option) => (<option key={option.naam} value={option.naam}>{option.naam}</option>)}
                        required
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        type={"number"}
                        label={"Aantal keer gebruikt"}
                        placeholder={"Aantal keer gebruikt..."}
                        name={"aantalKeerGebruikt"}
                        value={doos.aantalKeerGebruikt}
                        min={"0"}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>
            <div className={"flex flex-row"}>
                <BackButton
                    to={"/bekerverhuur/dozen"}
                />
                <SubmitButton
                    label={buttonLabel}
                    isLoading={isLoading}
                    onClick={onSubmit}
                />
            </div>
        </form>
    );
}

export default DoosForm;
