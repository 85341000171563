import React from 'react';
import TeBestellen from "../../../components/dranken/stocktelling/tebestellen/TeBestellen";
import Stockwaarde from "../../../components/dranken/stocktelling/stockwaarde/Stockwaarde";
import ListContainer from "../../../components/global/ListContainer";
import useFetchStocktellingen from "../../../data/dranken/stocktelling/useFetchStocktellingen";
import StocktellingListItem from "../../../components/dranken/stocktelling/stocktellingen/StocktellingListItem";
import {useUserInfo} from "../../../components/global/sidebar/UserContext";
import StocktellingListLoading from "../../../components/dranken/stocktelling/stocktellingen/StocktellingListLoading";
import useFetchBestellingen from "../../../data/dranken/stocktelling/useFetchBestellingen";
import BestellingListItem from "../../../components/dranken/stocktelling/bestellingen/BestellingListItem";

function StocktellingLandingPage() {
    const {permissies} = useUserInfo()

    return (
        <div className={"flex flex-col md:flex-none xl:grid xl:grid-cols-3 xl:gap-6"}>
            <div className={"xl:col-span-2"}>
                <TeBestellen/>
            </div>
            <div className={"xl:col-span-1"}>
                <Stockwaarde/>
            </div>
            <div className={"xl:col-span-3"}>
                <ListContainer
                    amountPerPage={3}
                    type={"Stocktellingen"}
                    addPath={"/dranken/stocktellingen/toevoegen"}
                    addPermissie={permissies.includes("stocktelling")}
                    dataFunction={useFetchStocktellingen}
                    listItemLambda={st => (
                        <StocktellingListItem key={st.datum} stocktelling={st}/>
                    )}
                    placeholderLambda={index => (
                        <StocktellingListLoading key={index}/>
                    )}
                />
            </div>
            <div className={"xl:col-span-3"}>
                <ListContainer
                    amountPerPage={3}
                    type={"Bestellingen"}
                    addPath={"/dranken/bestellingen/toevoegen"}
                    addPermissie={permissies.includes("stocktelling")}
                    dataFunction={useFetchBestellingen}
                    listItemLambda={b => (
                        <BestellingListItem key={b.datum} bestelling={b}/>
                    )}
                    placeholderLambda={index => (
                        <StocktellingListLoading key={index}/>
                    )}
                />
            </div>
        </div>
    );
}

export default StocktellingLandingPage;
