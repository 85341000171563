import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import authHeader from "../../util/auth-header";
import {useAlerts} from "../../components/global/alerts/AlertContext";

const addEvenement = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/evenementen`, data, {headers: authHeader()})
}

function useAddEvenement() {
    const {setAlert} = useAlerts()
    const queryClient = useQueryClient()
    return useMutation('addEvenement', addEvenement, {
        onSuccess: (data) => {
            setAlert(data?.data?.message, "SUCCESS")
            queryClient.invalidateQueries('fetchEvenementen')
        },
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        }
    })
}

export default useAddEvenement
