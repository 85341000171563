import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import TabContainer from "../../components/global/TabContainer";
import EvenementForm from "../../components/evenementen/EvenementenForm";
import moment from "moment";
import useAddEvenement from "../../data/evenementen/useAddEvenement";

function EvenementAddPage() {
    const [evenement, setEvenement] = useState({
        naam: "",
        datum: moment().format("YYYY-MM-DD"),
        categorieId: "",
        afbeelding: undefined,
        afbeeldingType: "",
        toevoegenKalender: true,
    })
    const navigate = useNavigate()
    const {mutate: createEvenement, isLoading, isSuccess: isAdded} = useAddEvenement()

    useEffect(() => {
        if (isAdded) navigate("/evenementen")
    }, [isAdded, navigate])

    return (
        <TabContainer>
            <h4>Evenement toevoegen</h4>
            <EvenementForm
                evenement={evenement}
                setEvenement={setEvenement}
                buttonLabel={"Toevoegen"}
                action={createEvenement}
                isLoading={isLoading}
            />
        </TabContainer>
    );
}

export default EvenementAddPage;
