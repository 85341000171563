import React, {Fragment} from 'react';
import TabContainer from "../global/TabContainer";
import {useFetchAandatchReservaties} from "../../data/bekerverhuur/reservaties/useFetchAandachtReservaties";
import ReservatieProblemenListItem from "./ReservatieProblemenListItem";
import ReservatieProblemenListLoading from "./ReservatieProblemenListLoading";

function ReservatieProblemen() {
    const {data: reservaties, isSuccess, isLoading, isError} = useFetchAandatchReservaties()

    return (
        <TabContainer>
            <h4 className={"mb-6"}>Reservaties die aandacht vereisen</h4>
            <div className="overflow-y-auto overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                    <tbody>
                    {isLoading && (
                        <Fragment>
                            <ReservatieProblemenListLoading/>
                            <ReservatieProblemenListLoading/>
                            <ReservatieProblemenListLoading/>
                            <ReservatieProblemenListLoading/>
                            <ReservatieProblemenListLoading/>
                        </Fragment>
                    )}
                    {!isLoading && isSuccess && reservaties.length > 0 && reservaties.map(r => (
                        <ReservatieProblemenListItem reservatie={r} key={r.id}/>
                    ))}
                    {!isLoading && isSuccess && reservaties.length === 0 && (
                        <tr>
                            <td>
                                <p className={"text-gray-800 dark:text-gray-200"}>Geen reservaties gevonden.</p>
                            </td>
                        </tr>
                    )}
                    {(!isLoading && isError) && (
                        <tr>
                            <td>
                                <p className={"text-gray-800 dark:text-gray-200"}>Oeps! Er liep iets mis bij het ophalen van de reservaties.</p>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </TabContainer>
    );
}

export default ReservatieProblemen;
