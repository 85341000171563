import React from 'react';
import InputField from "../../global/forms/InputField";
import SubmitButton from "../../global/forms/SubmitButton";
import {useAlerts} from "../../global/alerts/AlertContext";
import BackButton from "../../global/forms/BackButton";
import AddBestuurslidToArray from "../../global/forms/AddBestuurslidToArray";
import {validateInkomsten, validateTappers, validateTapPrestatieDatum} from "../../../util/validator";
import TextAreaField from "../../global/forms/TextAreaField";
import SelectField from "../../global/forms/SelectField";
import useFetchActieveBestuursleden from "../../../data/bestuursleden/useFetchActieveBestuursleden";

function TapPrestatieForm({tapprestatie, setTapprestatie, isLoading, action, buttonLabel}) {
    console.log(tapprestatie)
    const {setAlert} = useAlerts()
    const {isSuccess: bestuursledenIsSuccess, data: bestuursleden} = useFetchActieveBestuursleden()
    const onChange = (e) => {
        setTapprestatie(old => ({...old, [e.target.name]: e.target.value}))
    }

    const onClick = (e) => {
        e.preventDefault()
        if (controlData()) {
            action(tapprestatie)
        }
    }

    const controlData = () => {
        const datum = validateTapPrestatieDatum(tapprestatie.datum)
        if (!datum) {
            setAlert("Ongeldige datum, datum mag niet in de toekomst liggen.", "ERROR")
            return false
        }
        const inkomsten = validateInkomsten(tapprestatie.inkomsten)
        if (!inkomsten) {
            setAlert("Ongeldige inkomsten.", "ERROR")
            return false
        }
        const tappers = validateTappers(tapprestatie.tappers)
        if (!tappers) {
            setAlert("Ongeldig aantal tappers, minimum 1 en maximum 12.", "ERROR")
            return false
        }
        return true
    }

    const addTapper = (discordId) => {
        setTapprestatie(old => ({...old, tappers: [...old.tappers, discordId]}))
    }

    const resetTappers = () => {
        setTapprestatie(old => ({...old, tappers: []}))
    }

    return (
        <form>
            <div className={"mt-5 grid xl:grid-cols-2 xl:gap-6"}>
                <div className={"w-full"}>
                    <InputField
                        label={"Datum"}
                        name={"datum"}
                        type={"date"}
                        placeholder={"Datum..."}
                        value={tapprestatie.datum}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        name={"inkomsten"}
                        label={"Inkomsten"}
                        type={"number"}
                        placeholder={"Inkomsten..."}
                        onChange={onChange}
                        value={tapprestatie.inkomsten}
                    />
                </div>
                <div className={"w-full"}>
                    <TextAreaField
                        name={"opmerking"}
                        label={"Opmerking"}
                        placeholder={"Opmerking..."}
                        onChange={onChange}
                        value={tapprestatie.opmerking ? tapprestatie.opmerking : ""}
                    />
                </div>
                <div className={"w-full"}>
                    <AddBestuurslidToArray
                        label={"Tappers"}
                        name={"tappers"}
                        selected={tapprestatie.tappers}
                        addSelected={addTapper}
                        resetSelected={resetTappers}
                    />
                </div>
                <div className={"w-full"}>
                    <SelectField
                        name={"geldmeeBestuurslidId"}
                        label={"Geld mee"}
                        options={bestuursledenIsSuccess ? bestuursleden : []}
                        value={tapprestatie.geldmeeBestuurslidId}
                        onChange={onChange}
                        optionMapper={(option) => (<option key={option.discordId}
                                                           value={option.discordId}>{option.voornaam} {option.familienaam}</option>)}
                        required
                    />
                </div>
            </div>
            <div className={"flex flex-row"}>
                <BackButton to={"/tappen/tapprestaties"}/>
                <SubmitButton
                    label={buttonLabel}
                    isLoading={isLoading}
                    onClick={onClick}
                />
            </div>
        </form>);
}

export default TapPrestatieForm;
