import React from 'react';
import Logo from "../icons/Logo";

function SidebarLogo() {
    return (
        <div className="hidden md:flex justify-center">
            <div className="cursor-pointer flex items-center">
                <Logo className="w-24 h-24 stroke-current fill-current text-gray-800 dark:stroke-gray-100 dark:fill-gray-100"/>
                <h1 className="text-2xl text-gray-800 dark:text-gray-100 tracking-wide ml-2 font-semibold">
                    Onder de Toren vzw
                </h1>
            </div>
        </div>
    );
}

export default SidebarLogo;
