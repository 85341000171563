import {useMutation} from "react-query";
import axios from "axios";
import authHeader from "../../util/auth-header";
import {useAlerts} from "../../components/global/alerts/AlertContext";
import {useUserInfo} from "../../components/global/sidebar/UserContext";

const changePermissies = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/permissies`, data, {headers: authHeader()})
}

function useChangePermissies() {
    const {setAlert} = useAlerts()
    const {refetch} = useUserInfo()
    return useMutation('changePermissies', changePermissies, {
        onSuccess: (data) => {
            setAlert(data?.data?.message, "SUCCESS")
            refetch()
        },
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        }
    })
}

export default useChangePermissies
