import React from 'react';

function Row({children, loading = false}) {
    return (
        <tr className={`focus:outline-none h-16 border border-gray-100 rounded dark:border-gray-700 w-full ${loading && "animate-pulse"}`}>
            {children}
        </tr>
    );
}

export default Row;
