import {useMutation} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";

const fetchBerschikbareBekers = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/dozen/beschikbaar`, data,{headers: authHeader()})
}

export const useFetchBeschikbareBekers = (onSuccess, onError) => {
    return useMutation('fetchBeschikbareBekers', fetchBerschikbareBekers, {
        onSuccess,
        onError,
    })
}
