import React, {useEffect, useState} from 'react';
import TabContainer from "../../../components/global/TabContainer";
import useFetchEenheden from "../../../data/dranken/stocktelling/useFetchEenheden";
import Loading from "../../../components/global/Loading";
import ConsumptieItem from "../../../components/dranken/stocktelling/stocktellingen/ConsumptieItem";
import SubmitButton from "../../../components/global/forms/SubmitButton";
import useCreateStocktelling from "../../../data/dranken/stocktelling/useCreateStocktelling";
import {useNavigate} from "react-router-dom";
import BackButton from "../../../components/global/forms/BackButton";
import TextAreaField from "../../../components/global/forms/TextAreaField";

function StocktellingAddPage() {
    const navigate = useNavigate()
    const [consumpties, setConsumpties] = useState([])
    const [opmerking, setOpmerking] = useState("")
    const {data: eenheden, isSuccess: isLoaded} = useFetchEenheden()
    const {mutate: createStocktelling, isLoading, isSuccess} = useCreateStocktelling()

    useEffect(() => {
        if (isLoaded) {
            setConsumpties(eenheden)
        }
    }, [isLoaded])

    useEffect(() => {
        if (isSuccess) {
            navigate("/dranken/stocktellingen")
        }
    }, [isSuccess])

    const onChange = (cNaam, eNaam, e) => {
        let res = consumpties
        let conIndex = res.findIndex(c => c.naam === cNaam)
        let eenIndex = res[conIndex].eenheden.findIndex(e => e.type.naam === eNaam)
        res[conIndex].eenheden[eenIndex][e.target.name] = parseInt(e.target.value)
        setConsumpties(res)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        createStocktelling({datum: new Date(), opmerking, consumpties})
    }

    if (!isLoaded) {
        return (
            <TabContainer>
                <h5>Stocktelling toevoegen</h5>
                <Loading/>
            </TabContainer>
        )
    }

    return (
        <TabContainer>
            <h5>Stocktelling toevoegen</h5>
            {consumpties.map(c => (<ConsumptieItem key={c.naam} consumptie={c} onChange={onChange}/>))}
            <div
                className={"duration-300 rounded-md border border-gray-200 shadow-sm hover:shadow-md my-4 dark:border-none dark:bg-gray-700 p-4"}>
                <h6 className={"font-medium dark:text-gray-200 mb-4"}>Opmerking</h6>
                <TextAreaField
                    label={""}
                    placeholder={"Opmerking..."}
                    name={"opmerking"}
                    value={opmerking}
                    onChange={(e) => setOpmerking(e.target.value)}
                />
            </div>
            <div className={"flex flex-row"}>
                <BackButton to={"/dranken/stocktellingen"}/>
                <SubmitButton label={"Toevoegen"} isLoading={isLoading} onClick={handleSubmit}/>
            </div>
        </TabContainer>
    );
}

export default StocktellingAddPage;
