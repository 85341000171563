import React from 'react';

function TabContainer({children}) {
    return (
        <div className="w-full h-full border-gray-300 bg-white rounded-xl shadow-lg p-5 mb-5 dark:border-gray-700 dark:bg-gray-800 hover:shadow-xl duration-300">
            {children}
        </div>
    );
}

export default TabContainer;
