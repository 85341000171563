import React from 'react';
import Cell from "../../global/listitems/Cell";
import RoundImageLoading from "../../global/listitems/loading/RoundImageLoading";
import BoldTextLoading from "../../global/listitems/loading/BoldTextLoading";
import BoxTextLoading from "../../global/listitems/loading/BoxTextLoading";
import NormalTextLoading from "../../global/listitems/loading/NormalTextLoading";
import OptionsButtonLoading from "../../global/listitems/loading/OptionsButtonLoading";
import Row from "../../global/listitems/Row";

function DrankVdMaandListLoading() {
    return (
        <Row loading={true}>
            <Cell>
                <RoundImageLoading/>
            </Cell>
            <Cell>
                <BoldTextLoading/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-24"}/>
            </Cell>
            <Cell>
                <NormalTextLoading width={Math.random() < .7 ? "w-[40px]" : "w-[45px]"}/>
            </Cell>
            <Cell>
                <NormalTextLoading/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default DrankVdMaandListLoading;
