import React from 'react';
import TabContainer from "../../../global/TabContainer";
import Loading from "../../../global/Loading";
import useFetchTeBestellen from "../../../../data/dranken/stocktelling/useFetchTeBestellen";
import TeBestellenListItem from "./TeBestellenListItem";
import {useNavigate} from "react-router-dom";
import Button from "../../../global/Button";

function TeBestellen() {
    const {data: stocktelling, isSuccess} = useFetchTeBestellen()
    const navigate = useNavigate()

    if (!isSuccess) {
        return (
            <TabContainer>
                <h4>Bij te bestellen consumpties</h4>
                <Loading/>
            </TabContainer>
        )
    }

    if (stocktelling.length === 0) {
        return (
            <TabContainer>
                <h4>Bij te bestellen consumpties</h4>
                <p className={"text-gray-800 dark:text-gray-300 mt-4"}>Geen consumpties gevonden.</p>
            </TabContainer>
        )
    }

    return (
        <TabContainer>
            <div className={"flex flex-row justify-between mb-3"}>
                <h4>Bij te bestellen consumpties</h4>
                <Button label={"Bestelling plaatsen"} onClick={() => {
                    navigate("/dranken/bestellingen/toevoegen")
                }}/>
            </div>
            <div className="overflow-y-scroll h-72">
                <table className="w-full whitespace-nowrap">
                    <tbody>
                    {stocktelling.map(e => (<TeBestellenListItem key={e.consumptie + e.type} eenheid={e}/>))}
                    </tbody>
                </table>
            </div>
        </TabContainer>
    );
}

export default TeBestellen;
