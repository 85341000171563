import React from 'react';
import Row from "../global/listitems/Row";
import Cell from "../global/listitems/Cell";
import NormalText from "../global/listitems/NormalText";
import RoundImage from "../global/listitems/RoundImage";
import BoldText from "../global/listitems/BoldText";
import BoxText from "../global/listitems/BoxText";
import moment from "moment";
import OptionsButton from "../global/listitems/OptionsButton";
import RoundText from "../global/listitems/RoundText";
import Tooltip from "../global/listitems/Tooltip";
import {useNavigate} from "react-router-dom";
import useChangeZichtbaarheidEvenement from "../../data/evenementen/useChangeZichtbaarheidEvenement";
import useDeleteEvenement from "../../data/evenementen/useDeleteEvenement";
import useCheckPermissies from "../../hooks/useCheckPermissies";
import DetailsButton from "../global/listitems/DetailsButton";
import MenuButton from "../global/listitems/MenuButton";
import {useFetchEvenementStatus} from "../../data/evenementen/useFetchEvenementStatus";
import useChangeAanvraagstatusEvenement from "../../data/evenementen/useChangeAanvraagstatusEvenement";

function EvenementListItem({evenement}) {
    const navigate = useNavigate()
    const {mutate: changeZichtbaarheid} = useChangeZichtbaarheidEvenement()
    const {mutate: changeStatus} = useChangeAanvraagstatusEvenement()
    const {mutate: deleteEvenement} = useDeleteEvenement(evenement.id)
    const statuses = useFetchEvenementStatus()
    const status = {
        'NIET_AANGEVRAAGD': "red",
        'IN_BEHANDELING': "orange",
        'GOEDGEKEURD': "green",
        'AFGEKEURD': "red"
    }

    const options = [
        {
            action: () => navigate(`/evenementen/${evenement.id}/wijzigen`),
            label: "Wijzig",
            condition: useCheckPermissies("wijzigEvenement")
        },
        {
            action: () => changeZichtbaarheid({id: evenement.id, zichtbaar: !evenement.zichtbaar}),
            label: evenement.zichtbaar ? "Maak onzichtbaar" : "Maak zichtbaar",
            condition: useCheckPermissies("wijzigEvenement")
        }, {
            action: () => deleteEvenement(),
            label: "Verwijder",
            condition: useCheckPermissies("verwijderEvenement")
        }
    ]

    const updateStatus = (status) => {
        changeStatus({id: evenement.id, status: status})
    }

    return (
        <Row>
            <Cell>
                <RoundImage source={evenement.afbeelding} alt={evenement.naam}/>
            </Cell>
            <Cell>
                <BoldText text={evenement.naam}/>
            </Cell>
            <Cell>
                <NormalText text={moment(evenement.datum).format("DD/MM/YYYY")}/>
            </Cell>
            <Cell>
                <BoxText text={evenement.categorie.naam} width={"w-32"}/>
            </Cell>
            <Cell>
                <MenuButton
                    text={evenement.status}
                    color={status[evenement.status]}
                    updateOption={updateStatus}
                    options={statuses}
                    disabled={!useCheckPermissies("wijzigEvenement")}
                />
            </Cell>
            <Cell>
                <Tooltip tooltipText={evenement.zichtbaar ? "Zichtbaar" : "Niet-zichtbaar"}>
                    <RoundText text={"Z"} color={evenement.zichtbaar ? "green" : "red"}/>
                </Tooltip>
            </Cell>
            <Cell>
                <DetailsButton to={`/evenementen/${evenement.id}`}/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButton options={options}/>
            </Cell>
        </Row>
    );
}

export default EvenementListItem;
