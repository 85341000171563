import React from 'react';
import moment from "moment";
import {useFetchReservatiestatus} from "../../../data/bekerverhuur/reservaties/useFetchReservatiestatus";
import useChangeReservatieStatus from "../../../data/bekerverhuur/reservaties/useChangeReservatieStatus";
import useChangeReservatieBetaalstatus from "../../../data/bekerverhuur/reservaties/useChangeReservatieBetaalstatus";
import useChangeReservatieWaarborgstatus
    from "../../../data/bekerverhuur/reservaties/useChangeReservatieWaarborgstatus";
import Row from "../../global/listitems/Row";
import Cell from "../../global/listitems/Cell";
import BoldText from "../../global/listitems/BoldText";
import NormalText from "../../global/listitems/NormalText";
import RoundText from "../../global/listitems/RoundText";
import DetailsButton from "../../global/listitems/DetailsButton";
import OptionsButton from "../../global/listitems/OptionsButton";
import MenuButton from "../../global/listitems/MenuButton";
import Tooltip from "../../global/listitems/Tooltip";
import useCheckPermissies from "../../../hooks/useCheckPermissies";

function ReservatieListItem({reservatie}) {
    const status = {
        'AANGEVRAAGD': "blue",
        'GEANNULEERD': "red",
        'IN_BEHANDELING': "orange",
        'AFGEHANDELD': "green"
    }
    const statuses = useFetchReservatiestatus()
    const {mutate: changeStatus} = useChangeReservatieStatus()
    const {mutate: changeBetaalstatus} = useChangeReservatieBetaalstatus()
    const {mutate: changeWaarborgstatus} = useChangeReservatieWaarborgstatus()

    const options = [
        {
            action: () => changeBetaalstatus({id: reservatie.id}),
            label: "Markeer als betaald",
            condition: useCheckPermissies("wijzigReservatie") && !reservatie.betaald
        }, {
            action: () => changeWaarborgstatus({id: reservatie.id}),
            label: "Markeer als waarborg teruggestort",
            condition: useCheckPermissies("wijzigReservatie") && (reservatie.waarborgTerugbetaald ? null : reservatie?.betaald)
        }
    ]

    const updateStatus = (status) => {
        changeStatus({id: reservatie.id, status: status})
    }

    return (
        <Row>
            <Cell>
                <BoldText text={(reservatie.id + '').padStart(6, '0')}/>
            </Cell>
            <Cell>
                <NormalText
                    text={`${moment(reservatie.begindatum).format("DD/MM/YYYY")} - ${moment(reservatie.einddatum).format("DD/MM/YYYY")}`}/>
            </Cell>
            <Cell>
                <NormalText text={`${reservatie.klant.voornaam} ${reservatie.klant.familienaam}`}/>
            </Cell>
            <Cell>
                <MenuButton
                    text={reservatie.status}
                    color={status[reservatie.status]}
                    updateOption={updateStatus}
                    options={statuses}
                    disabled={!useCheckPermissies("wijzigReservatie") || reservatie.status === "AFGEHANDELD" || reservatie.status === "GEANNULEERD"}
                />
            </Cell>
            <Cell>
                <Tooltip tooltipText={"Betaald"}>
                    <RoundText text={'B'} color={reservatie.betaald ? "green" : "red"}/>
                </Tooltip>
                <Tooltip tooltipText={"Waarborg"}>
                    <RoundText text={'W'} color={reservatie.waarborgTerugbetaald ? "green" : "red"}/>
                </Tooltip>
            </Cell>
            <Cell>
                <DetailsButton to={`/bekerverhuur/reservaties/${reservatie.id}`}/>
            </Cell>
            <Cell justify={"justify-end"}>
                {(reservatie.betaald && reservatie.waarborgTerugbetaald) || reservatie.status === "GEANNULEERD" ? null : (
                    <OptionsButton options={options}/>
                )}
            </Cell>
        </Row>
    );
}

export default ReservatieListItem;
