import React from 'react';
import Row from "../../../global/listitems/Row";
import Cell from "../../../global/listitems/Cell";
import BoldText from "../../../global/listitems/BoldText";
import NormalText from "../../../global/listitems/NormalText";

function BestellingDetailListItem({consumptie}) {
    const aantal = `${consumptie.aantal} ${consumptie.aantal > 1 ? consumptie.consumptieEenheid.type.meervoudNaam : consumptie.consumptieEenheid.type.enkelvoudNaam}`
    return (
        <Row>
            <Cell>
                <BoldText text={consumptie.consumptieEenheid.consumptie.naam}/>
            </Cell>
            <Cell>
                <NormalText text={consumptie.consumptieEenheid.type.naam}/>
            </Cell>
            <Cell>
                <NormalText text={aantal}/>
            </Cell>
        </Row>
    );
}

export default BestellingDetailListItem;
