import {useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../util/auth-header";
import {useAlerts} from "../../components/global/alerts/AlertContext";

const fetchEvenementen = (pagina, filter) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/evenementen?limit=10&pagina=${pagina}&zoeken=${filter}`, {headers: authHeader()})
}

function useFetchEvenementen(pagina, filter) {
    const {setAlert} = useAlerts()
    return useQuery(['fetchEvenementen', pagina, filter], () => fetchEvenementen(pagina, filter), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: data => data.data
    })
}

export default useFetchEvenementen
