import {useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const fetchTapPrestaties = (pagina, filter) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/tapprestaties?limit=10&pagina=${pagina}&zoeken=${filter}`, {headers: authHeader()})
}

function useFetchTapPrestaties(pagina = 1, filter) {
    const {setAlert} = useAlerts()
    return useQuery(['fetchTapPrestaties', pagina, filter], () => fetchTapPrestaties(pagina, filter), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: (data => data.data)
    })
}

export default useFetchTapPrestaties
