import React from 'react';
import TabContainer from "../../../global/TabContainer";
import useFetchStockwaarde from "../../../../data/dranken/stocktelling/useFetchStockwaarde";
import Loading from "../../../global/Loading";
import StockwaardePieChart from "./StockwaardePieChart";
import moment from "moment";

function Stockwaarde() {
    const {data: stockwaarde, isLoading, isError, error} = useFetchStockwaarde()
    if (isLoading) {
        return (
            <TabContainer>
                <h4>Stockwaarde</h4>
                <Loading/>
            </TabContainer>
        )
    }

    if (isError) {
        return (
            <TabContainer>
                <h4>Stockwaarde</h4>
                <p className={"text-gray-800 dark:text-gray-300 mt-4"}>{error?.response?.data?.message}</p>
            </TabContainer>
        )
    }

    return (
        <TabContainer>
            <h4>Stockwaarde ({moment(stockwaarde.datum).format("DD/MM/YYYY")})</h4>
            <StockwaardePieChart data={stockwaarde.chart}/>
        </TabContainer>
    );
}

export default Stockwaarde;
