import React from 'react';
import Cell from "../../global/listitems/Cell";
import BoldText from "../../global/listitems/BoldText";
import HeaderRow from "../../global/listitems/HeaderRow";

function TapPrestatieHeaderRow() {
    return (
        <HeaderRow>
            <Cell>
                <BoldText text={"Datum"}/>
            </Cell>
            <Cell>
                <BoldText text={"Inkomsten"}/>
            </Cell>
            <Cell>
                <BoldText text={"Tappers"}/>
            </Cell>
            <Cell>
                <BoldText text={"Opmerkingen"}/>
            </Cell>
            <Cell>
                <BoldText text={"Geld mee"}/>
            </Cell>
            <Cell>
            </Cell>
            <Cell>
            </Cell>
        </HeaderRow>
    );
}

export default TapPrestatieHeaderRow;
