import React from 'react';
import Cell from "../../../global/listitems/Cell";
import BoldText from "../../../global/listitems/BoldText";
import moment from "moment";
import NormalText from "../../../global/listitems/NormalText";
import {capitalizeFirstLetter} from "../../../../util/formatter";
import DetailsButton from "../../../global/listitems/DetailsButton";
import Row from "../../../global/listitems/Row";
import RoundText from "../../../global/listitems/RoundText";
import Tooltip from "../../../global/listitems/Tooltip";
import OptionsButton from "../../../global/listitems/OptionsButton";
import useCheckPermissies from "../../../../hooks/useCheckPermissies";
import useSetBestellingGeleverd from "../../../../data/dranken/stocktelling/useSetBestellingGeleverd";

function BestellingListItem({bestelling}) {
    const {mutate: setGeleverd} = useSetBestellingGeleverd(bestelling.id)

    const options = [
        {
            action: () => {setGeleverd(bestelling.id)},
            label: "Markeer als geleverd",
            condition: useCheckPermissies("stocktelling") && !bestelling.geleverd
        },
    ]

    return (
        <Row>
            <Cell>
                <BoldText text={moment(bestelling.datum).format("DD/MM/YYYY")}/>
            </Cell>
            <Cell>
                <NormalText text={bestelling.opmerking ? capitalizeFirstLetter(bestelling.opmerking) : "Geen opmerkingen"}/>
            </Cell>
            <Cell>
                <Tooltip tooltipText={bestelling.geleverd ? "Geleverd" : "Niet geleverd"}>
                    <RoundText text={"G"} color={bestelling.geleverd ? "green" : "red"}/>
                </Tooltip>
            </Cell>
            <Cell>
                <DetailsButton to={`/dranken/bestellingen/${bestelling.id}`}/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButton options={options}/>
            </Cell>
        </Row>
    );
}

export default BestellingListItem;
