import React, {createContext, useContext, useState} from 'react';

const AlertContext = createContext(null);

export const AlertContextProvider = ({children}) => {
    const [type, setType] = useState("OTHER")
    const [message, setMessage] = useState("")
    const [isShown, setIsShown] = useState(false)

    const showAlert = () => {
        setIsShown(true)
        setTimeout(() => {
            setIsShown(false)
        }, 5000)
    }

    const setAlert = (msg, type = "OTHER") => {
        setMessage(msg)
        setType(type)
        showAlert()
        return false
    }

    const dismiss = () => {
        setIsShown(false)
    }

    return (
        <AlertContext.Provider value={{type, message, isShown, setAlert, dismiss}}>
            {children}
        </AlertContext.Provider>
    )
}

export const useAlerts = () => useContext(AlertContext)
