import React from 'react';
import ListContainer from "../../../components/global/ListContainer";
import useFetchDrankenVdMaand from "../../../data/dranken/drankvdmaand/useFetchDrankenVdMaand";
import DrankVdMaandListItem from "../../../components/dranken/drankenvdmaand/DrankVdMaandListItem";
import useCheckPermissies from "../../../hooks/useCheckPermissies";
import DrankVdMaandListLoading from "../../../components/dranken/drankenvdmaand/DrankVdMaandListLoading";

function DrankVdMaandListPage() {
    return (
        <ListContainer
            type={"Drank van de maand"}
            addPath={"/dranken/drankenvdmaand/toevoegen"}
            addPermissie={useCheckPermissies("upsertDrankVdMaand")}
            useFilter={true}
            dataFunction={useFetchDrankenVdMaand}
            listItemLambda={drank => (
                <DrankVdMaandListItem key={drank.naam} drank={drank}/>
            )}
            placeholderLambda={index => (
                <DrankVdMaandListLoading key={index}/>
            )}
        />
    );
}

export default DrankVdMaandListPage;
