export const capitalizeFirstLetter = (string) => {
    return (string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()).replace("_", " ");
}

export const titleCaseString = (str) => {
    let splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

export const randomIntFromInterval = (min, max) => { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
};
