import React, {useState} from 'react';
import StevenJambon from "./StevenJambon";

function MadeBy() {
    const [emoji, setEmoji] = useState("❤️")

    const randomEmoji = () => {
        const emojis = ["❤️", "💙", "💜", "💛", "💚", "🧡", "🤍", "🤎", "🖤"]
        return emojis[Math.floor(Math.random() * emojis.length)]
    }

    const changeEmoji = () => {
        setEmoji(randomEmoji())
    }

    return (
        <div className={"justify-end flex flex-row items-center container"}>
            <p className={"text-gray-700 dark:text-gray-400 select-none"}>Gemaakt met <span
                onMouseEnter={changeEmoji}
                onClick={changeEmoji}
                className={"cursor-default select-none"}
            >{emoji}</span>️
                door</p><StevenJambon/>
        </div>
    );
}

export default MadeBy;
