import React from 'react';
import InputField from "../global/forms/InputField";
import BackButton from "../global/forms/BackButton";
import SubmitButton from "../global/forms/SubmitButton";
import SelectField from "../global/forms/SelectField";
import useFetchBestuursafdelingen from "../../data/bestuursleden/useFetchBestuursafdelingen";
import {
    validateAfdelingId,
    validateDiscordId,
    validateEmail,
    validateFamilienaam,
    validateFunctie, validateGeboortedatum, validatePhoneNumber,
    validateVoornaam
} from "../../util/validator";
import {useAlerts} from "../global/alerts/AlertContext";
import ImageUploadField from "../global/forms/ImageUploadField";

function BestuurslidForm({bestuurslid, setBestuurslid, isLoading, action, buttonLabel, update = false}) {
    const {isSuccess: afdelingenIsSuccess, data: afdelingen} = useFetchBestuursafdelingen()
    const {setAlert} = useAlerts()
    const onChange = (e) => {
        setBestuurslid(old => ({...old, [e.target.name]: e.target.value}))
    }

    const onClick = (e) => {
        e.preventDefault()
        if (controlData()) {
            action(bestuurslid)
        }
    }

    const controlData = () => {
        const voornaam = validateVoornaam(bestuurslid.voornaam)
        if (!voornaam) {
            setAlert("Ongeldige voornaam.", "ERROR")
            return false
        }
        const familienaam = validateFamilienaam(bestuurslid.familienaam)
        if (!familienaam) {
            setAlert("Ongeldige familienaam.", "ERROR")
            return false
        }
        const email = validateEmail(bestuurslid.email)
        if (!email) {
            setAlert("Ongeldig e-mailadres.", "ERROR")
            return false
        }
        const discordId = validateDiscordId(bestuurslid.discordId)
        if (!discordId) {
            setAlert("Ongeldig discordId.", "ERROR")
            return false
        }
        if (bestuurslid.priveEmail) {
            const priveEmail = validateEmail(bestuurslid.priveEmail)
            if (!priveEmail) {
                return setAlert("Ongeldig privé e-mailadres.", "ERROR")
            }
        }
        if (bestuurslid.priveTel) {
            const priveTel = validatePhoneNumber(bestuurslid.priveTel)
            if (!priveTel) {
                return setAlert("Ongeldige privé telefoonnummer", "ERROR")
            } else {
                setBestuurslid(old => ({...old, priveTel}))
            }
        }
        if (bestuurslid.geboortedatum) {
            const geboortedatum = validateGeboortedatum(bestuurslid.geboortedatum)
            if (!geboortedatum) {
                return setAlert("Ongeldige geboortedatum", "ERROR")
            }
        }
        const afdelingId = validateAfdelingId(bestuurslid.afdelingId)
        if (!afdelingId) {
            setAlert("Ongeldige afdeling", "ERROR")
            return false
        }
        const functie = validateFunctie(bestuurslid.functie)
        if (!functie) {
            setAlert("Ongeldige functie", "ERROR")
            return false
        }
        return true
    }

    return (
        <form>
            <div className={"mt-5 grid xl:grid-cols-2 xl:gap-6"}>
                <div className={"w-full"}>
                    <InputField
                        label={"Voornaam"}
                        name={"voornaam"}
                        type={"text"}
                        placeholder={"voornaam..."}
                        value={bestuurslid.voornaam}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Familienaam"}
                        name={"familienaam"}
                        type={"text"}
                        placeholder={"familienaam..."}
                        value={bestuurslid.familienaam}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"E-mailadres"}
                        name={"email"}
                        type={"email"}
                        placeholder={"e-mailadres..."}
                        value={bestuurslid.email}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Discord ID"}
                        name={"discordId"}
                        type={"text"}
                        placeholder={"discord ID..."}
                        disabled={update}
                        value={bestuurslid.discordId}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Geboortedatum"}
                        name={"geboortedatum"}
                        type={"date"}
                        placeholder={"geboortedatum..."}
                        value={bestuurslid.geboortedatum ? bestuurslid.geboortedatum : ""}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Privé e-mailadres"}
                        name={"priveEmail"}
                        type={"email"}
                        placeholder={"e-mailadres..."}
                        value={bestuurslid.priveEmail ? bestuurslid.priveEmail : ""}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Privé telefoonnummer"}
                        name={"priveTel"}
                        type={"text"}
                        placeholder={"telefoonnummer..."}
                        value={bestuurslid.priveTel ? bestuurslid.priveTel : ""}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <SelectField
                        name={"afdelingId"}
                        label={"Afdeling"}
                        options={afdelingenIsSuccess ? afdelingen : []}
                        value={bestuurslid.afdelingId}
                        onChange={onChange}
                        optionMapper={(option) => (<option key={option.id} value={option.id}>{option.naam}</option>)}
                        required
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Functie"}
                        name={"functie"}
                        type={"text"}
                        placeholder={"functie..."}
                        value={bestuurslid.functie}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <ImageUploadField
                        label={"Afbeelding"}
                        name={"afbeelding"}
                        value={bestuurslid.afbeelding}
                        onChange={(afbeelding, type) => setBestuurslid(old => ({
                            ...old,
                            afbeelding: afbeelding,
                            afbeeldingType: type
                        }))}
                    />
                </div>
            </div>
            <div className={"flex flex-row"}>
                <BackButton
                    to={"/bestuursleden"}
                />
                <SubmitButton
                    label={buttonLabel}
                    isLoading={isLoading}
                    onClick={onClick}
                />
            </div>
        </form>);
}

export default BestuurslidForm;
