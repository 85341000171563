import React from 'react';
import {Link} from "react-router-dom";

function DetailsButton({to = "/dashboard"}) {
    return (
        <Link
            to={to}
            className="duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300 text-sm leading-none text-gray-600 py-3 px-5 bg-gray-100 rounded hover:bg-gray-200 focus:outline-none dark:text-gray-300 dark:bg-gray-700 dark:hover:bg-gray-900">Details
        </Link>
    );
}

export default DetailsButton;
