import React, {useEffect, useState} from 'react';
import TabContainer from "../../../components/global/TabContainer";
import BekertypeForm from "../../../components/bekerverhuur/bekertypes/BekertypeForm";
import useAddBekertype from "../../../data/bekerverhuur/bekertypes/useAddBekertype";
import {useNavigate} from "react-router-dom";

function BekertypeAddPage() {
    const [bekertype, setBekertype] = useState({
        naam: "",
        aantalBekersPerDoos: 0,
        huurprijs: 0,
        waarborgprijs: 0,
        inhoud: 0
    })
    const navigate = useNavigate()
    const {mutate: bekertypeToevoegen, isLoading, isSuccess: isAdded} = useAddBekertype()

    useEffect(() => {
        if (isAdded) navigate("/bekerverhuur/bekertypes")
    }, [isAdded, navigate])

    return (
        <TabContainer>
            <h4>Bekertype aanmaken</h4>
            <BekertypeForm
                bekertype={bekertype}
                setBekertype={setBekertype}
                buttonLabel={"Toevoegen"}
                action={bekertypeToevoegen}
                isLoading={isLoading}
            />
        </TabContainer>
    );
}

export default BekertypeAddPage;
