import React, {useEffect, useState} from "react";
import TabContainerAnimation from "../components/global/TabContainerAnimation";
import TabContainer from "../components/global/TabContainer";
import ChangePasswordForm from "../components/instellingen/ChangePasswordForm";
import {useUserInfo} from "../components/global/sidebar/UserContext";
import useChangePassword from "../data/authentication/useChangePassword";
import {useNavigate} from "react-router-dom";
import MadeBy from "../components/global/MadeBy";

function SettingsPage() {
    const {user: userInfo} = useUserInfo()
    const [user, setUser] = useState({email: "", oudWachtwoord: "", nieuwWachtwoord: "", nieuwWachtwoordOpnieuw: "" })
    const {isLoading, mutate: changePassword, isSuccess} = useChangePassword()
    const navigate = useNavigate()

    useEffect(() => {
        setUser(old => ({...old, email: userInfo.email}))
    }, [userInfo])

    useEffect(() => {
        if (isSuccess) {
            navigate("/dashboard")
        }
    }, [isSuccess])

    return (
        <TabContainerAnimation>
            <h3 className="mb-8">Instellingen</h3>
            <TabContainer>
                <h4>Wachtwoord wijzigen</h4>
                <ChangePasswordForm
                    user={user}
                    setUser={setUser}
                    buttonLabel={"Wijzigen"}
                    isLoading={isLoading}
                    action={changePassword}
                />
            </TabContainer>
            <MadeBy/>
        </TabContainerAnimation>
    );
}

export default SettingsPage;
