import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const deleteConsumptie = (id) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/consumpties/${id}`, {headers: authHeader()})
}

function useDeleteConsumptie(id) {
    const {setAlert} = useAlerts()
    const queryClient = useQueryClient()
    return useMutation(['deleteConsumptie', id], () => deleteConsumptie(id), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        onSuccess: (data) => {
            setAlert(data?.data?.message, "DANGER")
            queryClient.invalidateQueries('fetchAssortiment')
        },
        select: (data => data.data)
    })
}

export default useDeleteConsumptie
