import React, {useEffect, useState} from 'react';

function EenheidListItem({eenheid: en, cNaam, onChange}) {
    const [eenheid, setEenheid] = useState({
        type: {naam: "", meervoudNaam: ""},
        volledig: 0,
        onvolledig: 0,
        inKoeling: 0
    })

    useEffect(() => {
        setEenheid(en)
    },[])

    const handleChange = (e) => {
        setEenheid(old => ({...old, [e.target.name]: e.target.value}))
        onChange(cNaam, en.type.naam, e)
    }

    return (
        <tr className="bg-white border dark:bg-gray-700 dark:border-gray-800">
            <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-gray-100 w-1/4">
                {eenheid.type.naam}
            </td>
            <td className="px-6 text-sm text-gray-600 whitespace-nowrap dark:text-gray-400 w-1/4">
                <div className={"flex flex-row items-center"}>
                    <input
                        className={"h-full pl-1 py-2 my-0 bg-transparent dark:text-gray-200 w-10 md:w-full"}
                        value={eenheid.volledig}
                        name={"volledig"}
                        type={"number"}
                        onChange={handleChange}
                    />
                    <p className={"pl-2 dark:text-gray-400 text-gray-500"}>{eenheid.type.meervoudNaam}</p>
                </div>
            </td>
            <td className="px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 w-1/4">
                <div className={"flex flex-row items-center"}>
                    <input
                        className={"h-full pl-1 py-2 my-0 bg-transparent dark:text-gray-200 w-10 md:w-full"}
                        value={eenheid.onvolledig}
                        name={"onvolledig"}
                        type={"number"}
                        onChange={handleChange}
                    />
                    <p className={"pl-2 dark:text-gray-400 text-gray-500"}>consumpties</p>
                </div>
            </td>
            <td className="px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400 w-1/4">
                <div className={"flex flex-row items-center"}>
                    <input
                        className={"h-full pl-1 py-2 my-0 bg-transparent dark:text-gray-200 w-10 md:w-full"}
                        value={eenheid.inKoeling}
                        name={"inKoeling"}
                        type={"number"}
                        onChange={handleChange}
                    />
                    <p className={"pl-2 dark:text-gray-400 text-gray-500"}>consumpties</p>
                </div>
            </td>

        </tr>
    );
}

export default EenheidListItem;
