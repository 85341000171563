import React, {useState} from 'react';
import EenheidModal from "./EenheidModal";
import useUpsertConsumptieEenheid from "../../../../data/dranken/assortiment/useUpsertConsumptieEenheid";

function EenheidAddModal({id, setShown, shown}) {
    const [eenheid, setEenheid] = useState({
        typeNaam: "",
        consumptieId: id,
        standaardInKoeling: 0,
        standaardInStock: 0,
        aankoopprijs: 0
    })
    const {mutate:addEenheid} = useUpsertConsumptieEenheid()


    return (
        <EenheidModal setShown={setShown} shown={shown} eenheid={eenheid} setEenheid={setEenheid} title={"toevoegen"} action={addEenheid}/>
    );
}

export default EenheidAddModal;
