import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const deleteTapweek = (jaar, weeknummer) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/tapkalender/${jaar}/${weeknummer}`, {headers: authHeader()})
}

function useDeleteTapweek(jaar, weeknummer) {
    const {setAlert} = useAlerts()
    const queryClient = useQueryClient()
    return useMutation(['deleteTapweek', jaar, weeknummer], () => deleteTapweek(jaar, weeknummer), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        onSuccess: (data) => {
            setAlert(data?.data?.message, "SUCCESS")
            queryClient.invalidateQueries('fetchTapkalender')
        },
        select: (data => data.data)
    })
}

export default useDeleteTapweek
