import React, {Fragment} from 'react';

function BoldText({text}) {
    return (
        <Fragment>
            <p className="text-base font-medium leading-none text-gray-700 mr-2 dark:text-gray-300">{text}</p>
        </Fragment>
    );
}

export default BoldText;
