import React from 'react';
import {Routes, Route} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import TabContainerAnimation from "../../components/global/TabContainerAnimation";
import PrivateRoute from "../../components/login/PrivateRoute";
import EvenementenListPage from "./EvenementenListPage";
import EvenementAddPage from "./EvenementAddPage";
import EvenementEditPage from "./EvenementEditPage";
import EvenementDetailPage from "./EvenementDetailPage";

function EvenementenStack({location}) {
    return (
        <div>
            <TabContainerAnimation>
                <h3 className="mb-8">Evenementen</h3>
                <AnimatePresence exitBeforeEnter>
                    <Routes location={location} key={location.pathname}>
                        <Route
                            path={"/"}
                            element={<PrivateRoute><EvenementenListPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/toevoegen"}
                            element={<PrivateRoute permissie={"createEvenement"}><EvenementAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/:id"}
                            element={<PrivateRoute><EvenementDetailPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/:id/wijzigen"}
                            element={<PrivateRoute permissie={"wijzigEvenement"}><EvenementEditPage/></PrivateRoute>}
                        />
                    </Routes>
                </AnimatePresence>
            </TabContainerAnimation>
        </div>
    );
}

export default EvenementenStack;
