import React, {useEffect, useState} from 'react';
import TabContainer from "../../../components/global/TabContainer";
import {useUserInfo} from "../../../components/global/sidebar/UserContext";
import InputField from "../../../components/global/forms/InputField";
import {useFetchBeschikbareBekers} from "../../../data/bekerverhuur/reservaties/useFetchBeschikbareBekers";
import BeschikbaarBekertype from "../../../components/bekerverhuur/reservaties/BeschikbaarBekertype";
import SubmitButton from "../../../components/global/forms/SubmitButton";
import {useCreateReservatie} from "../../../data/bekerverhuur/reservaties/useCreateReservatie";
import {useAlerts} from "../../../components/global/alerts/AlertContext";
import {useNavigate} from "react-router-dom";
import BackButton from "../../../components/global/forms/BackButton";

function BekerReservatieAddPage() {
    const {user} = useUserInfo()
    const navigate = useNavigate()
    const {setAlert} = useAlerts()
    const {mutate: getBeschikbareBekers, data: beschikbareBekers, isSuccess: bekersLoaded} = useFetchBeschikbareBekers()
    const {mutate: createReservatie, isLoading, isSuccess: isAdded} = useCreateReservatie()
    const [reservatie, setReservatie] = useState({
        begindatum: "",
        einddatum: "",
        bekers: [],
        email: user.email
    })

    useEffect(() => {
        if (isAdded) navigate("/bekerverhuur/reservaties")
    }, [isAdded, navigate])

    const onChangeDate = (e) => {
        setReservatie(old => ({...old, [e.target.name]: e.target.value}))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (reservatie.begindatum === "") {
            return setAlert("Ongeldige ophaaldatum.","ERROR")
        }
        if (reservatie.einddatum === "") {
            return setAlert("Ongeldige afleverdatum.", "ERROR")
        }
        if (!reservatie.bekers.some(b => b.aantal > 0)) {
            return setAlert("Geen bekers geselecteerd.", "ERROR")
        }
        createReservatie(reservatie)
    }

    useEffect(() => {
        if (reservatie.einddatum < reservatie.begindatum) {
            setReservatie(old => ({...old, einddatum: ""}))
        }
    }, [reservatie.begindatum, reservatie.einddatum])

    useEffect(() => {
        if (reservatie.begindatum !== "" && reservatie.einddatum !== "" && reservatie.begindatum < reservatie.einddatum) {
            getBeschikbareBekers({
                begindatum: reservatie.begindatum,
                einddatum: reservatie.einddatum
            })
        }
    }, [reservatie.begindatum, reservatie.einddatum])

    return (
        <TabContainer>
            <h4>Reservatie toevoegen</h4>
            <div className={"mt-5 grid xl:grid-cols-2 xl:gap-x-6"}>
                <div className={"w-full"}>
                    <InputField
                        label={"Ophaaldatum"}
                        name={"begindatum"}
                        type={"date"}
                        placeholder={"Datum..."}
                        value={reservatie.begindatum}
                        onChange={onChangeDate}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Afleverdatum"}
                        name={"einddatum"}
                        type={"date"}
                        disabled={reservatie.begindatum === ""}
                        placeholder={"Datum..."}
                        value={reservatie.einddatum}
                        onChange={onChangeDate}
                    />
                </div>
                {bekersLoaded && (
                    <div className={"w-full md:col-span-2"}>
                        <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Beschikbare
                            bekers</p>
                        <div className={"mt-3 flex flex-row grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-4"}>
                            {beschikbareBekers.data.map(bekertype => (
                                <BeschikbaarBekertype key={bekertype.naam} bekertype={bekertype} reservatie={reservatie} setReservatie={setReservatie}/>))}
                        </div>
                    </div>
                )}
                <div className={"flex flex-row"}>
                    <BackButton/>
                    <SubmitButton disabled={!bekersLoaded} label={"Toevoegen"} isLoading={isLoading} onClick={handleSubmit}/>
                </div>
            </div>
        </TabContainer>
    );
}

export default BekerReservatieAddPage;
