import React from 'react';
import SidebarLink from "./SidebarLink";
import SidebarDropDown from "./SidebarDropDown";

function SidebarLinks({hideMenu}) {
    const bekerverhuurLinks = [
        {
            title: "Bekertypes",
            to: "/bekertypes",
        },
        {
            title: "Dozen",
            to: "/dozen",
        },
        {
            title: "Reservaties",
            to: "/reservaties",
        }
    ]

    const drankLinks = [
        {
            title: "Assortiment",
            to: "/assortiment"
        },
        {
            title: "Drank van de maand",
            to: "/drankenvdmaand"
        },
        {
            title: "Stocktelling",
            to: "/stocktellingen"
        }
    ]

    const tapLinks = [
        {
            title: "Tapkalender",
            to: "/tapkalender"
        },
        {
            title: "Tapprestaties",
            to: "/tapprestaties"
        }
    ]

    return (
        <ul
            className="mt-6 z-50">
            <SidebarLink name={"Dashboard"} to={"/dashboard"} hideMenu={hideMenu}/>
            <SidebarDropDown
                name={"Bekerverhuur"}
                icon={"Verhuur"}
                sublink={"/bekerverhuur"}
                links={bekerverhuurLinks}
                hideMenu={hideMenu}
            />
            <SidebarLink name={"Bestuursleden"} to={"/bestuursleden"} hideMenu={hideMenu}/>
            <SidebarDropDown
                name={"Dranken"}
                icon={"Dranken"}
                sublink={"/dranken"}
                links={drankLinks}
                hideMenu={hideMenu}
            />
            <SidebarLink name={"Evenementen"} to={"/evenementen"} hideMenu={hideMenu}/>
            <SidebarDropDown
                name={"Tappen"}
                icon={"Tappen"}
                sublink={"/tappen"}
                links={tapLinks}
                hideMenu={hideMenu}
            />
            <SidebarLink name={"Instellingen"} to={"/instellingen"} hideMenu={hideMenu}/>
        </ul>
    );
}

export default SidebarLinks;
