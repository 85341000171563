import React from 'react';
import Row from "../global/listitems/Row";
import Cell from "../global/listitems/Cell";
import BoxTextLoading from "../global/listitems/loading/BoxTextLoading";
import RoundImageLoading from "../global/listitems/loading/RoundImageLoading";
import NormalTextLoading from "../global/listitems/loading/NormalTextLoading";
import RoundTextLoading from "../global/listitems/loading/RoundTextLoading";
import DetailsButtonLoading from "../global/listitems/loading/DetailsButtonLoading";
import OptionsButtonLoading from "../global/listitems/loading/OptionsButtonLoading";

function BestuursledenListLoading() {
    return (
        <Row loading={true}>
            <Cell>
                <RoundImageLoading/>
            </Cell>
            <Cell>
                <NormalTextLoading/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-40"}/>
            </Cell>
            <Cell>
                <NormalTextLoading/>
            </Cell>
            <Cell>
                <RoundTextLoading/>
                <RoundTextLoading/>
            </Cell>
            <Cell>
                <DetailsButtonLoading/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default BestuursledenListLoading;
