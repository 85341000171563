import React from 'react';
import {useFetchDoosstatus} from "../../../data/bekerverhuur/dozen/useFetchDoosstatus";
import useChangeDoosstatus from "../../../data/bekerverhuur/dozen/useChangeDoosstatus";
import useReinigDoos from "../../../data/bekerverhuur/dozen/useReinigDoos";
import {useNavigate} from "react-router-dom";
import Row from "../../global/listitems/Row";
import Cell from "../../global/listitems/Cell";
import BoldText from "../../global/listitems/BoldText";
import NormalText from "../../global/listitems/NormalText";
import MenuButton from "../../global/listitems/MenuButton";
import RoundText from "../../global/listitems/RoundText";
import OptionsButton from "../../global/listitems/OptionsButton";
import Tooltip from "../../global/listitems/Tooltip";
import useCheckPermissies from "../../../hooks/useCheckPermissies";

function DoosListItem({doos}) {
    const {mutate: changeStatus} = useChangeDoosstatus()
    const {mutate: reinigDoos} = useReinigDoos()
    const navigate = useNavigate()

    const statuses = useFetchDoosstatus()
    const status = {
        'NIET_BESCHIKBAAR': "gray",
        'VUIL': "red",
        'PROPER': "green"
    }

    const options = [
        {
            action: () => navigate(`/bekerverhuur/dozen/wijzigen/${encodeURI(doos.naam)}`),
            label: "Wijzig",
            condition: useCheckPermissies("wijzigDoos")
        },
        {
            action: () => reinigDoos({naam: doos.naam}),
            label: "Markeer als machinaal gereinigd",
            condition: useCheckPermissies("wijzigDoos")
        }
    ]

    const updateStatus = (status) => {
        changeStatus({naam: doos.naam, status: status})
    }

    return (
        <Row>
            <Cell>
                <BoldText text={doos.naam}/>
            </Cell>
            <Cell>
                <NormalText text={doos.bekertypeNaam}/>
            </Cell>
            <Cell>
                <NormalText text={`${doos.aantalBekers} bekers`}/>
            </Cell>
            <Cell>
                <MenuButton text={doos.status}
                            color={status[doos.status]}
                            updateOption={updateStatus}
                            options={statuses}
                            disabled={!useCheckPermissies("wijzigDoos")}
                />
            </Cell>
            <Cell>
                <Tooltip tooltipText={"Aantal keer gebruikt"}>
                    <RoundText
                        text={doos.aantalKeerGebruikt}
                        color={doos.aantalKeerGebruikt < 2 ? "green" : doos.aantalKeerGebruikt < 4 ? "orange" : doos.aantalKeerGebruikt >= 4 ? "red" : "gray"}
                    />
                </Tooltip>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButton options={options}/>
            </Cell>
        </Row>
    );
}

export default DoosListItem;
