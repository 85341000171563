import React from 'react';
import Row from "../../../global/listitems/Row";
import Cell from "../../../global/listitems/Cell";
import BoldText from "../../../global/listitems/BoldText";
import moment from "moment";
import BoxText from "../../../global/listitems/BoxText";
import DetailsButton from "../../../global/listitems/DetailsButton";
import NormalText from "../../../global/listitems/NormalText";
import {capitalizeFirstLetter} from "../../../../util/formatter";

function StocktellingListItem({stocktelling}) {
    return (
        <Row>
            <Cell>
                <BoldText text={moment(stocktelling.datum).format("DD/MM/YYYY")}/>
            </Cell>
            <Cell>
                <BoxText
                    text={`€ ${stocktelling.omzet.toFixed(2).replace(".", ",")}`}
                    color={stocktelling.omzet > 0 ? "green" : stocktelling.omzet === 0 ? "gray" : "red"}
                    width={"w-24"}
                />
            </Cell>
            <Cell>
                <NormalText text={stocktelling.opmerking ? capitalizeFirstLetter(stocktelling.opmerking) : "Geen opmerkingen"}/>
            </Cell>
            <Cell>
                <DetailsButton to={`/dranken/stocktellingen/${stocktelling.id}`}/>
            </Cell>
        </Row>
    );
}

export default StocktellingListItem;
