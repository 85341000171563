import React, {useState} from 'react';
import useFetchActieveBestuursleden from "../../../data/bestuursleden/useFetchActieveBestuursleden";
import Tooltip from "../listitems/Tooltip";
import RoundImage from "../listitems/RoundImage";

function AddBestuurslidToArray({label = "Bestuursleden", name = "name", selected, addSelected, resetSelected}, props) {
    const {isSuccess: bestuursledenIsSuccess, data: bestuursleden} = useFetchActieveBestuursleden()
    const [value, setValue] = useState("")

    const handleClick = e => {
        e.preventDefault()
        addSelected(value)
        setValue("")
    }

    const handleReset = e => {
        e.preventDefault()
        resetSelected()
    }

    return (
        <div className="mb-6">
            <label
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >{label}</label>
            <div className={"ml-2 mb-2 flex flex-row"}>
                {
                    bestuursledenIsSuccess && selected.map(id => {
                        const bestuurslid = bestuursleden.find(b => b.discordId === id)
                        return (
                            <Tooltip key={id} tooltipText={`${bestuurslid.voornaam} ${bestuurslid.familienaam}`} left={-10}>
                                <div className={"-ml-2 border-2 border-white dark:border-gray-800 rounded-full"}>
                                    <RoundImage source={bestuurslid?.afbeelding} alt={id} profilepic={true}/>
                                </div>
                            </Tooltip>
                        )
                    })
                }
                {
                    selected.length > 0 && (
                    <button onClick={handleReset} className={" ml-4 text-sm underline text-yellow-500"}>reset</button>)
                }
            </div>
            <div className={"flex flex-row"}>
                <select
                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-yellow-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    name={name}
                    disabled={selected.length >= 12}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    {...props}
                >
                    <option value={""} disabled defaultValue>Kies...</option>
                    {bestuursledenIsSuccess && bestuursleden.filter(bestuurslid => !selected.includes(bestuurslid.discordId)).map(bestuurslid => (
                        <option key={bestuurslid.discordId}
                                value={bestuurslid.discordId}>{bestuurslid.voornaam} {bestuurslid.familienaam}</option>))}
                </select>
                <button onClick={handleClick}
                        disabled={selected.length >= 12 || value === ""}
                        className={`text-white bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-r-lg text-sm text-center px-2 dark:bg-yellow-600 ${(selected.length < 12 || value === "") ? "hover:bg-yellow-600 dark:hover:bg-yellow-700" : "cursor-not-allowed"} dark:focus:ring-yellow-800`}>
                    Toevoegen
                </button>
            </div>
        </div>);
}

export default AddBestuurslidToArray;
