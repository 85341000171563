import moment from "moment";
import {capitalizeFirstLetter, titleCaseString} from "./formatter";
import parsePhoneNumber from 'libphonenumber-js'

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
        return email.toLowerCase()
    } else {
        return false
    }
}

export function validateWachtwoord(wachtwoord) {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@.\-_$!%*#?&])[A-Za-z\d@.\-_$!%*#?&]{8,}$/
    if (re.test(String(wachtwoord))) {
        return wachtwoord
    } else {
        return false
    }
}

export function validateVoornaam(voornaam) {
    const re = /^[\w'\-,.][^0-9_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/;
    if (re.test(String(voornaam))) {
        return voornaam.charAt(0).toUpperCase() + voornaam.slice(1);
    } else {
        return false
    }
}

export function validateFamilienaam(familienaam) {
    const re = /^[\w'\-,.][^0-9_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/;
    if (re.test(String(familienaam))) {
        return familienaam.charAt(0).toUpperCase() + familienaam.slice(1);
    } else {
        return false
    }
}

export function validateFunctie(functie) {
    const re = /[\w ]+$/;
    if (re.test(String(functie))) {
        return functie;
    } else {
        return false
    }
}

export function validateAfdelingId(afdelingId) {
    const re = /^\d+$/
    if (re.test(String(afdelingId))) {
        return parseInt(afdelingId)
    } else {
        return false
    }
}

export function validateConsumptieCategorieId(categorieId) {
    const re = /^\d+$/
    if (re.test(String(categorieId))) {
        return parseInt(categorieId)
    } else {
        return false
    }
}

export function validateDoosNaam(naam) {
    const re = /^[\w'\-,.0-9]+$/;
    if (re.test(String(naam))) {
        return naam;
    } else {
        return false
    }
}

export function validateDoosType(type) {
    const re = /^[\w'\-,.][^0-9_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/;
    if (re.test(String(type))) {
        return type;
    } else {
        return false
    }
}

export function validateAantal(aantal, canBeZero =false) {
    if (aantal % 1 === 0) {
        if (!canBeZero && aantal === 0) {
            return false
        }
        return aantal
    } else {
        return false
    }
}

export function validateConsumptieNaam(naam) {
    const re = /^[\w'\-,.][^0-9_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/;
    if (re.test(String(naam))) {
        return titleCaseString(naam);
    } else {
        return false
    }
}

export function validateBekertypeNaam(naam) {
    const re = /^[\w'\-,.][^0-9_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/;
    if (re.test(String(naam))) {
        return naam.charAt(0).toUpperCase() + naam.slice(1);
    } else {
        return false
    }
}

export function validatePrijs(prijs) {
    const re = /^\d+.?\d{0,2}$/
    if (re.test(String(prijs))) {
        return prijs;
    } else {
        return false
    }
}

export function validateInhoud(inhoud) {
    if (inhoud > 0 && inhoud % 1 === 0) {
        return inhoud
    } else {
        return false
    }
}

function validateWeeknummer(weeknummer) {
    if (weeknummer > 0 && weeknummer <= 52) {
        return weeknummer
    } else {
        return false
    }
}

export function validateJaartal(jaartal) {
    if (jaartal > 0 && jaartal <= 2100) {
        return jaartal
    } else {
        return false
    }
}

export function validateMaand(maand) {
    if (maand > 0 && maand <= 12) {
        return maand
    } else {
        return false
    }
}

export function validateTapweek(weeknummer, jaar) {
    if (validateJaartal(jaar) && validateWeeknummer(weeknummer)) {
        if (jaar < moment().year()) {
            return false
        } else {
            return {weeknummer, jaar}
        }
    } else {
        return false
    }
}

export function validateDiscordId(id) {
    if (id !== "") {
        return id
    } else {
        return false
    }
}

export function validateOpmerking(opmerking) {
    if (opmerking !== "") {
        return opmerking
    } else {
        return "Geen opmerkingen"
    }
}

export function validateOmschrijving(omschrijving) {
    if (omschrijving.length >= 20 && omschrijving.length <= 200) {
        return omschrijving
    } else {
        return false
    }
}

export function validateTappers(tappers) {
    if (tappers.length <= 12 && tappers.length > 0) {
        return tappers
    } else {
        return false
    }
}

export function validateInkomsten(inkomsten) {
    if (inkomsten >= 0) {
        return inkomsten
    } else {
        return false
    }
}

export function validateTapPrestatieDatum(datum) {
    if (moment(datum) < moment()) {
        return datum
    } else {
        return false
    }
}

export function validateGeboortedatum(datum) {
    if (moment(datum) < moment()) {
        return datum
    } else {
        return false
    }
}

export function validateEvenementNaam(naam) {
    const re = /^[\w'\-,.][^0-9_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/;
    if (re.test(String(naam))) {
        return capitalizeFirstLetter(naam);
    } else {
        return false
    }
}

export function validateLink(link) {
    if (link) {
        const re = /(?:(?:https?|ftp):\/\/|\b[a-z\d]+\.)(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|\([^\s()<>]+\))?\))+(?:\((?:[^\s()<>]+|\(?:[^\s()<>]+\))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/
        if (re.test(link)) {
            return link
        } else {
            return "error"
        }
    }
    return false
}

export function validateEvenementDatum(datum) {
    return datum
}

export function validateConsumptieId(consumptieId) {
    const re = /^\d+$/
    if (re.test(String(consumptieId))) {
        return parseInt(consumptieId)
    } else {
        return false
    }
}

export function validatePhoneNumber(number) {
    const phoneNumber = parsePhoneNumber(number, 'BE')
    if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational()
    } else {
        return false
    }
}

export function validateEvenementCategorie(categorieId) {
    const re = /^\d+$/
    if (re.test(String(categorieId))) {
        return parseInt(categorieId)
    } else {
        return false
    }
}
