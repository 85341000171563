import React from 'react';
import {useAlerts} from "../alerts/AlertContext";
import Resizer from "react-image-file-resizer";

function ImageUploadField({label = "Label", name = "inputField", value, onChange}) {
    const {setAlert} = useAlerts()
    const changeImage = async (e) => {
        try {
            const file = e.target.files[0];
            const image = await resizeFile(file);
            onChange(image, file.type)
            console.log(image);
        } catch (err) {
            setAlert(err.message, "ERROR")
            console.log(err);
        }
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "PNG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    return (
        <div className={"mb-6"}>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                   htmlFor="user_avatar">{label}</label>
            <div className={"flex flex-row"}>
                <input
                    className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg bg-gray-50 border border-gray-300 text-gray-900 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    name={name}
                    type="file"
                    onChange={changeImage}
                    accept="image/jpeg, image/png"
                />
                <div className={"w-10 h-10 rounded-full overflow-hidden ml-4"}>
                    <img className={"object-cover w-10 h-10"} src={value ? value : "https://s3.eu-west-1.amazonaws.com/odt.website/bestuursleden/768893910487138335.jpg"} alt={name}/>
                </div>
            </div>
        </div>
    );
}

export default ImageUploadField;
