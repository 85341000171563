import React from 'react';
import InputField from "../global/forms/InputField";
import BackButton from "../global/forms/BackButton";
import SubmitButton from "../global/forms/SubmitButton";
import SelectField from "../global/forms/SelectField";
import {useAlerts} from "../global/alerts/AlertContext";
import ImageUploadField from "../global/forms/ImageUploadField";
import useFetchEvenementCategorieen from "../../data/evenementen/useFetchEvenementCategorieen";
import {
    validateEvenementCategorie,
    validateEvenementDatum,
    validateEvenementNaam,
    validateLink
} from "../../util/validator";
import useFetchActieveBestuursleden from "../../data/bestuursleden/useFetchActieveBestuursleden";

function EvenementForm({evenement, setEvenement, isLoading, action, buttonLabel}) {
    const {isSuccess: categorieenIsSuccess, data: categorieen} = useFetchEvenementCategorieen()
    const {isSuccess: bestuursledenIsSuccess, data: bestuursleden} = useFetchActieveBestuursleden()
    const {setAlert} = useAlerts()

    const onChange = (e) => {
        setEvenement(old => ({...old, [e.target.name]: e.target.value}))
    }

    const onClick = (e) => {
        e.preventDefault()
        if (controlData()) {
            action(evenement)
        }
    }

    const controlData = () => {
        const naam = validateEvenementNaam(evenement.naam)
        if (!naam) {
            return setAlert("Ongeldige naam.", "ERROR")
        }
        const datum = validateEvenementDatum(evenement.datum)
        if (!datum) {
            return setAlert("Ongeldige datum.", "ERROR")
        }
        const categorieId = validateEvenementCategorie(evenement.categorieId)
        if (!categorieId) {
            return setAlert("Ongeldige categorie", "ERROR")
        }
        const facebookURL = validateLink(evenement.facebookURL)
        if (facebookURL === "error") {
            throw new Error("Ongeldige Facebook-link.")
        }
        const driveURL = validateLink(evenement.driveURL)
        if (driveURL === "error") {
            throw new Error("Ongeldige Google Drive-link.")
        }
        return true
    }

    return (
        <form>
            <div className={"mt-5 grid xl:grid-cols-2 xl:gap-6"}>
                <div className={"w-full"}>
                    <InputField
                        label={"Naam"}
                        name={"naam"}
                        type={"text"}
                        placeholder={"naam..."}
                        value={evenement.naam}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Datum"}
                        name={"datum"}
                        type={"date"}
                        placeholder={"datum..."}
                        value={evenement.datum}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <SelectField
                        name={"categorieId"}
                        label={"Categorie"}
                        options={categorieenIsSuccess ? categorieen : []}
                        value={evenement.categorieId}
                        onChange={onChange}
                        optionMapper={(option) => (<option key={option.id} value={option.id}>{option.naam}</option>)}
                        required
                    />
                </div>
                <div className={"w-full"}>
                    <ImageUploadField
                        label={"Affiche"}
                        name={"afbeelding"}
                        value={evenement.afbeelding}
                        onChange={(afbeelding, type) => setEvenement(old => ({
                            ...old,
                            afbeelding: afbeelding,
                            afbeeldingType: type
                        }))}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Link naar Google Drive"}
                        name={"driveURL"}
                        value={evenement.driveURL ? evenement.driveURL : ""}
                        placeholder={"Google Drive link..."}
                        type={"text"}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Link naar Facebook evenement"}
                        name={"facebookURL"}
                        value={evenement.facebookURL ? evenement.facebookURL : ""}
                        placeholder={"Facebook evenement link..."}
                        type={"text"}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <SelectField
                        name={"verantwoordelijkeId"}
                        label={"Verantwoordelijke"}
                        options={bestuursledenIsSuccess ? bestuursleden : []}
                        value={evenement.verantwoordelijkeId ? evenement.verantwoordelijkeId : ""}
                        onChange={onChange}
                        optionMapper={(option) => (<option key={option.discordId}
                                                           value={option.discordId}>{`${option.voornaam} ${option.familienaam}`}</option>)}
                    />
                </div>
                <div className={"w-full"}>
                    <SelectField
                        name={"toevoegenKalender"}
                        label={"Toevoegen aan Google Calendar?"}
                        options={[{name: "Ja", value: true}, {name: "Nee", value: false}]}
                        value={evenement.toevoegenKalender ? evenement.toevoegenKalender : false}
                        onChange={onChange}
                        optionMapper={(option) => (
                            <option key={option.name} value={option.value}>{`${option.name}`}</option>)}
                    />
                </div>
            </div>
            <div className={"flex flex-row"}>
                <BackButton
                    to={"/evenementen"}
                />
                <SubmitButton
                    label={buttonLabel}
                    isLoading={isLoading}
                    onClick={onClick}
                />
            </div>
        </form>);
}

export default EvenementForm;
