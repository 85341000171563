import {useMutation, useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../util/auth-header";

const login = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/admin`, data)
}

const fetchUserInfo = (email) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/bestuursleden?email=${email}`, {headers: authHeader()})
}

export const useLogin = () => {
    return useMutation(login)
}

export const useUserInfoData = (email) => {
    return useQuery(['fetchUserInfo', email], () => fetchUserInfo(email), {
        enabled: !!email,
        select: data => data.data
    })
}
