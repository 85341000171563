import React, {useEffect, useState} from 'react';
import TabContainer from "../../components/global/TabContainer";
import {useNavigate, useParams} from "react-router-dom";
import useFetchBestuurslid from "../../data/bestuursleden/useFetchBestuurslid";
import {useUserInfoData} from "../../data/authentication/UseAuthenticationData";
import useFetchPermissies from "../../data/authentication/useFetchPermissies";
import BackButton from "../../components/global/forms/BackButton";
import SubmitButton from "../../components/global/forms/SubmitButton";
import useChangePermissies from "../../data/authentication/useChangePermissies";
import BestuursledenPermissieListItem from "../../components/bestuursleden/BestuursledenPermissieListItem";

function BestuursledenEditPermissionsPage() {
    const {discordId} = useParams()
    const navigate = useNavigate()
    const [permissies, setPermissies] = useState([])
    const [filter, setFilter] = useState("")
    const {data: bestuurslid} = useFetchBestuurslid(discordId)
    const {data: gebruiker, isSuccess: permissiesLoaded} = useUserInfoData(bestuurslid?.email)
    const {data: allePermissies, isSuccess: allPermissiesLoaded} = useFetchPermissies();
    const {mutate: changePermissies, isSuccess: isChanged, isLoading: isChanging} = useChangePermissies()

    useEffect(() => {
        if (permissiesLoaded) {
            setPermissies(gebruiker.permissies)
        }
    }, [permissiesLoaded])

    useEffect(() => {
        if (isChanged) {
            navigate("/bestuursleden")
        }
    }, [isChanged])

    const onChangeFilter = (e) => {
        const {value} = e.target
        setFilter(value)
    }

    const onChange = (e) => {
        const {id, checked} = e.target
        if (checked) {
            setPermissies(old => [...old, id])
        } else {
            setPermissies(old => old.filter(p => p !== id))
        }
    }

    const onClick = (e) => {
        e.preventDefault()
        changePermissies({email: gebruiker.email, permissies})
    }

    return (
        <TabContainer>
            <div className={"flex flex-col md:flex-row justify-between mb-5"}>
                <h4>Permissies wijzigen van {bestuurslid?.voornaam} {bestuurslid?.familienaam}</h4>
                <div className="relative mt-4 md:mt-0">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </div>
                    <input
                        value={filter}
                        onChange={onChangeFilter}
                        type="text"
                        className="block p-2 pl-10 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Zoeken..."
                    />
                </div>

            </div>
            <div className="overflow-y-auto overflow-x-auto mb-6">
                <table className="w-full whitespace-nowrap">
                    <tbody>
                    {allPermissiesLoaded && allePermissies.filter(p => p.naam.toLowerCase().includes(filter.toLowerCase())).map(p => (
                       <BestuursledenPermissieListItem permissie={p} checked={permissies.includes(p.id)} onChange={onChange}/>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className={"flex flex-row"}>
                <BackButton
                    to={"/bestuursleden"}
                />
                <SubmitButton
                    label={"Wijzigen"}
                    isLoading={isChanging}
                    onClick={onClick}
                />
            </div>
        </TabContainer>
    );
}

export default BestuursledenEditPermissionsPage;
