import React from 'react';
import Sidebar from "../components/global/sidebar/Sidebar";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import DashboardPage from "./DashboardPage";
import {AnimatePresence} from "framer-motion";
import PrivateRoute from "../components/login/PrivateRoute";
import AlertContainer from "../components/global/alerts/AlertContainer";
import {AlertContextProvider} from "../components/global/alerts/AlertContext";
import BekerverhuurStack from "./bekerverhuur/BekerverhuurStack";
import {UserContextProvider} from "../components/global/sidebar/UserContext";
import TappenStack from "./tappen/TappenStack";
import BestuursledenStack from "./bestuursleden/BestuursledenStack";
import SettingsPage from "./SettingsPage";
import EvenementenStack from "./evenementen/EvenementenStack";
import DrankenStack from "./dranken/DrankenStack";
import ErrorPage from "./ErrorPage";

function Stack() {
    const location = useLocation()

    return (
        <UserContextProvider>
            <AlertContextProvider>
                <div className={"relative md:flex h-screen overflow-hidden"}>
                    <div className={"sticky"}>
                        <Sidebar/>
                    </div>
                    <div className={"flex-1 h-screen overflow-y-auto"}>
                        <AlertContainer/>
                        <AnimatePresence exitBeforeEnter>
                            <Routes location={location}
                                    key={location.pathnamex}>
                                <Route path={"/dashboard"} element={<PrivateRoute><DashboardPage/></PrivateRoute>}/>
                                <Route path={"/bestuursleden/*"} element={<BestuursledenStack location={location}/>}/>
                                <Route path={"/evenementen/*"} element={<EvenementenStack location={location}/>}/>
                                <Route path={"/dranken/*"} element={<DrankenStack location={location}/>}/>
                                <Route path={"/tappen/*"} element={<TappenStack location={location}/>}/>
                                <Route path={"/bekerverhuur/*"} element={<BekerverhuurStack location={location}/>}/>
                                <Route path={"/instellingen"} element={<PrivateRoute><SettingsPage/></PrivateRoute>}/>
                                <Route path={"/"} element={<PrivateRoute><Navigate to={"/dashboard"}/></PrivateRoute>}/>
                                <Route path={"*"} element={<PrivateRoute><ErrorPage/></PrivateRoute>}/>
                            </Routes>
                        </AnimatePresence>
                    </div>
                </div>
            </AlertContextProvider>
        </UserContextProvider>
    );
}

export default Stack;
