import React from 'react';
import TabContainer from "../../../components/global/TabContainer";
import {useParams} from "react-router-dom";
import Loading from "../../../components/global/Loading";
import moment from "moment";
import BackButton from "../../../components/global/forms/BackButton";
import BestellingDetailHeader from "../../../components/dranken/stocktelling/bestellingen/BestellingDetailHeader";
import BestellingDetailListItem from "../../../components/dranken/stocktelling/bestellingen/BestellingDetailListItem";
import useFetchBestelling from "../../../data/dranken/stocktelling/useFetchBestelling";

function BestellingDetailPage() {
    const {id} = useParams()
    const {data: bestelling, isSuccess} = useFetchBestelling(id)

    if (!isSuccess) {
        return (
            <TabContainer>
                <h4>Overzicht bestelling</h4>
                <Loading/>
            </TabContainer>
        )
    }

    return (
        <TabContainer>
            <h4>Overzicht bestelling</h4>
            <div className={"flex flex-row grid md:grid-cols-3 md:gap-6"}>
                <div className={"flex flex-col"}>
                    <h5 className={"mt-3 mb-2"}>Datum</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{moment(bestelling.datum).format("DD/MM/YYYY")}</p>
                </div>
                <div className={"flex flex-col md:col-span-2"}>
                    <h5 className={"mt-3 mb-2"}>Opmerkingen</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{bestelling.opmerking ?? "Geen opmerkingen"}</p>
                </div>
            </div>
            <div className={"flex flex-col"}>
                <h5 className={"mt-3 mb-2"}>Detailoverzicht</h5>
                <div className="overflow-y-auto overflow-x-auto">
                    <table className="w-full whitespace-nowrap">
                        <thead>
                        <BestellingDetailHeader/>
                        </thead>
                        <tbody>
                        {isSuccess && bestelling.consumpties.map(c => (
                            <BestellingDetailListItem key={c.id} consumptie={c}/>))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={"mb-3 mt-5"}>
                <BackButton to={"/dranken/stocktellingen"}/>
            </div>
        </TabContainer>
    );
}

export default BestellingDetailPage;
