import {useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const fetchBekertype = (naam) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/bekertypes/${naam}`, {headers: authHeader()})
}

function useOpvragenBekertypeData(naam) {
    const {setAlert} = useAlerts()
    return useQuery(['fetchBekertype', naam], () => fetchBekertype(naam), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: (data => data.data)
    })
}

export default useOpvragenBekertypeData
