import React from 'react';
import EenheidListItem from "./EenheidListItem";

function ConsumptieItem({consumptie, onChange}) {

    return (
        <div className={"duration-300 rounded-md border border-gray-200 shadow-sm hover:shadow-md my-4 dark:border-none dark:bg-gray-700 p-4"}>
            <h6 className={"font-medium dark:text-gray-200 mb-4"}>{consumptie.naam}</h6>
            <div className="overflow-y-auto overflow-x-auto">
                <table className="w-full">
                    <thead className="bg-gray-50 dark:bg-gray-800">
                    <tr className={"border dark:border-none"}>
                        <th scope="col"
                            className="py-3 px-6 text-sm font-medium text-left text-gray-700 dark:text-gray-100 w-1/4">
                            Type
                        </th>
                        <th scope="col"
                            className="py-3 px-6 text-sm font-medium text-left text-gray-700 dark:text-gray-100 w-1/4">
                            Aantal volledig in stock
                        </th>
                        <th scope="col"
                            className="py-3 px-6 text-sm font-medium text-left text-gray-700 dark:text-gray-100 w-1/4">
                            Aantal onvolledig in stock
                        </th>
                        <th scope="col"
                            className="py-3 px-6 text-sm font-medium text-left text-gray-700 dark:text-gray-100 w-1/4">
                            Aantal in koeling
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {consumptie.eenheden.map(e => (<EenheidListItem key={e.id} eenheid={e} cNaam={consumptie.naam} onChange={onChange}/>))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ConsumptieItem;
