import React, {Fragment} from 'react';

function NormalText({text, className}) {
    return (
        <Fragment>
            <p className={`text-sm leading-none text-gray-600 ml-2 dark:text-gray-300 ${className}`}>
                {text}
            </p>
        </Fragment>
    );
}

export default NormalText;
