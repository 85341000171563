import React from 'react';
import {Routes, Route} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import TabContainerAnimation from "../../components/global/TabContainerAnimation";
import PrivateRoute from "../../components/login/PrivateRoute";
import BestuursledenListPage from "./BestuursledenListPage";
import BestuursledenAddPage from "./BestuursledenAddPage";
import BestuursledenEditPage from "./BestuursledenEditPage";
import BestuursledenEditPermissionsPage from "./BestuursledenEditPermissionsPage";
import BestuursledenDetailPage from "./BestuursledenDetailPage";
import BestuursledenEditBezittingenPage from "./BestuursledenEditBezittingenPage";

function BestuursledenStack({location}) {
    return (
        <div>
            <TabContainerAnimation>
                <h3 className="mb-8">Bestuursleden</h3>
                <AnimatePresence exitBeforeEnter>
                    <Routes location={location} key={location.pathname}>
                        <Route
                            path={"/"}
                            element={<PrivateRoute><BestuursledenListPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/toevoegen"}
                            element={<PrivateRoute permissie={"upsertBestuurslid"}><BestuursledenAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/:discordId"}
                            element={<PrivateRoute><BestuursledenDetailPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/:discordId/wijzigen"}
                            element={<PrivateRoute permissie={"upsertBestuurslid"}><BestuursledenEditPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/:discordId/permissies"}
                            element={<PrivateRoute permissie={"changePermissies"}><BestuursledenEditPermissionsPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/:discordId/bezittingen"}
                            element={<PrivateRoute permissie={"changePermissies"}><BestuursledenEditBezittingenPage/></PrivateRoute>}
                        />
                    </Routes>
                </AnimatePresence>
            </TabContainerAnimation>
        </div>
    );
}

export default BestuursledenStack;
