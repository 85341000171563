import React from 'react';
import Cell from "../../../global/listitems/Cell";
import Row from "../../../global/listitems/Row";
import BoldTextLoading from "../../../global/listitems/loading/BoldTextLoading";
import BoxTextLoading from "../../../global/listitems/loading/BoxTextLoading";
import NormalTextLoading from "../../../global/listitems/loading/NormalTextLoading";
import DetailsButtonLoading from "../../../global/listitems/loading/DetailsButtonLoading";

function StocktellingListLoading() {
    return (
        <Row loading={true}>
            <Cell>
                <BoldTextLoading width={"w-[90px]"}/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-24"}/>
            </Cell>
            <Cell>
                <NormalTextLoading/>
            </Cell>
            <Cell>
                <DetailsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default StocktellingListLoading;
