import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const deleteTapprestatie = (id) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/tapprestaties/${id}`, {headers: authHeader()})
}

function useDeleteTapPrestatie(id) {
    const {setAlert} = useAlerts()
    const queryClient = useQueryClient()
    return useMutation('deleteTapprestatie',() => deleteTapprestatie(id), {
        onSuccess: (data) => {
            setAlert(data?.data?.message, "SUCCESS")
            queryClient.invalidateQueries('fetchTapPrestaties')
        },
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        }
    })
}

export default useDeleteTapPrestatie
