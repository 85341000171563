import axios from "axios";
import {useQuery} from "react-query";
import {useAlerts} from "../../components/global/alerts/AlertContext";

const fetchUpcomingEvenementen = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/evenementen/upcoming`)
}

export const useFetchUpcomingEvenementen = () => {
    const {setAlert} = useAlerts()
    return useQuery('fetchUpcomingEvenementen', fetchUpcomingEvenementen, {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: (data => data.data)
    })
}
