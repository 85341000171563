import React from 'react';
import Row from "../../global/listitems/Row";
import Cell from "../../global/listitems/Cell";
import BoldTextLoading from "../../global/listitems/loading/BoldTextLoading";
import BoxTextLoading from "../../global/listitems/loading/BoxTextLoading";
import NormalTextLoading from "../../global/listitems/loading/NormalTextLoading";
import RoundTextLoading from "../../global/listitems/loading/RoundTextLoading";
import DetailsButtonLoading from "../../global/listitems/loading/DetailsButtonLoading";
import OptionsButtonLoading from "../../global/listitems/loading/OptionsButtonLoading";

function AssortimentListLoading() {
    return (
        <Row loading={true}>
            <Cell>
                <BoldTextLoading/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-36"}/>
            </Cell>
            <Cell>
                <NormalTextLoading width={Math.random() < .7 ? "w-[40px]" : "w-[45px]"}/>
            </Cell>
            <Cell>
                <RoundTextLoading/>
                <RoundTextLoading/>
            </Cell>
            <Cell>
                <DetailsButtonLoading/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default AssortimentListLoading;
