import React, {useState} from 'react';
import {capitalizeFirstLetter} from "../../../util/formatter";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";

function MenuButton({color, text, options, updateOption, disabled}) {
    const [showOptions, setShowOptions] = useState(false)
    const ref = useOnClickOutside(() => setShowOptions(false))
    const colorStyles = {
        red: 'text-red-700 bg-red-100 dark:bg-red-900 dark:text-red-400',
        green: 'text-green-700 bg-green-100 dark:bg-green-900 dark:text-green-400',
        orange: 'text-orange-700 bg-orange-100 dark:bg-orange-900 dark:text-orange-400',
        blue: 'text-blue-700 bg-blue-100 dark:bg-blue-900 dark:text-blue-400',
        gray: 'text-gray-700 bg-gray-100 dark:bg-gray-900 dark:text-gray-400',
    }


    function toggleStatuses() {
        setShowOptions(old => !old)
    }

    function updateOpt(selected) {
        updateOption(selected)
        setShowOptions(false)
    }

    return (
        <div ref={ref}>
            <button
                disabled={disabled}
                className={`py-3 px-3 text-sm focus:outline-none w-36 leading-none ${colorStyles[color]} rounded`}
                onClick={toggleStatuses}
            >
                {capitalizeFirstLetter(text)}
            </button>
            <div
                className={`dropdown-content rounded-lg bg-white shadow w-36 absolute z-30 dark:bg-gray-700 ${showOptions ? "" : "hidden"}`}>
                {options.filter(option => option !== text).map(option => (
                    <div
                        onBlur={() => setShowOptions(false)}
                        key={option}
                        onClick={() => updateOpt(option)}
                        className="focus:outline-none focus:text-yellow-600 dark:text-gray-300 dark:hover:bg-yellow-600 text-xs w-full rounded-lg hover:bg-yellow-400 py-4 px-4 cursor-pointer hover:text-white"
                    >
                        <p>{capitalizeFirstLetter(option)}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MenuButton;
