import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import TabContainer from "../../../components/global/TabContainer";
import useFetchTapPrestatie from "../../../data/tappen/tapprestaties/useFetchTapPrestatie";
import TapPrestatieForm from "../../../components/tappen/tapprestaties/TapPrestatieForm";
import moment from "moment";
import useUpdateTapPrestatie from "../../../data/tappen/tapprestaties/useEditTapPrestatie";

function TapPrestatiesEditPage() {
    const {id} = useParams();
    const [tapprestatie, setTapprestatie] = useState({
        id,
        tappers: [],
        geldmeeBestuurslidId: null,
        datum: moment().format("YYYY-MM-DD"),
        inkomsten: 0,
        opmerking: ""
    })
    const {isSuccess, data} = useFetchTapPrestatie(id)
    const {mutate: upsertTapweek, isLoading: isUpdating, isSuccess: isUpdated} = useUpdateTapPrestatie()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            let temp = data;
            temp.datum = moment(data.datum).format("YYYY-MM-DD")
            temp.tappers = temp.bestuursleden.map(b => b.bestuurslidId)
            temp.opmerking = data.opmerking
            setTapprestatie(temp)
        }
    }, [isSuccess])

    useEffect(() => {
        if (isUpdated) navigate("/tappen/tapprestaties")
    }, [isUpdated, navigate])

    return (
        <TabContainer>
            <h4>Tapprestatie wijzigen</h4>
            <TapPrestatieForm
                buttonLabel={"Wijzigen"}
                tapprestatie={tapprestatie}
                setTapprestatie={setTapprestatie}
                action={upsertTapweek}
                isLoading={isUpdating}
            />
        </TabContainer>
    );
}

export default TapPrestatiesEditPage;
