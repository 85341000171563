import React from 'react';
import ListContainer from "../../../components/global/ListContainer";
import ReservatieListItem from "../../../components/bekerverhuur/reservaties/ReservatieListItem";
import useFetchReservaties from "../../../data/bekerverhuur/reservaties/useFetchReservaties";
import ReservatieListLoading from "../../../components/bekerverhuur/reservaties/ReservatieListLoading";
import useCheckPermissies from "../../../hooks/useCheckPermissies";

function BekerReservatiesListPage() {
    return (
        <ListContainer
            type={"Reservaties"}
            dataFunction={useFetchReservaties}
            addPath={"/bekerverhuur/reservaties/toevoegen"}
            addPermissie={useCheckPermissies("createReservatie")}
            listItemLambda={reservatie => (
                <ReservatieListItem key={reservatie.id} reservatie={reservatie}/>
            )}
            placeholderLambda={index => (
                <ReservatieListLoading key={index}/>
            )}
        />
    );
}

export default BekerReservatiesListPage;
