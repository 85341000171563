import React from 'react';
import {useAlerts} from "../global/alerts/AlertContext";
import InputField from "../global/forms/InputField";
import SubmitButton from "../global/forms/SubmitButton";
import {validateWachtwoord} from "../../util/validator";

function ChangePasswordForm({user, setUser, action, buttonLabel, isLoading}) {
    const {setAlert} = useAlerts()
    const onChange = (e) => {
        setUser(old => ({...old, [e.target.name]: e.target.value}))
    }



    const onClick = (e) => {
        e.preventDefault()
        if (controlData()) {
            action(user)
        }
    }

    const controlData = () => {
        if (user.nieuwWachtwoord !== user.nieuwWachtwoordOpnieuw) {
            setAlert("Wachtwoorden komen niet overeen", "ERROR")
            return false
        }
        const nieuwWachtwoord = validateWachtwoord(user.nieuwWachtwoord)
        if (!nieuwWachtwoord) {
            setAlert("Ongeldig wachtwoord. Het wachtwoord moet minimaal 8 karakters lang zijn. Minimaal 1 letter, 1 cijfer en 1 speciaal teken bevatten.", "ERROR")
            return false
        }
        return true
    }

    return (
        <form>
            <div className={"mt-5 grid xl:grid-cols-2 xl:gap-6"}>
                <div className={"w-full"}>
                    <InputField
                        label={"Oud Wachtwoord"}
                        name={"oudWachtwoord"}
                        type={"password"}
                        placeholder={"oud wachtwoord..."}
                        value={user.oudWachtwoord}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}></div>
                <div className={"w-full"}>
                    <InputField
                        label={"Nieuw Wachtwoord"}
                        name={"nieuwWachtwoord"}
                        type={"password"}
                        placeholder={"nieuw wachtwoord..."}
                        value={user.nieuwWachtwoord}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Nieuw Wachtwoord opnieuw"}
                        name={"nieuwWachtwoordOpnieuw"}
                        type={"password"}
                        placeholder={"nieuw wachtwoord opnieuw..."}
                        value={user.nieuwWachtwoordOpnieuw}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className={"flex flex-row"}>
                <SubmitButton
                    label={buttonLabel}
                    isLoading={isLoading}
                    onClick={onClick}
                />
            </div>
        </form>);
}

export default ChangePasswordForm;
