import axios from "axios";
import {useQuery} from "react-query";
import {useAlerts} from "../../../components/global/alerts/AlertContext";
import authHeader from "../../../util/auth-header";

const fetchAandatchReservaties = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/reservaties/aandacht`, {headers: authHeader()})
}

export const useFetchAandatchReservaties = () => {
    const {setAlert} = useAlerts()
    return useQuery('fetchAandatchReservaties', fetchAandatchReservaties, {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: (data => data.data)
    })
}
