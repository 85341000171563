import React from 'react';
import {PieChart, Pie, Cell} from 'recharts';
import {capitalizeFirstLetter} from "../../../../util/formatter";

const COLORS = ['#0088FE', '#FFBB28', '#00C49F', '#FF8042'];

const StockWaardePieChart = ({data}) => {

    return (
        <div className={"flex flex-col w-full"}>
            <div className={"mx-auto"}>
                <PieChart width={200} height={200}>
                    <Pie
                        data={data}
                        cx={100}
                        cy={100}
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={5}
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} stroke={"none"}/>
                        ))}
                    </Pie>
                </PieChart>
            </div>
            <div className={"mt-5"}>
                {data.map((e, i) => (
                    <div key={i} className={"flex flex-row items-center align-middle"}>
                        <div className={"rounded-full w-3 h-3 mr-2 text-xs"}
                             style={{backgroundColor: COLORS[i % COLORS.length]}}/>
                        <p className={"text-gray-800 dark:text-gray-200"}>{capitalizeFirstLetter(e.name)}</p>
                        <span
                            className={"ml-2 text-gray-700 dark:text-gray-400 text-xs"}>(€ {e.value.toFixed(2).replace(".", ",")})</span>
                    </div>
                ))}
                <div className={"flex flex-row items-center align-middle"}>
                    <div className={"rounded-full w-3 h-3 mr-2 bg-gray-500"}/>
                    <p className={"text-gray-800 dark:text-gray-200"}>Totaal</p>
                    <span
                        className={"ml-2 text-gray-700 dark:text-gray-400 text-xs"}>(€ {data.reduce((prev, next) => prev + next.value, 0).toFixed(2).replace(".", ",")})
                    </span>
                </div>
            </div>
        </div>
    );
}

export default StockWaardePieChart;
