import {useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../util/auth-header";
import {useAlerts} from "../../components/global/alerts/AlertContext";

const fetchBestuurslid = (discordId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/bestuursleden/${discordId}`, {headers: authHeader()})
}

function useFetchBestuurslid(discordId) {
    const {setAlert} = useAlerts()
    return useQuery(['fetchBestuurslid', discordId], () => fetchBestuurslid(discordId), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: (data => data.data)
    })
}

export default useFetchBestuurslid
