import React from 'react';
import {motion} from "framer-motion";
import Alert from "./Alert";
import {useAlerts} from "./AlertContext";

function AlertContainer() {
    const {isShown, dismiss} = useAlerts()
    const variants = {
        "shown": {
            opacity: 1,
            y: [-100, 0]
        },
        "hidden": {
            opacity: 0,
            y: [0, -100],
        }
    }


    return (
        <motion.div
            variants={variants}
            initial={"hidden"}
            animate={isShown ? 'shown' : 'hidden'}
            exit={"hidden"}
            transition={{duration: 0.4}}
        >
            <Alert close={dismiss}/>
        </motion.div>
    );
}

export default AlertContainer;
