import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const upsertConsumptieEenheid = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/consumpties/eenheden`, data, {headers: authHeader()})
}

function useUpsertConsumptieEenheid() {
    const {setAlert} = useAlerts()
    const queryClient = useQueryClient()
    return useMutation('upsertConsumptieEenheid', upsertConsumptieEenheid, {
        onSuccess: (data) => {
            setAlert(data?.data?.message, "SUCCESS")
            queryClient.invalidateQueries('fetchConsumptie')
        },
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        }
    })
}

export default useUpsertConsumptieEenheid
