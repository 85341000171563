import React from 'react';

function InputField({label = "Label", placeholder="Placeholder", type="text", name="inputField", value, onChange, disabled= false}, props) {
    return (
        <div className="mb-6">
            <label
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >{label}</label>
            <input
                className={`${disabled && "cursor-not-allowed"} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                value={value}
                onChange={onChange}
                name={name}
                {...props}
            />
        </div>
    );
}

export default InputField;
