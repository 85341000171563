import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import useChangeBestuurslidActiefstatus from "../../data/bestuursleden/useChangeBestuurslidActiefstatus";
import useResetBestuurslidWachtwoord from "../../data/bestuursleden/useResetPassword";
import Row from "../global/listitems/Row";
import Cell from "../global/listitems/Cell";
import RoundImage from "../global/listitems/RoundImage";
import NormalText from "../global/listitems/NormalText";
import BoxText from "../global/listitems/BoxText";
import RoundText from "../global/listitems/RoundText";
import OptionsButton from "../global/listitems/OptionsButton";
import Tooltip from "../global/listitems/Tooltip";
import useCheckIfUserExists from "../../data/authentication/useCheckIfUserExists";
import useCheckPermissies from "../../hooks/useCheckPermissies";
import DetailsButton from "../global/listitems/DetailsButton";
import useChangeBestuurslidZichtbaarheid from "../../data/bestuursleden/useChangeBestuurslidZichtbaarheid";

function BestuursledenListItem({bestuurslid}) {
    const {mutate: changeActiefStatus} = useChangeBestuurslidActiefstatus()
    const {mutate: changeZichtbaarheid} = useChangeBestuurslidZichtbaarheid()
    const {mutate: resetWachtwoord} = useResetBestuurslidWachtwoord(bestuurslid.discordId)
    const {mutate: checkIfExists, isSuccess: userExists} = useCheckIfUserExists(bestuurslid.email)
    const navigate = useNavigate()

    const options = [
        {
            action: () => navigate(`/bestuursleden/${bestuurslid.discordId}/wijzigen`),
            label: "Wijzig",
            condition: useCheckPermissies("upsertBestuurslid")
        }, {
            action: () => navigate(`/bestuursleden/${bestuurslid.discordId}/bezittingen`),
            label: "Wijzig bezittingen",
            condition: useCheckPermissies("changePermissies")
        }, {
            action: checkIfExists,
            label: "Wijzig permissies",
            condition: useCheckPermissies("changePermissies")
        }, {
            action: () => resetWachtwoord(bestuurslid.discordId),
            label: "Wachtoord resetten",
            condition: useCheckPermissies("resetWachtwoord")
        }, {
            action: () => changeActiefStatus({discordId: bestuurslid.discordId, actief: !bestuurslid.actief}),
            label: bestuurslid.actief ? "Zet op non-actief" : "Zet op actief",
            condition: useCheckPermissies("upsertBestuurslid")
        },
        {
            action: () => changeZichtbaarheid({discordId: bestuurslid.discordId, zichtbaar: !bestuurslid.zichtbaar}),
            label: bestuurslid.zichtbaar ?  "Maak onzichtbaar" : "Maak zichtbaar",
            condition: useCheckPermissies("upsertBestuurslid")
        }
    ]

    useEffect(() => {
        if (userExists) {
            navigate(`/bestuursleden/${bestuurslid.discordId}/permissies`)
        }
    }, [userExists])

    return (
        <Row>
            <Cell>
                <RoundImage
                    source={bestuurslid.afbeelding}
                    alt={bestuurslid.voornaam}
                    profilepic={true}
                />
            </Cell>
            <Cell>
                <NormalText text={`${bestuurslid.voornaam} ${bestuurslid.familienaam}`}/>
            </Cell>
            <Cell>
                <BoxText text={bestuurslid.afdeling.naam} width={"w-40"}/>
            </Cell>
            <Cell>
                <NormalText text={bestuurslid.functie}/>
            </Cell>
            <Cell>
                <Tooltip tooltipText={bestuurslid.actief ? "Actief" : "Non-actief"}>
                    <RoundText text={'A'} color={bestuurslid.actief ? 'green' : 'red'}/>
                </Tooltip>
                <Tooltip tooltipText={bestuurslid.zichtbaar ? "Zichtbaar" : "Niet zichtbaar"}>
                    <RoundText text={'Z'} color={bestuurslid.zichtbaar ? 'green' : 'red'}/>
                </Tooltip>
            </Cell>
            <Cell>
                <DetailsButton to={`/bestuursleden/${bestuurslid.discordId}`}/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButton options={options}/>
            </Cell>
        </Row>
    );
}

export default BestuursledenListItem;
