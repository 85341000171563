import React from 'react';
import {Oval} from "react-loader-spinner";

function Loading() {
    return (
        <div className={"flex justify-center mt-20"}>
            <Oval
                color="#777"
                secondaryColor="#ccc"
                height={80}
                width={80}
                strokeWidth={2}
                ariaLabel={"Laden..."}
            />
        </div>    );
}

export default Loading;
