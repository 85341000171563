import React, {createRef} from "react";

function Tooltip({ children, tooltipText, left = 0 }) {
    const tipRef = createRef(null);

    function handleMouseEnter() {
        tipRef.current.style.opacity = 1;
        tipRef.current.style.marginBottom = "10px";
    }
    function handleMouseLeave() {
        tipRef.current.style.opacity = 0;
        tipRef.current.style.marginBottom = "0px";
    }

    return (
        <div
            className={`relative flex items-center`}
            onMouseLeave={handleMouseLeave}
        >
            <div
                className="absolute bg-gray-900 dark:bg-gray-700 text-white px-4 py-2 rounded flex items-center transition-all duration-150"
                style={{ bottom: "100%", opacity: 0, left: left}}
                ref={tipRef}
            >
                <div
                    className="absolute bg-gray-900 dark:bg-gray-700 h-3 w-3"
                    style={{ bottom: "-6px", transform: "rotate(45deg)" }}
                />
                {tooltipText}
            </div>
            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </div>
        </div>
    );
}

export default Tooltip
