import React, {Fragment} from 'react';
import TabContainer from "../global/TabContainer";
import useFetchTapkalenderWeek from "../../data/tappen/tapkalender/useFetchTapkalenderWeek";
import moment from "moment";
import Button from "../global/Button";
import {useNavigate} from "react-router-dom";
import TapverantwoordelijkeLoading from "./TapverantwoordelijkeLoading";

function TapVerantwoordelijke() {
    const navigate = useNavigate()
    const weeknummer = moment().isoWeek()
    const jaartal = moment().year()
    const {data: tapverantwoordelijke, isSuccess, isLoading, isError} = useFetchTapkalenderWeek(jaartal, weeknummer)
    return (
        <TabContainer>
            <div className={"flex flex-col w-full"}>
                <h4>Tapverantwoordelijke van deze week</h4>
                {isLoading && (<TapverantwoordelijkeLoading/>)}
                {!isLoading && tapverantwoordelijke && isSuccess && (
                    <Fragment>
                        <div className={"max-h-60 max-w-60 mx-auto rounded-full overflow-hidden m-10 shadow-lg"}>
                            <img
                                src={tapverantwoordelijke.bestuurslid.afbeelding}
                                alt={tapverantwoordelijke.bestuurslid.voornaam}
                            />
                        </div>
                        <div className={"flex justify-between lg:mx-10 items-center"}>
                            <p className={"text-lg text-gray-800 dark:text-gray-200"}>{tapverantwoordelijke.bestuurslid.voornaam} {tapverantwoordelijke.bestuurslid.familienaam}</p>
                            <p
                                className={"bg-gray-200 dark:bg-gray-900 px-3 py-2 rounded-md text-gray-800 dark:text-gray-200"}
                            >Week {weeknummer}</p>
                        </div>
                    </Fragment>
                )}
                {!isLoading && !tapverantwoordelijke && isSuccess && (
                    <div className={"mt-6"}>
                        <p className={"text-gray-800 dark:text-gray-200"}>Geen tapverantwoordelijke aangesteld voor deze week.</p>
                        <Button
                            label={"Tapverantwoordelijke toevoegen"}
                            className={"mt-6"}
                            onClick={() => navigate("/tappen/tapkalender")}
                        />
                    </div>
                )}
                {(!isLoading && isError) && (
                    <div className={"mt-6"}>
                        <p className={"text-gray-800 dark:text-gray-200"}>Oeps! Er liep iets mis bij het ophalen van de tapverantwoordelijke.</p>
                    </div>
                )}
            </div>
        </TabContainer>
    );
}

export default TapVerantwoordelijke;
