import React from 'react';
import Cell from "../../global/listitems/Cell";
import Row from "../../global/listitems/Row";
import BoldTextLoading from "../../global/listitems/loading/BoldTextLoading";
import NormalTextLoading from "../../global/listitems/loading/NormalTextLoading";
import BoxTextLoading from "../../global/listitems/loading/BoxTextLoading";
import OptionsButtonLoading from "../../global/listitems/loading/OptionsButtonLoading";

function BekertypesListLoading() {
    return (
        <Row loading={true}>
            <Cell>
                <BoldTextLoading/>
            </Cell>
            <Cell>
                <NormalTextLoading width={"w-[90px]"}/>
            </Cell>
            <Cell>
                <NormalTextLoading width={"w-[90px]"}/>
            </Cell>
            <Cell>
                <NormalTextLoading width={Math.random() < .5 ? "w-[75px]" : "w-[65px]"}/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-14"}/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default BekertypesListLoading;
