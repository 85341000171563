import React from 'react';
import {motion} from "framer-motion";
import Logo from "../components/global/icons/Logo";
import Beer from "../components/global/icons/Beer";
import LoginForm from "../components/login/LoginForm";

function LoginPage() {
    return (
        <motion.div
            className={"bg-yellow-100 opacity-0 dark:bg-gray-600"}
            animate={{opacity: 1}}
            exit={{opacity: 0, transition: {delay: .8}}}
        >
            <motion.div
                className="lg:float-left lg:w-1/2 bg-white xl:max-w-screen-sm h-screen shadow-md dark:bg-gray-800"
                transition={{type: "spring", damping: 20, duration: .8, delay: .25}}
                animate={{
                    x: ["-100%", "0%"]
                }}
                exit={{x: "-100%"}}
            >
                <div className="py-12 flex justify-center lg:justify-start lg:px-12">
                    <div className="flex items-center">
                        <Logo className="w-16 h-16 stroke-current fill-current text-gray-800 dark:text-gray-100"/>
                        <h1 className="ml-2">
                            Onder de Toren vzw
                        </h1>
                    </div>
                </div>
                <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
                    <h2 className="text-center font-display lg:text-left xl:text-5xl
                    xl:text-bold dark:text-gray-200">Inloggen</h2>
                    <div className="mt-12">
                        <LoginForm/>
                    </div>
                </div>
            </motion.div>
            <div className="hidden lg:flex items-center justify-center flex-1 h-screen">
                <motion.div
                    className="max-w-xs scale-0"
                    animate={{
                        scale: [1.2, 1],
                        x: ["-100%", "0%"],
                        rotate: [15, 0],
                        duration: .8
                    }}
                    transition={{delay: .25}}
                    whileHover={{
                        scale: 1.2,
                        rotate: 15,
                        transition: {duration: .8}
                    }}
                    exit={{
                        scale: [1, 1.2, 0],
                        rotate: [0, 15, 10],
                        x: ["0%", "-100%"]
                    }}
                >
                    <Beer className={"w-80 mx-auto"}/>
                </motion.div>
            </div>
        </motion.div>
    );
}

export default LoginPage;
