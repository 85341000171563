import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const changeTapPrestatieStatus = (data) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/tapprestaties/betaald`, data, {headers: authHeader()})
}

function useChangeTapPrestatieStatus() {
    const {setAlert} = useAlerts()
    const queryClient = useQueryClient()
    return useMutation('changeTapPrestatieStatus', changeTapPrestatieStatus, {
        onSuccess: (data) => {
            setAlert(data?.data?.message, "SUCCESS")
            queryClient.invalidateQueries('fetchTapPrestaties')
        },
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        }
    })
}

export default useChangeTapPrestatieStatus
