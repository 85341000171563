import React, {Fragment} from 'react';

function BoxText({text, width= 20, color="gray"}) {
    const colorStyles = {
        red: 'text-red-700 bg-red-100 dark:bg-red-900 dark:text-red-400',
        green: 'text-green-700 bg-green-100 dark:bg-green-900 dark:text-green-400',
        orange: 'text-orange-700 bg-orange-100 dark:bg-orange-900 dark:text-orange-400',
        blue: 'text-blue-700 bg-blue-100 dark:bg-blue-900 dark:text-blue-400',
        gray: 'text-gray-700 bg-gray-100 dark:bg-gray-900 dark:text-gray-400',
    }


    return (
        <Fragment>
            <button
                className={`py-3 px-3 text-sm focus:outline-none ${width} leading-none rounded ${colorStyles[color]}`}
                disabled
            >
                {text}
            </button>
        </Fragment>
    );
}

export default BoxText;
