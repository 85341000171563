import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import TabContainer from "../../../components/global/TabContainer";
import AssortimentForm from "../../../components/dranken/assortiment/AssortimentForm";
import useUpsertConsumptie from "../../../data/dranken/assortiment/useUpsertConsumptie";

function AssortimentAddPage() {
    const [constumptie, setConstumptie] = useState({oldNaam: "", naam: "", verkoopprijs: 0, categorieId: ""})
    const {mutate: consumptieToevoegen, isLoading, isSuccess} = useUpsertConsumptie()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            navigate("/dranken/assortiment")
        }
    }, [isSuccess, navigate])

    return (
        <TabContainer>
            <h4>Consumptie aanmaken</h4>
            <AssortimentForm
                consumptie={constumptie}
                setConsumptie={setConstumptie}
                action={consumptieToevoegen}
                isLoading={isLoading}
                buttonLabel={"Toevoegen"}
            />
        </TabContainer>
    );
}

export default AssortimentAddPage;
