import React from 'react';
import ListContainer from "../../../components/global/ListContainer";
import useFetchTapkalender from "../../../data/tappen/tapkalender/useFetchTapkalender";
import TapkalenderListItem from "../../../components/tappen/tapkalender/TapkalenderListItem";
import useCheckPermissies from "../../../hooks/useCheckPermissies";
import TapkalenderListLoading from "../../../components/tappen/tapkalender/TapkalenderListLoading";

function TapkalenderListPage() {
    return (
        <ListContainer
            type={"Tapkalender"}
            addPath={"/tappen/tapkalender/toevoegen"}
            dataFunction={useFetchTapkalender}
            useFilter={true}
            addPermissie={useCheckPermissies("upsertTapweek")}
            listItemLambda={tapweek => (
                <TapkalenderListItem key={tapweek.id} tapweek={tapweek}/>
            )}
            placeholderLambda={index => (
                <TapkalenderListLoading key={index}/>
            )}
        />
    );
}

export default TapkalenderListPage;
