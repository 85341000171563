import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import TabContainer from "../../components/global/TabContainer";
import EvenementForm from "../../components/evenementen/EvenementenForm";
import moment from "moment";
import useEditEvenement from "../../data/evenementen/useEditEvenement";
import useFetchEvenement from "../../data/evenementen/useFetchEvenement";

function EvenementEditPage() {
    const {id} = useParams()
    const [evenement, setEvenement] = useState({
        naam: "",
        datum: moment().format("YYYY-MM-DD"),
        categorieId: "",
        afbeelding: undefined,
        afbeeldingType: "",
        verantwoordelijkeId: null,
    })
    const {isSuccess, data} = useFetchEvenement(id)
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            const temp = data
            temp.datum =  moment(data.datum).format("YYYY-MM-DD")
            temp.afbeeldingType = ""
            setEvenement(temp)
        }
    }, [isSuccess])

    const {mutate: editEvenement, isLoading, isSuccess: isAdded} = useEditEvenement()

    useEffect(() => {
        if (isAdded) navigate("/evenementen")
    }, [isAdded, navigate])

    return (
        <TabContainer>
            <h4>Evenement wijzigen</h4>
            <EvenementForm
                evenement={evenement}
                setEvenement={setEvenement}
                buttonLabel={"Wijzigen"}
                action={editEvenement}
                isLoading={isLoading}
            />
        </TabContainer>
    );
}

export default EvenementEditPage;
