import {useUserInfo} from "../components/global/sidebar/UserContext";

const useCheckPermissies = (permissie) => {
    const {permissies} = useUserInfo()
    if (!permissie) {
        return true
    } else {
        return permissies.includes(permissie)
    }
}

export default useCheckPermissies
