import React, {useState} from 'react';
import {RiEditLine} from 'react-icons/ri'
import Tooltip from "../../../global/listitems/Tooltip";
import DetailContainer from "../../../global/DetailContainer";
import EenheidEditModal from "./EenheidEditModal";
import {useUserInfo} from "../../../global/sidebar/UserContext";

function EenheidDetail({eenheid, id}) {
    const [shown, setShown] = useState(false)
    const {permissies} = useUserInfo()

    return (
        <DetailContainer>
            {
                permissies.includes("stocktelling") && (
                    <EenheidEditModal shown={shown} setShown={setShown} consumptieId={id} id={eenheid.id}/>
                )
            }
            <div className={"flex flex-row justify-between px-4 mb-3 items-center"}>
                <h6 className={"text-md font-medium dark:text-gray-100"}>{eenheid.type.naam}</h6>
                {permissies.includes("stocktelling") && (
                    <div
                        className={"hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-200 text-gray-800 duration-300 flex flex-row"}>
                        <div className={"px-1"}>
                            <Tooltip tooltipText={"Wijzigen"} left={-15}>
                                <div
                                    onClick={() => setShown(true)}
                                    className={"hover:cursor-pointer"}
                                >
                                    <RiEditLine/>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                )}
            </div>
            <div className={"px-4 mt-2"}>
                <p className={"pl-1 text-gray-600 dark:text-gray-300 text-xs"}>Aankoopprijs</p>
                <p className={"pl-2 text-gray-800 dark:text-gray-200"}>{`€ ${eenheid.aankoopprijs.toFixed(2).replace(".", ",")} (€ ${(eenheid.aankoopprijs / eenheid.type.aantalConsumpties).toFixed(2).replace(".", ",")} / consumptie)`}</p>
            </div>
            <div className={"px-4 mt-2"}>
                <p className={"pl-1 text-gray-600 dark:text-gray-300 text-xs"}>Aantal momenteel in stock</p>
                <p className={"pl-2 text-gray-800 dark:text-gray-200"}>{Math.floor(eenheid.momenteelInStock / eenheid.type.aantalConsumpties)} {Math.floor(eenheid.momenteelInStock / eenheid.type.aantalConsumpties) === 1 ? eenheid.type.enkelvoudNaam : eenheid.type.meervoudNaam}{eenheid.momenteelInStock / eenheid.type.aantalConsumpties % 1 > 0 && ` & ${eenheid.momenteelInStock % eenheid.type.aantalConsumpties} consumpties`}</p>
            </div>
            <div className={"px-4 mt-2"}>
                <p className={"pl-1 text-gray-600 dark:text-gray-300 text-xs"}>Standaard in stock</p>
                <p className={"pl-2 text-gray-800 dark:text-gray-200"}>{Math.floor(eenheid.standaardInStock / eenheid.type.aantalConsumpties)} {Math.floor(eenheid.standaardInStock / eenheid.type.aantalConsumpties) === 1 ? eenheid.type.enkelvoudNaam : eenheid.type.meervoudNaam}{eenheid.standaardInStock / eenheid.type.aantalConsumpties % 1 > 0 && ` & ${eenheid.standaardInStock % eenheid.type.aantalConsumpties} consumpties`}</p>
            </div>
            <div className={"px-4 mt-2"}>
                <p className={"pl-1 text-gray-600 dark:text-gray-300 text-xs"}>Standaard in koeling</p>
                <p className={"pl-2 text-gray-800 dark:text-gray-200"}>{eenheid.standaardInKoeling} consumpties</p>
            </div>
        </DetailContainer>
    );
}

export default EenheidDetail;
