import React from 'react';

function TapverantwoordelijkeLoading() {
    return (
        <div className={"animate-pulse"}>
            <div className={"h-60 w-60 mx-auto rounded-full overflow-hidden m-10 bg-gray-100 dark:bg-gray-500"}/>
            <div className={"flex justify-between lg:mx-10 items-center"}>
                <div className={"rounded bg-gray-100 dark:bg-gray-500 w-[130px] h-[30px]"}/>
                <div className={"rounded-md bg-gray-100 dark:bg-gray-500 w-[85px] h-[40px]"}/>
            </div>
        </div>
    );
}

export default TapverantwoordelijkeLoading;
