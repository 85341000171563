import React from 'react';
import Mail from "../global/icons/Mail";
import Globe from "../global/icons/Globe";
import Dollar from "../global/icons/Dollar";
import Key from "../global/icons/Key";
import Empty from "../global/icons/Empty";
import Tooltip from "../global/listitems/Tooltip";

function BestuursledenBezittingItem({bezitting}) {
    const icons = {
        "MAILBOX" : (<Mail/>),
        "INLOGGEGEVENS": (<Globe/>),
        "BETAALGEGEVENS": (<Dollar/>),
        "SLEUTEL": (<Key/>),
        "OVERIGE": (<Empty/>)
    }

    return (
        <Tooltip tooltipText={bezitting.naam} left={-15}>
            <div className={"-ml-4 mb-3 rounded-full bg-gray-100 p-2 border border-2 border-white stroke-gray-800 hover:bg-gray-200 dark:border-gray-800 dark:bg-gray-700 dark:hover:bg-gray-600 dark:stroke-gray-200"}>
                {icons[bezitting.type]}
            </div>
        </Tooltip>
    );
}

export default BestuursledenBezittingItem;
