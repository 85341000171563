import React from 'react';
import Cell from "../../global/listitems/Cell";
import Row from "../../global/listitems/Row";
import BoldTextLoading from "../../global/listitems/loading/BoldTextLoading";
import NormalTextLoading from "../../global/listitems/loading/NormalTextLoading";
import RoundTextLoading from "../../global/listitems/loading/RoundTextLoading";
import OptionsButtonLoading from "../../global/listitems/loading/OptionsButtonLoading";
import BoxTextLoading from "../../global/listitems/loading/BoxTextLoading";

function DoosListLoading() {
    return (
        <Row loading={true}>
            <Cell>
                <BoldTextLoading/>
            </Cell>
            <Cell>
                <NormalTextLoading/>
            </Cell>
            <Cell>
                <NormalTextLoading/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-36"}/>
            </Cell>
            <Cell>
                <RoundTextLoading/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default DoosListLoading;
