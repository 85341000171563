import React from 'react';

function HeaderRow({children}) {
    return (
        <tr className="focus:outline-none h-12 border bg-gray-50 border-gray-50 rounded dark:border-gray-700 dark:bg-gray-900 w-full">
            {children}
        </tr>

    );
}

export default HeaderRow;
