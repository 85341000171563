import React from 'react';
import ListContainer from "../../../components/global/ListContainer";
import DoosListItem from "../../../components/bekerverhuur/dozen/DoosListItem";
import useFetchDozen from "../../../data/bekerverhuur/dozen/useFetchDozen";
import useCheckPermissies from "../../../hooks/useCheckPermissies";
import DoosListLoading from "../../../components/bekerverhuur/dozen/DoosListLoading";

function DozenListPage() {
    return (
        <ListContainer
            type={"Dozen"}
            dataFunction={useFetchDozen}
            addPath={"toevoegen"}
            addPermissie={useCheckPermissies("createDoos")}
            listItemLambda={doos => (
                <DoosListItem key={doos.naam} doos={doos}/>
            )}
            placeholderLambda={index => (
                <DoosListLoading key={index}/>
            )}
        />
    );
}

export default DozenListPage;
