import React from "react";

export default function Pagination({isLoading, isSuccess, amountPerPage, total, next, prev, curr, setPagina}) {
    const handleNext = () => {
        setPagina(next)
    }

    const handlePrev = () => {
        setPagina(prev)
    }

    if (total === 0) {
        return <div/>
    }

    if (!isLoading && !isSuccess) {
        return (<div/>)
    }

    return (
        <div className='pt-4 pr-4 flex flex-row justify-between items-center'>
            {(next || prev || isLoading) && (
                <div>
                    <nav
                        className='z-0 inline-flex rounded-md shadow-sm -space-x-px'
                        aria-label='Pagination'
                    >
                        <button
                            disabled={!prev || isLoading}
                            onClick={handlePrev}
                            className={`inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 dark:border-gray-900 bg-white text-sm font-medium text-gray-500 dark:text-gray-300 dark:bg-gray-700 ${prev && "hover:bg-gray-50 dark:hover:bg-gray-900"}`}
                        >
                            <span>Vorige</span>
                        </button>
                        <button
                            disabled={!next || isLoading}
                            onClick={handleNext}
                            className={`inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 dark:border-gray-900 bg-white dark:text-gray-300 dark:bg-gray-700 text-sm font-medium text-gray-500 ${next && "hover:bg-gray-50 dark:hover:bg-gray-900"}`}
                        >
                            <span>Volgende</span>
                        </button>
                    </nav>
                </div>
            )}
            <div>
                {isLoading ? (
                    <p className='text-sm text-gray-700 pl-2 dark:text-gray-500'> Aan het laden...</p>
                ) : (
                    <p className='text-sm text-gray-700 pl-2 dark:text-gray-500'>
                        Toont
                        <span className='font-medium'> {curr * amountPerPage - (amountPerPage - 1)}</span>
                        -
                        <span className='font-medium'>{(curr * amountPerPage) <= total ? curr * amountPerPage : total} </span>
                        van de
                        <span className='font-medium'> {total} </span>
                        resultaten
                    </p>

                )}
            </div>
        </div>
    );
}
