import React from 'react';
import {useAlerts} from "../../global/alerts/AlertContext";
import InputField from "../../global/forms/InputField";
import ImageUploadField from "../../global/forms/ImageUploadField";
import BackButton from "../../global/forms/BackButton";
import SubmitButton from "../../global/forms/SubmitButton";
import TextAreaField from "../../global/forms/TextAreaField";
import {
    validateConsumptieNaam,
    validateJaartal,
    validateMaand,
    validateOmschrijving,
    validatePrijs
} from "../../../util/validator";
import {titleCaseString} from "../../../util/formatter";

function DrankVdMaandForm({drank, setDrank, isLoading, action, buttonLabel, update = false}) {
    const {setAlert} = useAlerts()
    const onChange = (e) => {
        setDrank(old => ({...old, [e.target.name]: e.target.value}))
    }

    const onClick = (e) => {
        e.preventDefault()
        if (controlData()) {
            action(drank)
        }
    }

    const controlData = () => {
        const naam = validateConsumptieNaam(drank.naam)
        if (!naam) {
            setAlert("Ongeldige naam.", "ERROR")
            return false
        } else {
            setDrank(old => ({...old, naam: titleCaseString(naam)}))
        }
        const prijs = validatePrijs(drank.prijs)
        if (!prijs) {
            setAlert("Ongeldige prijs.", "ERROR")
            return false
        }
        const jaartal = validateJaartal(drank.jaartal)
        if (!jaartal) {
            setAlert("Ongeldig jaartal.", "ERROR")
            return false
        }
        const maand = validateMaand(drank.maand)
        if (!maand) {
            setAlert("Ongeldige maand.", "ERROR")
            return false
        }
        if (!drank.afbeelding && !drank.afbeeldingType) {
            setAlert("Ongeldige afbeelding.", "ERROR")
            return false
        }
        const omschrijving = validateOmschrijving(drank.omschrijving)
        if (!omschrijving) {
            setAlert("Ongeldige omschrijving. De omschrijving moet tussen de 20 en 200 karakters lang zijn.", "ERROR")
            return false
        }
        return true
    }

    return (
        <form>
            <div className={"mt-5 grid xl:grid-cols-2 xl:gap-6"}>
                <div className={"w-full"}>
                    <InputField
                        label={"Naam"}
                        name={"naam"}
                        type={"text"}
                        placeholder={"naam..."}
                        value={drank.naam}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Prijs"}
                        name={"prijs"}
                        type={"number"}
                        placeholder={"prijs..."}
                        value={drank.prijs}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Jaartal"}
                        name={"jaartal"}
                        type={"number"}
                        placeholder={"jaartal..."}
                        value={drank.jaartal}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Maand"}
                        name={"maand"}
                        type={"number"}
                        placeholder={"maand..."}
                        disabled={update}
                        value={drank.maand}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <ImageUploadField
                        label={"Afbeelding"}
                        name={"afbeelding"}
                        value={drank.afbeelding}
                        onChange={(afbeelding, type) => setDrank(old => ({
                            ...old,
                            afbeelding: afbeelding,
                            afbeeldingType: type
                        }))}
                    />
                </div>
                <div className={"w-full"}>
                    <TextAreaField
                        label={"Omschrijving"}
                        name={"omschrijving"}
                        placeholder={"omschrijving..."}
                        value={drank.omschrijving}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className={"flex flex-row"}>
                <BackButton
                    to={"/dranken/drankenvdmaand"}
                />
                <SubmitButton
                    label={buttonLabel}
                    isLoading={isLoading}
                    onClick={onClick}
                />
            </div>
        </form>);
}

export default DrankVdMaandForm;
