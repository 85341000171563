import React from 'react';
import Row from "../global/listitems/Row";
import Cell from "../global/listitems/Cell";
import BoldText from "../global/listitems/BoldText";
import BoxText from "../global/listitems/BoxText";
import moment from "moment";
import DetailsButton from "../global/listitems/DetailsButton";

function UpcomingEvenementenListItem({evenement}) {
    return (
        <Row>
            <Cell>
                <div className={"rounded-md my-2 max-w-50"}>
                    <img
                        src={evenement.afbeelding}
                        alt={evenement.naam}
                        className={"max-w-50 max-h-50 object-contain rounded-md mx-auto"}
                    />
                </div>
            </Cell>
            <Cell>
                <BoldText text={evenement.naam}/>
            </Cell>
            <Cell>
                <BoxText text={moment(evenement.datum).format("DD/MM/YYYY")}/>
            </Cell>
            <Cell justify={"justify-end"}>
                <DetailsButton to={`/evenementen/${evenement.id}`}/>
            </Cell>
        </Row>
        );
}

export default UpcomingEvenementenListItem;
