import {useMutation} from "react-query";
import axios from "axios";
import authHeader from "../../util/auth-header";
import {useAlerts} from "../../components/global/alerts/AlertContext";

const checkIfExists = (email) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/auth/${email}/exists`, {headers: authHeader()})
}

function useCheckIfUserExists(email) {
    const {setAlert} = useAlerts()
    return useMutation(['checkIfExists', email], () => checkIfExists(email), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
    })
}

export default useCheckIfUserExists
