import React from 'react';
import ReservatieDetailContainer from "../../../components/bekerverhuur/reservaties/ReservatieDetailContainer";
import {useParams} from "react-router-dom";

function BekerReservatiesDetailPage() {
    const {id: reservatieId} = useParams();
    return (
        <ReservatieDetailContainer reservatieId={reservatieId}/>
    );
}

export default BekerReservatiesDetailPage;
