import React from 'react';

function SelectField({optionMapper, options = [], label = "Label", name = "selectField", value, onChange}, props) {
    return (
        <div className={"mb-6"}>
            <label htmlFor={name}
                   className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
            <select
                value={value}
                onChange={onChange}
                name={name}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-500 dark:focus:border-yellow-500"
                {...props}
            >
                <option value={""} disabled defaultValue>Kies...</option>
                {options.map(optionMapper)}
            </select>
        </div>
    );
}

export default SelectField;
