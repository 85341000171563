import React from 'react';

function DetailContainer({children}) {
    return (
        <div className={"border border-gray-200 rounded-md py-4 shadow dark:border-none dark:bg-gray-700 hover:shadow-md duration-300 mb-4"}>
            {children}
        </div>
    );
}

export default DetailContainer;
