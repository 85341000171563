import React from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import TabContainerAnimation from "../../components/global/TabContainerAnimation";
import PrivateRoute from "../../components/login/PrivateRoute";
import AssortimentListPage from "./assortiment/AssortimentListPage";
import DrankVdMaandListPage from "./drankvdmaand/DrankVdMaandListPage";
import AssortimentEditPage from "./assortiment/AssortimentEditPage";
import AssortimentAddPage from "./assortiment/AssortimentAddPage";
import DrankVdMaandEditPage from "./drankvdmaand/DrankVdMaandEditPage";
import DrankVdMaandAddPage from "./drankvdmaand/DrankVdMaandAddPage";
import AssortimentDetailPage from "./assortiment/AssortimentDetailPage";
import StocktellingAddPage from "./stocktelling/StocktellingAddPage";
import StocktellingDetailPage from "./stocktelling/StocktellingDetailPage";
import StocktellingLandingPage from "./stocktelling/StocktellingLandingPage";
import BestellingAddPage from "./stocktelling/BestellingAddPage";
import BestellingDetailPage from "./stocktelling/BestellingDetailPage";

function DrankenStack({location}) {
    return (
        <div>
            <TabContainerAnimation>
                <h3 className="mb-8">Dranken</h3>
                <AnimatePresence exitBeforeEnter>
                    <Routes location={location} key={location.pathname}>
                        <Route
                            path={"/"}
                            element={<Navigate to={"/dranken/assortiment"}/>}
                        />
                        <Route
                            path={"/assortiment"}
                            element={<PrivateRoute><AssortimentListPage/></PrivateRoute>}
                        />
                        <Route
                            path={"assortiment/toevoegen"}
                            element={<PrivateRoute permissie={"upsertConsumptie"}><AssortimentAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/assortiment/:naam"}
                            element={<PrivateRoute><AssortimentDetailPage/></PrivateRoute>}
                        />
                        <Route
                            path={"assortiment/:naam/wijzigen"}
                            element={<PrivateRoute permissie={"upsertConsumptie"}><AssortimentEditPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/drankenvdmaand"}
                            element={<PrivateRoute><DrankVdMaandListPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/drankenvdmaand/toevoegen"}
                            element={<PrivateRoute permissie={"upsertDrankVdMaand"}><DrankVdMaandAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/drankenvdmaand/:jaartal/:maand/wijzigen"}
                            element={<PrivateRoute permissie={"upsertDrankVdMaand"}><DrankVdMaandEditPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/stocktellingen"}
                            element={<PrivateRoute><StocktellingLandingPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/stocktellingen/toevoegen"}
                            element={<PrivateRoute permissie={"stocktelling"}><StocktellingAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/stocktellingen/:id/"}
                            element={<PrivateRoute><StocktellingDetailPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/bestellingen"}
                            element={<PrivateRoute><StocktellingLandingPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/bestellingen/toevoegen"}
                            element={<PrivateRoute permissie={"stocktelling"}><BestellingAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"/bestellingen/:id/"}
                            element={<PrivateRoute><BestellingDetailPage/></PrivateRoute>}
                        />
                    </Routes>
                </AnimatePresence>
            </TabContainerAnimation>
        </div>
    );
}

export default DrankenStack;
