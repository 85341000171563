import React from 'react';
import Calendar from "../icons/Calendar";
import {motion} from "framer-motion";
import Monitor from "../icons/Monitor";
import ClipBoard from "../icons/ClipBoard";
import ShoppingBag from "../icons/ShoppingBag";
import {NavLink, useLocation} from "react-router-dom";
import Persons from "../icons/Persons";
import Settings from "../icons/Settings";
import Music from "../icons/Music";

const linkVariants = {
    notSelected: {
        opacity: 0,
    },
    selected: {
        opacity: [0, 1],
        x: [-200, 0],
        transition: {
            type: "spring",
            duration: .25
        }
    }
}

const variants = {
    notSelected: {
        scale: 1
    },
    selected: {
        scale: 1
    }
}

function SidebarLink({name, to, hideMenu}) {
    const {pathname} = useLocation()
    const icons = {
        "Dashboard": <Monitor
            className={`z-10 w-6 h-6 stroke-current dark:stroke-gray-300 ${pathname.includes(to) ? "stroke-3" : "stroke-2"}`}/>,
        "Tapkalender": <Calendar
            className={`z-10 w-6 h-6 stroke-current dark:stroke-gray-300 ${pathname.includes(to) ? "stroke-3" : "stroke-2"}`}/>,
        "Bekerverhuur": <ShoppingBag
            className={`z-10 w-6 h-6 stroke-current dark:stroke-gray-300 ${pathname.includes(to) ? "stroke-3" : "stroke-2"}`}/>,
        "To-Do": <ClipBoard
            className={`z-10 w-6 h-6 stroke-current dark:stroke-gray-300 ${pathname.includes(to) ? "stroke-3" : "stroke-2"}`}/>,
        "Bestuursleden": <Persons
            className={`z-10 w-6 h-6 stroke-current dark:stroke-gray-300 ${pathname.includes(to) ? "stroke-3" : "stroke-2"}`}/>,
        "Instellingen": <Settings
            className={`z-10 w-6 h-6 stroke-current dark:stroke-gray-300 ${pathname.includes(to) ? "stroke-3" : "stroke-2"}`}/>,
        "Evenementen": <Music
            className={`z-10 w-6 h-6 stroke-current dark:stroke-gray-300 ${pathname.includes(to) ? "stroke-3" : "stroke-2"}`}/>,
    }

    return (
        <motion.li
            className="flex justify-between text-gray-800 items-center mb-4 z-10 dark:text-gray-300"
            variants={variants}
            whileHover={"selected"}
        >
            <NavLink
                className={"duration-300 relative w-full md:w-44 dark:hover:text-gray-300 dark:hover:bg-gray-700 hover:bg-gray-200 rounded-md"}
                to={to}
                onClick={hideMenu}
            >
                <div className="flex items-center z-10 pl-2 p-1.5">
                    {icons[name]}
                    <span className={`text-sm ml-2 z-10 ${pathname.includes(to) && "font-medium"}`}>{name}</span>
                </div>
                <motion.div
                    className={"absolute top-0 bg-gray-200 w-full md:w-44 h-9 rounded-md opacity-0 dark:bg-gray-700"}
                    animate={pathname.includes(to) ? "selected" : "notSelected"}
                    variants={linkVariants}
                />
            </NavLink>
        </motion.li>
    );
}

export default SidebarLink;
