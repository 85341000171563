import React from 'react';

function SearchField({filter, setFilter, isError}) {
    const onChangeFilter = (e) => {
        setFilter(e.target.value)
    }

    return (
        <div className="relative mb-4 md:mb-0">
            <div className="absolute inset-y-0 left-0 top-2 items-center pl-3 pointer-events-none">
                <svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            <input
                value={filter}
                disabled={isError}
                onChange={onChangeFilter}
                type="text"
                className="block p-2 pl-10 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Zoeken..."
            />
        </div>
    );
}

export default SearchField;
