import React, {useEffect, useState} from 'react';
import TabContainer from "../../../components/global/TabContainer";
import DoosForm from "../../../components/bekerverhuur/dozen/DoosForm";
import useAddDoos from "../../../data/bekerverhuur/dozen/useAddDoos";
import {useNavigate} from "react-router-dom";

function DoosAddPage() {
    const [doos, setDoos] = useState({naam: "", aantalBekers: 0, bekertypeNaam: "", aantalKeerGebruikt: 0})
    const {mutate: doosToevoegen, isLoading, isSuccess} = useAddDoos()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            navigate("/bekerverhuur/dozen")
        }
    }, [isSuccess, navigate])

    return (
        <TabContainer>
            <h4>Doos aanmaken</h4>
            <DoosForm
                doos={doos}
                setDoos={setDoos}
                action={doosToevoegen}
                isLoading={isLoading}
                buttonLabel={"Toevoegen"}
            />
        </TabContainer>
    );
}

export default DoosAddPage;
