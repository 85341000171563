import React from 'react';
import {useNavigate} from "react-router-dom";
import OptionsButton from "../../global/listitems/OptionsButton";
import BoldText from "../../global/listitems/BoldText";
import NormalText from "../../global/listitems/NormalText";
import Cell from "../../global/listitems/Cell";
import BoxText from "../../global/listitems/BoxText";
import Row from "../../global/listitems/Row";
import useCheckPermissies from "../../../hooks/useCheckPermissies";

function BekertypeListItem({bekertype}) {
    const navigate = useNavigate()

    const options = [{
        action: () => navigate(`/bekerverhuur/bekertypes/wijzigen/${encodeURI(bekertype.naam)}`),
        label: "Wijzig",
        condition: useCheckPermissies("wijzigBekertype")
    }]

    return (
        <Row>
            <Cell>
                <BoldText text={bekertype.naam}/>
            </Cell>
            <Cell>
                <NormalText text={`€ ${bekertype.huurprijs.toFixed(2).replace(".", ",")} / beker`}/>
            </Cell>
            <Cell>
                <NormalText text={`€ ${bekertype.waarborgprijs.toFixed(2).replace(".", ",")} / beker`}/>
            </Cell>
            <Cell>
                <NormalText text={`${bekertype.aantalBekersPerDoos} bekers`}/>
            </Cell>
            <Cell>
                <BoxText text={`${bekertype.inhoud} Cl`} width={"w-14"}/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButton options={options}/>
            </Cell>
        </Row>
    );
}

export default BekertypeListItem;
