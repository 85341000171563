import LoginPage from "./pages/LoginPage";
import {useLocation, Routes, Route, Navigate} from "react-router-dom";
import {ReactQueryDevtools} from 'react-query/devtools'
import {AnimatePresence, motion} from "framer-motion";
import Stack from "./pages/Stack";
import {QueryClient, QueryClientProvider} from "react-query";
import AuthenticationRoute from "./components/login/AuthenticationRoute";
import PrivateRoute from "./components/login/PrivateRoute";
import {useEffect} from "react";
import {ThemeProvider} from "./components/global/ThemeContext";

function App() {
    const location = useLocation()
    const locations = ["/login"]
    const queryClient = new QueryClient()

    //gets screen size - to fix mobile viewport height problem
    useEffect(() => {
        // only execute all the code below in client side
        if (typeof window !== 'undefined') {
            // Handler to call on window resize
            function handleResize() {
                let vh = window.innerHeight * 0.01
                document.documentElement.style.setProperty('--vh', `${vh}px`)
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <motion.div
                    className={`bg-yellow-25 dark:bg-gray-900 transition-all`}
                >
                    <AnimatePresence exitBeforeEnter>
                        <Routes location={location}
                                key={locations.includes(location.pathname) ? location.pathname : "else"}>
                            <Route exact path={"/login"} element={<AuthenticationRoute
                                redirect={"/dashboard"}><LoginPage/></AuthenticationRoute>}/>
                            <Route exact path={"/"}
                                   element={<PrivateRoute><Navigate to={"/login"}/></PrivateRoute>}/>
                            <Route path={"*"} element={<Stack/>}/>
                        </Routes>
                    </AnimatePresence>
                </motion.div>
            </ThemeProvider>
            {process.env.NODE_ENV === "development" && (
                <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"}/>
            )}
        </QueryClientProvider>
    );
}

export default App;
