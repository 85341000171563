import React, {useEffect, useState} from 'react';
import TabContainer from "../../../components/global/TabContainer";
import {useNavigate, useParams} from "react-router-dom";
import useFetchDoos from "../../../data/bekerverhuur/dozen/useFetchDoos";
import DoosForm from "../../../components/bekerverhuur/dozen/DoosForm";
import useUpdateDoos from "../../../data/bekerverhuur/dozen/useUpdateDoos";

function DoosEditPage() {
    const {naam: oldNaam} = useParams();
    const [doos, setDoos] = useState({oldNaam, naam: "", aantalBekers: 0, bekertypeNaam: "", aantalKeerGebruikt: 0})
    const {isSuccess, data} = useFetchDoos(oldNaam)
    const {mutate: updateDoos, isLoading: isUpdating, isSuccess: isUpdated} = useUpdateDoos()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            setDoos({...data, oldId: oldNaam})
        }
    }, [isSuccess, data, oldNaam])

    useEffect(() => {
        if (isUpdated) navigate("/bekerverhuur/dozen")
    }, [isUpdated, navigate])

    return (
        <TabContainer>
            <h4>Doos wijzigen</h4>
            <DoosForm
                buttonLabel={"Wijzigen"}
                doos={doos}
                setDoos={setDoos}
                action={updateDoos}
                isLoading={isUpdating}
            />
        </TabContainer>
    );
}

export default DoosEditPage;
