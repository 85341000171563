import React from 'react';
import Row from "../../../global/listitems/Row";
import Cell from "../../../global/listitems/Cell";
import BoldText from "../../../global/listitems/BoldText";
import NormalText from "../../../global/listitems/NormalText";
import BoxText from "../../../global/listitems/BoxText";

function StocktellingDetailListItem({consumptie}) {
    const inStock = `${Math.floor(consumptie.inStock / consumptie.consumptieEenheid.type.aantalConsumpties)} ${consumptie.consumptieEenheid.type.meervoudNaam} & ${consumptie.inStock % consumptie.consumptieEenheid.type.aantalConsumpties} consumpties`
    const inKoeling = `${consumptie.inKoeling} consumpties`
    const uitStock = `${Math.floor(consumptie.uitStock / consumptie.consumptieEenheid.type.aantalConsumpties)} ${consumptie.consumptieEenheid.type.meervoudNaam} & ${consumptie.uitStock % consumptie.consumptieEenheid.type.aantalConsumpties} consumpties`
    const omzet = `€ ${(consumptie.uitStock * consumptie.verkoopprijs).toFixed(2).replace(".", ",")}`

    return (
        <Row>
            <Cell>
                <BoldText text={consumptie.consumptieEenheid.consumptie.naam}/>
            </Cell>
            <Cell>
                <NormalText text={consumptie.consumptieEenheid.type.naam}/>
            </Cell>
            <Cell>
                <NormalText
                    text={inStock}
                />
            </Cell>
            <Cell>
                <NormalText
                    text={inKoeling}
                />
            </Cell>
            <Cell>
                <NormalText
                    text={uitStock}
                />
            </Cell>
            <Cell>
                <BoxText
                    width={"w-24"}
                    text={omzet}
                    color={consumptie.uitStock > 0 ? "green" : consumptie.uitStock === 0 ? "gray" : "red"}
                />
            </Cell>
        </Row>
    );
}

export default StocktellingDetailListItem;
