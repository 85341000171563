import React from 'react';

function ShoppingBag(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6 2L3 6v14a2 2 0 002 2h14a2 2 0 002-2V6l-3-4H6zM3 6h18M16 10a4 4 0 11-8 0"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>    );
}

export default ShoppingBag;
