import React from 'react';
import DetailContainer from "../../global/DetailContainer";

function BeschikbaarBekertype({bekertype, setReservatie, reservatie}) {
    const gekozenAantal = reservatie?.bekers.find(be => be.naam === bekertype.naam)?.aantal

    const updateReservatie = (name, amount) => {
        setReservatie((old) => {
            if (old.bekers.some(b => b.naam === name)) {
                return {...old, bekers: [...old.bekers.filter(b => b.naam !== name), {naam: name, aantal: amount}]}
            } else {
                return {...old, bekers: [...old.bekers, {naam: name, aantal: amount}]}
            }
        })
    }

    const onChangeAantal = (e) => {
        const val = e.target.value
        if (val >= 0 && val <= bekertype.aantal) {
            updateReservatie(bekertype.naam, val)
        } else if (!val) {
            updateReservatie(bekertype.naam, 0)
        } else if (val > bekertype.aantal) {
            updateReservatie(bekertype.naam, bekertype.aantal)
        }
    }

    return (
        <DetailContainer>
            <div className={"px-4 mb-3"}>
                <h6 className={"text-md font-medium dark:text-gray-100"}>{bekertype.naam} ({bekertype.inhoud} Cl)</h6>
            </div>
            <div className={"px-4 mt-2"}>
                <div className={"flex flex-row grid grid-cols-2 mb-2"}>
                    <div>
                        <p className={"pl-1 text-gray-600 dark:text-gray-300 text-xs"}>Aantal beschikbaar</p>
                        <p className={"pl-2 text-gray-800 dark:text-gray-200"}>{bekertype.aantal}</p>
                    </div>
                    <div>
                        <p className={"pl-1 text-gray-600 dark:text-gray-300 text-xs"}>Bekers/doos</p>
                        <p className={"pl-2 text-gray-800 dark:text-gray-200"}>{bekertype.aantalBekersPerDoos}</p>
                    </div>
                </div>
                <div className={"flex flex-row grid grid-cols-2 mb-2"}>
                    <div>
                        <p className={"pl-1 text-gray-600 dark:text-gray-300 text-xs"}>Waarborg/beker</p>
                        <p className={"pl-2 text-gray-800 dark:text-gray-200"}>€ {(bekertype.waarborgprijs).toFixed(2).replace(".", ",")}</p>
                    </div>
                    <div>
                        <p className={"pl-1 text-gray-600 dark:text-gray-300 text-xs"}>Prijs/beker</p>
                        <p className={"pl-2 text-gray-800 dark:text-gray-200"}>€ {(bekertype.huurprijs).toFixed(2).replace(".", ",")}</p>
                    </div>
                </div>
                <div>
                    <p className={"pl-1 text-gray-600 dark:text-gray-300 text-xs"}>Aantal bekers</p>
                    <input
                        className={`mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        type={"number"}
                        step={bekertype.aantalBekersPerDoos}
                        value={gekozenAantal ? gekozenAantal : 0}
                        onChange={onChangeAantal}
                    />
                </div>
            </div>
        </DetailContainer>
    );
}

export default BeschikbaarBekertype;
