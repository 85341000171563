import React from 'react';
import Row from "../global/listitems/Row";
import Cell from "../global/listitems/Cell";
import BoldTextLoading from "../global/listitems/loading/BoldTextLoading";
import BoxTextLoading from "../global/listitems/loading/BoxTextLoading";
import NormalTextLoading from "../global/listitems/loading/NormalTextLoading";
import RoundTextLoading from "../global/listitems/loading/RoundTextLoading";
import DetailsButtonLoading from "../global/listitems/loading/DetailsButtonLoading";

function ReservatieProblemenListLoading() {
    return (
        <Row loading={true}>
            <Cell>
                <BoldTextLoading width={"w-[30px]"}/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-48"}/>
            </Cell>
            <Cell>
                <NormalTextLoading/>
            </Cell>
            <Cell>
                <RoundTextLoading/>
                <RoundTextLoading/>
            </Cell>
            <Cell justify={"justify-end"}>
                <DetailsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default ReservatieProblemenListLoading;
