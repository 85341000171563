import React from 'react';
import Row from "../../global/listitems/Row";
import Cell from "../../global/listitems/Cell";
import BoldText from "../../global/listitems/BoldText";
import NormalText from "../../global/listitems/NormalText";

function ReservatieDoosListItem({beker}) {
    return (
        <Row>
            <Cell>
                <BoldText text={beker.naam}/>
            </Cell>
            <Cell>
                <NormalText text={beker.type}/>
            </Cell>
            <Cell>
                <NormalText text={beker.aantalBekers}/>
            </Cell>
            <Cell>
                <NormalText text={`€ ${beker.huurprijs.toFixed(2).replace(".", ",")}`}/>
            </Cell>
            <Cell>
                <NormalText text={`€ ${beker.waarborg.toFixed(2).replace(".", ",")}`}/>
            </Cell>
            <Cell>
                <NormalText text={`€ ${(beker.huurprijs + beker.waarborg).toFixed(2).replace(".", ",")}`}/>
            </Cell>
        </Row>
    );
}

export default ReservatieDoosListItem;
