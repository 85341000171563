import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import authHeader from "../../util/auth-header";
import {useAlerts} from "../../components/global/alerts/AlertContext";

const changeZichtbaarheidEvenement = (data) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/evenementen/zichtbaar`, data, {headers: authHeader()})
}

function useChangeZichtbaarheidEvenement() {
    const {setAlert} = useAlerts()
    const queryClient = useQueryClient()
    return useMutation('changeZichtbaarheidEvenement', changeZichtbaarheidEvenement, {
        onSuccess: (data) => {
            setAlert(data?.data?.message, "SUCCESS")
            queryClient.invalidateQueries('fetchEvenementen')
        },
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        }
    })
}

export default useChangeZichtbaarheidEvenement
