import React from 'react';
import ListContainer from "../../../components/global/ListContainer";
import useFetchAssortiment from "../../../data/dranken/assortiment/useFetchAssortiment";
import AssortimentListItem from "../../../components/dranken/assortiment/AssortimentListItem";
import useCheckPermissies from "../../../hooks/useCheckPermissies";
import AssortimentListLoading from "../../../components/dranken/assortiment/AssortimentListLoading";

function AssortimentListPage() {
    return (
        <ListContainer
            type={"Assortiment"}
            addPath={"/dranken/assortiment/toevoegen"}
            addPermissie={useCheckPermissies("upsertConsumptie")}
            dataFunction={useFetchAssortiment}
            useFilter={true}
            listItemLambda={consumptie => (
                <AssortimentListItem key={consumptie.naam} consumptie={consumptie}/>
            )}
            placeholderLambda={index => (
                <AssortimentListLoading key={index}/>
            )}
        />
    );
}

export default AssortimentListPage;
