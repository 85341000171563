import React from 'react';
import HeaderRow from "../../../global/listitems/HeaderRow";
import Cell from "../../../global/listitems/Cell";
import BoldText from "../../../global/listitems/BoldText";

function StocktellingDetailHeader() {
    return (
        <HeaderRow>
            <Cell>
                <BoldText text={"Consumptie"}/>
            </Cell>
            <Cell>
                <BoldText text={"Type"}/>
            </Cell>
            <Cell>
                <BoldText text={"In Stock"}/>
            </Cell>
            <Cell>
                <BoldText text={"Waarvan in Koeling"}/>
            </Cell>
            <Cell>
                <BoldText text={"Inkoop/Verkoop"}/>
            </Cell>
            <Cell>
                <BoldText text={"Omzet"}/>
            </Cell>
        </HeaderRow>

    );
}

export default StocktellingDetailHeader;
