import React from 'react';
import {useParams} from "react-router-dom";
import TabContainer from "../../../components/global/TabContainer";
import useFetchConsumptie from "../../../data/dranken/assortiment/useFetchConsumptie";
import Loading from "../../../components/global/Loading";
import EenheidDetail from "../../../components/dranken/assortiment/eenheden/EenheidDetail";
import EenheidAddButton from "../../../components/dranken/assortiment/eenheden/EenheidAddButton";
import BackButton from "../../../components/global/forms/BackButton";
import {useUserInfo} from "../../../components/global/sidebar/UserContext";

function AssortimentDetailPage() {
    const {naam} = useParams()
    const {data: consumptie, isSuccess} = useFetchConsumptie(naam)
    const {permissies} = useUserInfo()

    if (!isSuccess) {
        return <Loading/>
    }

    return (
        <TabContainer>
            <h4>Detailoverzicht {naam}</h4>
            <div className={"flex grid md:grid-cols-2 md:gap-4"}>
                <div className={"mt-3 w-full"}>
                    <h5>Verkoopprijs</h5>
                    <p className={"pl-2 text-gray-800 dark:text-gray-300"}>{`€ ${consumptie.verkoopprijs.toFixed(2).replace(".", ",")}`}</p>
                </div>
                <div className={"mt-3 w-full"}>
                    <h5>Categorie</h5>
                    <p className={"pl-2 text-gray-800 dark:text-gray-300"}>{consumptie.categorie.naam}</p>
                </div>
                <div className={'mt-3 col-span-2'}>
                    <h5>Eenheden</h5>
                    <div className={"mt-3 pl-2 flex flex-row grid md:grid-cols-3 xl:grid-cols-4 md:gap-4"}>
                        {
                            consumptie.eenheden.map(e => (<EenheidDetail key={e.type.naam} eenheid={e} id={consumptie.id}/>))
                        }
                        {
                            permissies.includes("stocktelling") && (
                                <EenheidAddButton id={consumptie.id}/>
                            )
                        }
                    </div>
                </div>
            </div>
            <BackButton to={"/dranken/assortiment"}/>
        </TabContainer>
    );
}

export default AssortimentDetailPage;
