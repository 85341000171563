import React from 'react';
import {validateDiscordId, validateOpmerking, validateTapweek} from "../../../util/validator";
import InputField from "../../global/forms/InputField";
import SubmitButton from "../../global/forms/SubmitButton";
import {useAlerts} from "../../global/alerts/AlertContext";
import SelectField from "../../global/forms/SelectField";
import useFetchActieveBestuursleden from "../../../data/bestuursleden/useFetchActieveBestuursleden";
import TextAreaField from "../../global/forms/TextAreaField";
import BackButton from "../../global/forms/BackButton";

function TapkalenderForm({tapweek, setTapweek, isLoading, action, buttonLabel}) {
    const {setAlert} = useAlerts()
    const {isSuccess: bestuursledenIsSuccess, data: bestuursleden} = useFetchActieveBestuursleden()

    const onChange = (e) => {
        setTapweek(old => ({...old, [e.target.name]: e.target.value}))
    }

    const onClick = (e) => {
        e.preventDefault()
        if (controlData()) {
            action(tapweek)
        }
    }

    const controlData = () => {
        const {weeknummer, jaar} = validateTapweek(tapweek.weeknummer, tapweek.jaar)
        if (!weeknummer || !jaar) {
            setAlert("Ongeldige periode.", "ERROR")
            return false
        }
        const bestuurslid = validateDiscordId(tapweek.bestuurslidId)
        if (!bestuurslid) {
            setAlert("Ongeldig bestuurslid", "ERROR")
            return false
        }
        const opmerking = validateOpmerking(tapweek.opmerking)
        if (!opmerking) {
            setAlert("Ongeldige opmerking", "ERROR")
            return false
        } else {
            setTapweek(old => ({...old, opmerking}))
        }
        return true
    }

    return (
        <form>
            <div className={"mt-5 grid xl:grid-cols-2 xl:gap-6"}>
                <div className={"w-full"}>
                    <InputField
                        label={"Weeknummer"}
                        name={"weeknummer"}
                        type={"number"}
                        placeholder={"Weeknummer..."}
                        value={tapweek.weeknummer}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Jaartal"}
                        name={"jaar"}
                        type={"number"}
                        placeholder={"Jaartal..."}
                        value={tapweek.jaar}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <SelectField
                        name={"bestuurslidId"}
                        label={"Tapverantwoordelijke"}
                        options={bestuursledenIsSuccess ? bestuursleden : []}
                        value={tapweek.bestuurslidId}
                        onChange={onChange}
                        optionMapper={(option) => (<option key={option.discordId}
                                                           value={option.discordId}>{option.voornaam} {option.familienaam}</option>)}
                        required
                    />
                </div>
                <div className={"w-full"}>
                    <TextAreaField
                        name={"opmerking"}
                        label={"Opmerkingen"}
                        placeholder={"Opmerking..."}
                        onChange={onChange}
                        value={tapweek.opmerking}
                    />
                </div>
            </div>
            <div className={"flex flex-row"}>
                <BackButton
                    to={"/tappen/tapkalender"}
                />
                <SubmitButton
                    label={buttonLabel}
                    isLoading={isLoading}
                    onClick={onClick}
                />
            </div>
        </form>);
}

export default TapkalenderForm;
