import React from 'react';
import {useNavigate} from "react-router-dom";
import {useUserInfo} from "./UserContext";
import Toggle from "../ThemeToggle";

function SidebarProfile() {
    const navigate = useNavigate()
    const {user} = useUserInfo()

    const handleUitloggen = () => {
        localStorage.removeItem("token")
        navigate("/login")
    }

    return (
        <div className="flex flex-row px-8 py-4 border-t border-gray-200 dark:border-gray-900 items-center">
            <div className="h-12 w-16">
                <img
                    src={user.afbeelding ? user.afbeelding : "https://s3.eu-west-1.amazonaws.com/odt.website/bestuursleden/768893910487138335.jpg"}
                    className={"object-cover w-12 h-12 rounded-full"} alt={"profile"}/>
            </div>
            <div className={"pl-4 flex flex-row justify-between w-full items-center"}>
                <div>
                    <p className={"text-gray-600 dark:text-gray-300"}>{user.voornaam} {user?.familienaam?.match(/\b(\w)/g).join(". ")}.</p>
                    <div onClick={handleUitloggen} className={"text-gray-400 text-sm cursor-pointer dark:text-gray-500"}>Uitloggen</div>
                </div>
                <div>
                    <Toggle/>
                </div>
            </div>
        </div>
    );
}

export default SidebarProfile;
