import {useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const fetchAssortiment = (pagina, filter) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/consumpties?limit=10&pagina=${pagina}&zoeken=${filter}`, {headers: authHeader()})
}

function useFetchAssortiment(pagina, filter) {
    const {setAlert} = useAlerts()
    return useQuery(['fetchAssortiment', pagina, filter],() => fetchAssortiment(pagina, filter), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: data => data.data
    })
}

export default useFetchAssortiment
