import React from 'react';
import Row from "../global/listitems/Row";
import Cell from "../global/listitems/Cell";
import Checkbox from "../global/listitems/Checkbox";
import BoldText from "../global/listitems/BoldText";
import NormalText from "../global/listitems/NormalText";

function BestuursledenPermissieListItem({permissie, checked, onChange}) {
    return (
        <Row>
            <Cell>
                <Checkbox checked={checked} onChange={onChange} id={permissie.id}/>
            </Cell>
            <Cell>
                <BoldText text={permissie.naam}/>
            </Cell>
            <Cell>
                <NormalText text={permissie.beschrijving}/>
            </Cell>
        </Row>
    );
}

export default BestuursledenPermissieListItem;
