import React, {useEffect, useState} from 'react';
import TabContainer from "../../../components/global/TabContainer";
import Loading from "../../../components/global/Loading";
import TextAreaField from "../../../components/global/forms/TextAreaField";
import BackButton from "../../../components/global/forms/BackButton";
import SubmitButton from "../../../components/global/forms/SubmitButton";
import useFetchTeBestellen from "../../../data/dranken/stocktelling/useFetchTeBestellen";
import SelectField from "../../../components/global/forms/SelectField";
import useFetchBeschikbareEenheden from "../../../data/dranken/stocktelling/useFetchBeschikbareEenheden";
import AddBestellingListItem from "../../../components/dranken/stocktelling/bestellingen/AddBestellingListItem";
import useCreateBestelling from "../../../data/dranken/stocktelling/useCreateBestelling";
import {useNavigate} from "react-router-dom";

function BestellingAddPage() {
    const navigate = useNavigate()
    const [eenheden, setEenheden] = useState([])
    const [selected, setSelected] = useState("")
    const [consumpties, setConsumpties] = useState([])
    const [opmerking, setOpmerking] = useState("")
    const {data: teBestellen, isSuccess: isLoaded} = useFetchTeBestellen()
    const {data: beschikbareEenheden, isSuccess: eenhedenLoaded} = useFetchBeschikbareEenheden()
    const {mutate: createBestelling, isLoading, isSuccess} = useCreateBestelling()

    useEffect(() => {
        if (eenhedenLoaded) {
            setEenheden(beschikbareEenheden)
        }
    }, [eenhedenLoaded])

    useEffect(() => {
        if (isSuccess) {
            navigate("/dranken/stocktellingen")
        }
    }, [isSuccess])

    useEffect(() => {
        if (isLoaded) {
            setConsumpties(() => ([
                ...teBestellen.map(tb => ({
                    id: tb.id,
                    typeNaam: tb.type,
                    consumptieNaam: tb.consumptie,
                    aantal: tb.aantal
                }))
            ]))
        }
    }, [teBestellen, isLoaded])

    const handleSubmit = (e) => {
        e.preventDefault()
        createBestelling({opmerking, consumpties})
    }

    const handleChange = (id, aantal) => {
        setConsumpties(old => {
            let index = old.findIndex(c => c.id === id)
            old[index].aantal = aantal
            return old
        })
    }

    const handleDelete = (id) => {
        setConsumpties(old => {
            return old.filter(c => c.id !== id)
        })
    }

    const addSelected = () => {
        if (selected === "") return
        const cons = eenheden.find((i) => i.id === parseInt(selected))
        setConsumpties((old => ([...old, {
            id: cons.id,
            typeNaam: cons.typeNaam,
            consumptieNaam: cons.consumptie.naam,
            aantal: 1
        }])))
        setSelected("")
    }

    if (!isLoaded) {
        return (
            <TabContainer>
                <h5>Bestelling toevoegen</h5>
                <Loading/>
            </TabContainer>
        )
    }

    console.log(consumpties)

    return (
        <TabContainer>
            <h5>Bestelling toevoegen</h5>
            {consumpties.length > 0 && (
                <div
                    className={"duration-300 rounded-md border border-gray-200 shadow-sm hover:shadow-md my-4 dark:border-none dark:bg-gray-700 p-4"}>
                    <h6 className={"font-medium dark:text-gray-200 mb-4"}>Consumpties</h6>
                    <div className="overflow-y-auto overflow-x-auto">
                        <table className="w-full">
                            <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr className={"border dark:border-none"}>
                                <th scope="col"
                                    className="py-3 px-6 text-sm font-medium text-left text-gray-700 dark:text-gray-100 w-1/4">
                                    Consumptie
                                </th>
                                <th scope="col"
                                    className="py-3 px-6 text-sm font-medium text-left text-gray-700 dark:text-gray-100 w-1/4">
                                    Type
                                </th>
                                <th scope="col"
                                    className="py-3 px-6 text-sm font-medium text-left text-gray-700 dark:text-gray-100 w-1/4">
                                    Aantal
                                </th>
                                <th scope="col"
                                    className="py-3 px-6 text-sm font-medium text-left text-gray-700 dark:text-gray-100 w-1/4">
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {consumpties.map((consumptie) => (
                                <AddBestellingListItem consumptie={consumptie} key={consumptie.id}
                                                       handleChange={handleChange} handleDelete={handleDelete}/>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {eenheden.filter(e => !consumpties.some(c => c.id === e.id)).length > 0 && (
                <div
                    className={"my-4 duration-300 rounded-md border mb-4 p-4 shadow-sm hover:shadow-md dark:border-none dark:bg-gray-700"}>
                    <h6 className={"font-medium dark:text-gray-200 mb-2"}>Consumptie toevoegen</h6>
                    <div className={"flex flex-row"}>
                        <div className={"w-full"}>
                            <SelectField
                                value={selected}
                                onChange={(e) => {
                                    setSelected(e.target.value)
                                }}
                                label={""}
                                name={"consumpties"}
                                options={eenheden.filter(e => !consumpties.some(c => c.id === e.id))}
                                optionMapper={(opt) => (
                                    <option key={opt.id}
                                            value={opt.id}>{opt.consumptie.naam} - {opt.typeNaam}</option>)}
                            />
                        </div>
                        <div className={"mt-2 ml-2 mr-2"}>
                            <SubmitButton label={"Toevoegen"} isLoading={false} onClick={addSelected}
                                          disabled={selected === ""}/>
                        </div>
                    </div>
                </div>
            )}
            <div
                className={"duration-300 rounded-md border border-gray-200 shadow-sm hover:shadow-md my-4 dark:border-none dark:bg-gray-700 p-4"}>
                <h6 className={"font-medium dark:text-gray-200 mb-4"}>Opmerking</h6>
                <TextAreaField
                    label={""}
                    placeholder={"Opmerking..."}
                    name={"opmerking"}
                    value={opmerking}
                    onChange={(e) => setOpmerking(e.target.value)}
                />
            </div>
            <div className={"flex flex-row"}>
                <BackButton to={"/dranken/stocktellingen"}/>
                <SubmitButton label={"Toevoegen"} isLoading={isLoading} onClick={handleSubmit}/>
            </div>
        </TabContainer>
    );
}

export default BestellingAddPage;
