import React, {useEffect, useState} from 'react';

function NormalTextLoading({width:w}) {
    const widths = ["w-24", "w-32", "w-48", "w-56"]
    const [width, setWidth] = useState(widths[Math.floor(Math.random() * (widths.length - 1))])

    useEffect(() => {
        if (w) {
            setWidth(w)
        }
    }, [])

    return (
        <div className={"ml-2 h-4 rounded bg-gray-100 dark:bg-gray-600 " + width}/>
    );
}

export default NormalTextLoading;
