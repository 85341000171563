import React from 'react';
import useFetchStocktelling from "../../../data/dranken/stocktelling/useFetchStocktelling";
import {useParams} from "react-router-dom";
import TabContainer from "../../../components/global/TabContainer";
import Loading from "../../../components/global/Loading";
import moment from "moment";
import StocktellingDetailListItem from "../../../components/dranken/stocktelling/stocktellingen/StocktellingDetailListItem";
import BackButton from "../../../components/global/forms/BackButton";
import StocktellingDetailHeader from "../../../components/dranken/stocktelling/stocktellingen/StocktellingDetailHeader";

function StocktellingDetailPage() {
    const {id} = useParams()
    const {data: stocktelling, isSuccess} = useFetchStocktelling(id)

    if (!isSuccess) {
        return (
            <TabContainer>
                <h4>Overzicht stocktelling</h4>
                <Loading/>
            </TabContainer>
        )
    }

    const aantalVerkocht = stocktelling.consumpties.reduce((a, b) => {
        if (b.uitStock > 0) {
            return a + b.uitStock
        } else {
            return  a
        }
    }, 0)

    const totaleOmzet = stocktelling.consumpties.reduce((a, b) => {
            return a + (b.verkoopprijs * b.uitStock)
    }, 0)

    return (
        <TabContainer>
            <h4>Overzicht stocktelling</h4>
            <div className={"flex flex-row grid md:grid-cols-3 md:gap-6"}>
                <div className={"flex flex-col"}>
                    <h5 className={"mt-3 mb-2"}>Datum</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{moment(stocktelling.datum).format("DD/MM/YYYY")}</p>
                </div>
                <div className={"flex flex-col"}>
                    <h5 className={"mt-3 mb-2"}>Aantal verkochte consumpties</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{aantalVerkocht} consumpties</p>
                </div>
                <div className={"flex flex-col"}>
                    <h5 className={"mt-3 mb-2"}>Totale omzet</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>€ {totaleOmzet.toFixed(2).replace(".",",")}</p>
                </div>

                <div className={"flex flex-col md:col-span-3"}>
                    <h5 className={"mt-3 mb-2"}>Opmerkingen</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{stocktelling.opmerking ?? "Geen opmerkingen"}</p>
                </div>
            </div>
            <div className={"flex flex-col"}>
                <h5 className={"mt-3 mb-2"}>Detailoverzicht</h5>
                <div className="overflow-y-auto overflow-x-auto">
                    <table className="w-full whitespace-nowrap">
                        <thead>
                        <StocktellingDetailHeader/>
                        </thead>
                        <tbody>
                        {isSuccess && stocktelling.consumpties.map(c => (
                            <StocktellingDetailListItem key={c.id} consumptie={c}/>))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={"mb-3 mt-5"}>
                <BackButton to={"/dranken/stocktellingen"}/>
            </div>
        </TabContainer>
    );
}

export default StocktellingDetailPage;
