import React, {useState} from 'react';
import {BsPlusLg} from 'react-icons/bs'
import DetailContainer from "../../../global/DetailContainer";
import EenheidAddModal from "./EenheidAddModal";

function EenheidAddButton({id}) {
    const [showAddModal, setShowAddModal] = useState(false)

    return (
        <DetailContainer>
            <EenheidAddModal shown={showAddModal} setShown={setShowAddModal} id={id}/>
            <h6 className={"text-md font-medium px-4 dark:text-gray-200 mb-3"}>Eenheid toevoegen</h6>
            <div className={"flex justify-center pb-16"}>
                <div
                    onClick={() => setShowAddModal(true)}
                    className={"flex bg-yellow-400 text-white dark:text-gray-700 rounded-full h-24 w-24 justify-center items-center mt-10 hover:cursor-pointer hover:shadow hover:bg-yellow-500 duration-300"}
                >
                    <BsPlusLg size={32}/>
                </div>
            </div>
        </DetailContainer>
    );
}

export default EenheidAddButton;
