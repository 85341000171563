import {useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const fetchStocktellingen = (pagina) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/stocktellingen?limit=3&pagina=${pagina}`, {headers: authHeader()})
}

function useFetchStocktellingen(pagina) {
    const {setAlert} = useAlerts()
    return useQuery(['fetchStocktellingen', pagina],() => fetchStocktellingen(pagina), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: data => data.data
    })
}

export default useFetchStocktellingen
