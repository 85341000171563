import React, {Fragment} from 'react';
import TabContainer from "../global/TabContainer";
import {useFetchHuidigeDrankVdMaand} from "../../data/dranken/drankvdmaand/useFetchHuidigeDrankVdMaand";
import moment from "moment";
import Button from "../global/Button";
import {useNavigate} from "react-router-dom";
import DrankVdMaandLoading from "./DrankVdMaandLoading";

function DrankVdMaand() {
    const navigate = useNavigate()
    const maanden = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"]
    const {data: drank, isSuccess, isLoading, isError} = useFetchHuidigeDrankVdMaand()
    const curr = drank?.maand === moment().month() + 1 && drank?.jaartal === moment().year()

    return (
        <TabContainer>
            <div className={"flex flex-col w-full"}>
                <h4>Drank van de maand</h4>
                { isLoading && (<DrankVdMaandLoading/>)}
                {(!isLoading && isSuccess && curr) && (
                    <Fragment>
                        <img
                            className={"max-h-80 mx-auto"}
                            src={drank.afbeelding}
                            alt={drank.naam}
                        />
                        <div className={"flex justify-between lg:mx-10 items-center"}>
                            <p className={"text-lg text-gray-800 dark:text-gray-200"}>{drank.naam}</p>
                            <p
                                className={"bg-gray-200 dark:bg-gray-900 px-3 py-2 rounded-md text-gray-800 dark:text-gray-200"}
                            >€ {drank.prijs.toFixed(2).replace(".", ",")}</p>
                        </div>
                    </Fragment>
                )}
                {(!isLoading && isSuccess && !curr) && (
                    <div className={"mt-6"}>
                        <p className={"text-gray-800 dark:text-gray-200"}>Geen drank ingesteld voor de maand {maanden[moment().month()]}.</p>
                        <Button
                            label={"Drank toevoegen"}
                            className={"mt-6"}
                            onClick={() => navigate("/dranken/drankenvdmaand")}
                        />
                    </div>
                )}
                {(!isLoading && isError) && (
                    <div className={"mt-6"}>
                        <p className={"text-gray-800 dark:text-gray-200"}>Oeps! Er liep iets mis bij het ophalen van de drank van de maand.</p>
                    </div>
                )}
            </div>
        </TabContainer>
    );
}

export default DrankVdMaand;
