import React from 'react';
import { Navigate } from 'react-router-dom'
import useAuth from "../../hooks/useAuth";

const AuthenticationRoute = ({ children, redirect }) => {
    const isAuthenticated = useAuth();
    return isAuthenticated ? <Navigate to={redirect}/> : children;
}

export default AuthenticationRoute;
