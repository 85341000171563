import React from 'react';
import InputField from "../../global/forms/InputField";
import SelectField from "../../global/forms/SelectField";
import SubmitButton from "../../global/forms/SubmitButton";
import {useAlerts} from "../../global/alerts/AlertContext";
import BackButton from "../../global/forms/BackButton";
import useFetchConsumptieCategorieen from "../../../data/dranken/assortiment/useFetchConsumptieCategorieen";
import {
    validateConsumptieCategorieId,
    validateConsumptieNaam,
    validatePrijs
} from "../../../util/validator";

function AssortimentForm({consumptie, setConsumptie, isLoading, action, buttonLabel}) {
    const {isSuccess: categorieIsSuccess, data: categorieen} = useFetchConsumptieCategorieen()
    const {setAlert} = useAlerts()

    const onSubmit = (e) => {
        e.preventDefault()
        if (controlData()) {
            action(consumptie)
        }
    }

    const onChange = (e) => {
        e.preventDefault();
        setConsumptie(old => ({...old, [e.target.name]: e.target.value}))
    }

    const controlData = () => {
        const naam = validateConsumptieNaam(consumptie.naam)
        if (!naam) {
            setAlert("Ongeldige naam.", "ERROR")
            return false
        } else {
            setConsumptie(old => ({...old, naam}))
        }
        const prijs = validatePrijs(consumptie.verkoopprijs)
        if (!prijs) {
            setAlert("Ongeldige prijs.", "ERROR")
            return false
        }
        const categorieId = validateConsumptieCategorieId(consumptie.categorieId)
        if (!categorieId) {
            setAlert("Ongeldige categorie.", "ERROR")
            return false
        }
        return true
    }

    return (
        <form>
            <div className={"mt-5 grid xl:grid-cols-2 xl:gap-6"}>
                <div className={"w-full"}>
                    <InputField
                        type={"text"}
                        label={"Naam"}
                        placeholder={"Naam..."}
                        name={"naam"}
                        value={consumptie.naam}
                        onChange={onChange}
                        required
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        type={"number"}
                        label={"Verkoopprijs"}
                        placeholder={"Prijs..."}
                        name={"verkoopprijs"}
                        value={consumptie.verkoopprijs}
                        min={"0"}
                        onChange={onChange}
                        required
                    />
                </div>
                <div className={"w-full"}>
                    <SelectField
                        name={"categorieId"}
                        label={"Categorie"}
                        options={categorieIsSuccess ? categorieen : []}
                        value={consumptie.categorieId}
                        onChange={onChange}
                        optionMapper={(option) => (<option key={option.id} value={option.id}>{option.naam}</option>)}
                        required
                    />
                </div>
            </div>
            <div className={"flex flex-row"}>
                <BackButton
                    to={"/dranken/assortiment"}
                />
                <SubmitButton
                    label={buttonLabel}
                    isLoading={isLoading}
                    onClick={onSubmit}
                />
            </div>
        </form>
    );
}

export default AssortimentForm;
