import React, {useEffect, useState} from 'react';

function AddBestellingListItem({consumptie, handleChange, handleDelete}) {
    const [aantal, setAantal] = useState(consumptie.aantal)

    useEffect(() => {
        handleChange(consumptie.id, parseInt(aantal))
    }, [aantal])

    const changeAantal = (val) => {
        if (parseInt(val) < 0) {
            setAantal(0)
        } else {
            setAantal(parseInt(val))
        }
    }

    return (
        <tr className="bg-white border dark:bg-gray-700 dark:border-gray-800">
            <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-gray-100 w-1/4">
                {consumptie.consumptieNaam}
            </td>
            <td className="py-4 px-6 text-sm text-gray-900 whitespace-nowrap dark:text-gray-100 w-1/4">
                {consumptie.typeNaam}
            </td>
            <td className="py-4 px-6 text-sm text-gray-900 whitespace-nowrap dark:text-gray-100 w-1/4">
                <input
                    className={"h-full pl-1 py-2 my-0 bg-transparent dark:text-gray-200 w-10 md:w-full"}
                    value={aantal}
                    onChange={(e) => {changeAantal(e.target.value)}}
                    name={"onvolledig"}
                    type={"number"}
                />
            </td>
            <td className="py-4 px-6 text-sm text-gray-900 whitespace-nowrap dark:text-gray-100 w-1/4">
                <div className={"flex justify-end"}>
                    <div onClick={() => handleDelete(consumptie.id)} className={"underline hover:cursor-pointer hover:text-gray-700 dark:hover:text-gray-300"}>verwijder</div>
                </div>
            </td>
        </tr>
    );
}

export default AddBestellingListItem;
