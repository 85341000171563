import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import TabContainer from "../../../components/global/TabContainer";
import moment from "moment";
import DrankVdMaandForm from "../../../components/dranken/drankenvdmaand/DrankVdMaandForm";
import useUpsertDrankVdMaand from "../../../data/dranken/drankvdmaand/useUpsertDrankVdMaand";

function DrankVdMaandAddPage() {
    const datum = moment()
    const [drank, setDrank] = useState({naam: "", prijs: 0, jaartal: datum.year(), maand: datum.month() + 1, omschrijving: "", afbeelding: "", afbeeldingType: ""})
    const {mutate: drankToevoegen, isLoading, isSuccess} = useUpsertDrankVdMaand()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            navigate("/dranken/drankenvdmaand")
        }
    }, [isSuccess, navigate])

    return (
        <TabContainer>
            <h4>Drank van de maand toevoegen</h4>
            <DrankVdMaandForm
                drank={drank}
                setDrank={setDrank}
                action={drankToevoegen}
                isLoading={isLoading}
                buttonLabel={"Toevoegen"}
            />
        </TabContainer>
    );
}

export default DrankVdMaandAddPage;
