import React from 'react';
import {useAlerts} from "./AlertContext";

function Alert({close}) {
    const {message, type} = useAlerts()
    const colors = {
        "INFO": "blue",
        "SUCCESS": "green",
        "DANGER": "yellow",
        "ERROR": "red",
        "OTHER": "gray"
    }

    return (
        <div className={"relative z-50"}>
            <div
                className={`absolute w-full flex p-4 mb-4 bg-${colors[type]}-100 dark:bg-${colors[type]}-400 mt-20 md:mt-0`}
                role="alert"
            >
                <svg
                    className={`flex-shrink-0 w-5 h-5 text-${colors[type]}-700 dark:text-${colors[type]}-900`}
                    fill="currentColor"
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"/>
                </svg>
                <div className={`ml-3 text-sm font-medium text-${colors[type]}-700 dark:text-${colors[type]}-900`}>
                    {message}
                </div>
                <button
                    className={`ml-auto -mx-1.5 -my-1.5 bg-${colors[type]}-100 text-${colors[type]}-500 rounded-lg focus:ring-2 focus:ring-${colors[type]}-400 p-1.5 hover:bg-${colors[type]}-200 inline-flex h-8 w-8 dark:bg-${colors[type]}-400 dark:text-${colors[type]}-900 dark:hover:bg-${colors[type]}-500`}
                    onClick={close} aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"/>
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default Alert;
