import React from 'react';
import TabContainer from "../../global/TabContainer";
import moment from "moment";
import ReservatieDoosListItem from "./ReservatieDoosListItem";
import useFetchReservatie from "../../../data/bekerverhuur/reservaties/useFetchReservatie";
import BackButton from "../../global/forms/BackButton";
import ReservatieDoosHeader from "./ReservatieDoosHeader";
import DownloadButton from "../../global/DownloadButton";

function ReservatieDetailContainer({reservatieId}) {
    const {isSuccess, data} = useFetchReservatie(reservatieId);

    if (!isSuccess) {
        return <div/>
    }

    return (
        <TabContainer>
            <div className={"flex flex-row justify-between"}>
                <h4>Reservatie {(reservatieId + '').padStart(6, '0')}</h4>
                <DownloadButton
                    url={`reservaties/${reservatieId}/contract`}
                    label={"Contract downloaden"}
                    className={"w-56"}
                    fileName={`contract_${(reservatieId + '').padStart(6, '0')}.pdf`}
                />
            </div>
            <div className={"flex flex-row grid xl:grid-cols-2 xl:gap-6"}>
                <div>
                    <h5 className={"mt-3"}>Huurder</h5>
                    <div className={"px-2 dark:text-gray-300"}>
                        <p>{data.klant.voornaam} {data.klant.familienaam}</p>
                        <p>{data.klant.vereniging}</p>
                        <p>{data.klant.telefoon}</p>
                        <p>{data.klant.email}</p>
                        <p>{data.klant.rekeningnummer}</p>
                    </div>
                </div>
                <div>
                    <h5 className={"mt-3"}>Reservatiedatum</h5>
                    <p className={"px-2 dark:text-gray-300"}>{moment(data.reservatiedatum).format("DD/MM/YYYY")}</p>
                    <h5 className={"mt-3"}>Periode</h5>
                    <p className={"px-2 dark:text-gray-300"}>{moment(data.begindatum).format("DD/MM/YYYY")} - {moment(data.einddatum).format("DD/MM/YYYY")}</p>
                </div>
            </div>
            <div>
                <h5 className={"mt-3 mb-2"}>Bekers</h5>
                <div className={"overflow-y-auto overflow-x-auto"}>
                    <table className="whitespace-nowrap mx-2 w-full">
                        <thead>
                        <ReservatieDoosHeader/>
                        </thead>
                        <tbody>
                        {data.dozen.map((b) => (<ReservatieDoosListItem beker={b} key={b.naam}/>))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={"w-full"}>
                <h5 className={"mt-3"}>Totaalprijs</h5>
                <p className={"pl-2 dark:text-gray-300"}>€ {data.dozen.map(d => (d.waarborg + d.huurprijs)).reduce((a, b) => {
                    return a + b
                }, 0).toFixed(2).replace(".", ",")}</p>
            </div>
            <div className={"mt-5 mb-3"}>
                <BackButton to={"/bekerverhuur/reservaties"}/>
            </div>
        </TabContainer>
    );
}

export default ReservatieDetailContainer;
