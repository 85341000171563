import React, {createContext, useContext, useEffect, useState} from 'react';
import jwt_decode from "jwt-decode";
import {useUserInfoData} from "../../../data/authentication/UseAuthenticationData";

const UserContext = createContext(null);

export const UserContextProvider = ({children}) => {
    const [user, setUser] = useState({})
    const [permissies, setPermissies] = useState([])
    let email;
    try {
        email = jwt_decode(localStorage.getItem("token")).data.email
    } catch (err) {}
    const {isSuccess, data: userInfo, refetch} = useUserInfoData(email)

    useEffect(() => {
        if (isSuccess) {
            setUser(userInfo)
            setPermissies(userInfo.permissies)
        }
    }, [isSuccess, userInfo])

    return (
        <UserContext.Provider value={{user, permissies, refetch}}>
            {children}
        </UserContext.Provider>
    )
}

export const useUserInfo = () => useContext(UserContext)
