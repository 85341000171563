import React from 'react';
import Cell from "../global/listitems/Cell";
import Row from "../global/listitems/Row";
import RoundImageLoading from "../global/listitems/loading/RoundImageLoading";
import BoldTextLoading from "../global/listitems/loading/BoldTextLoading";
import NormalTextLoading from "../global/listitems/loading/NormalTextLoading";
import BoxTextLoading from "../global/listitems/loading/BoxTextLoading";
import RoundTextLoading from "../global/listitems/loading/RoundTextLoading";
import DetailsButtonLoading from "../global/listitems/loading/DetailsButtonLoading";
import OptionsButtonLoading from "../global/listitems/loading/OptionsButtonLoading";

function EvenementenListLoading() {
    return (
        <Row loading={true}>
            <Cell>
                <RoundImageLoading/>
            </Cell>
            <Cell>
                <BoldTextLoading/>
            </Cell>
            <Cell>
                <NormalTextLoading width={"w-[80px]"}/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-32"}/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-36"}/>
            </Cell>
            <Cell>
                <RoundTextLoading/>
            </Cell>
            <Cell>
                <DetailsButtonLoading/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default EvenementenListLoading;
