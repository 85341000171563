import React, {useState} from 'react';
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";

function OptionsButton({options}) {
    const [showOptions, setShowOptions] = useState(false)
    const ref = useOnClickOutside(() => setShowOptions(false))

    function toggleOptions() {
        setShowOptions(old => !old)
    }

    const onClick = (action) => {
        action()
        setShowOptions(false)
    }

    if (!options.some(opt => opt.condition)) {
        return <div/>
    }

    return (
        <div ref={ref} className={"relative"}>
            <button
                className="focus:ring-2 rounded-md focus:outline-none"
                onClick={toggleOptions}
                aria-label="option"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                     fill="none" className={"stroke-gray-600 dark:stroke-gray-200"}>
                    <path
                        d="M4.16667 10.8332C4.62691 10.8332 5 10.4601 5 9.99984C5 9.5396 4.62691 9.1665 4.16667 9.1665C3.70643 9.1665 3.33334 9.5396 3.33334 9.99984C3.33334 10.4601 3.70643 10.8332 4.16667 10.8332Z"
                        strokeWidth="1.25" strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path
                        d="M10 10.8332C10.4602 10.8332 10.8333 10.4601 10.8333 9.99984C10.8333 9.5396 10.4602 9.1665 10 9.1665C9.53976 9.1665 9.16666 9.5396 9.16666 9.99984C9.16666 10.4601 9.53976 10.8332 10 10.8332Z"
                        strokeWidth="1.25" strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path
                        d="M15.8333 10.8332C16.2936 10.8332 16.6667 10.4601 16.6667 9.99984C16.6667 9.5396 16.2936 9.1665 15.8333 9.1665C15.3731 9.1665 15 9.5396 15 9.99984C15 10.4601 15.3731 10.8332 15.8333 10.8332Z"
                        strokeWidth="1.25" strokeLinecap="round"
                        strokeLinejoin="round"/>
                </svg>
            </button>
            <div
                className={`absolute z-30 right-0 top-7 dropdown-content rounded-lg bg-white dark:bg-gray-700 shadow ${showOptions ? "" : "hidden"}`}
            >
                {options.map(option => option.condition && (
                    <div
                        key={option.label}
                        onClick={() => onClick(option.action)}
                        className="focus:outline-none focus:text-yellow-600 dark:text-gray-300 dark:hover:bg-yellow-600 text-xs w-full rounded-lg hover:bg-yellow-400 py-4 px-4 cursor-pointer hover:text-white">
                        <p>{option.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OptionsButton;
