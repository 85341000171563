import React from "react";
import TabContainerAnimation from "../components/global/TabContainerAnimation";
import DrankVdMaand from "../components/dashboard/DrankVdMaand";
import UpcomingEvenementen from "../components/dashboard/UpcomingEvenementen";
import TapVerantwoordelijke from "../components/dashboard/TapVerantwoordelijke";
import ReservatieProblemen from "../components/dashboard/ReservatieProblemen";

function DashboardPage() {
    return (
        <TabContainerAnimation>
            <h3 className="mb-8">Dashboard</h3>
            <div className={"grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"}>
                <div className={"col-span-1 md:col-span-2"}>
                    <UpcomingEvenementen/>
                </div>
                <DrankVdMaand/>
                <TapVerantwoordelijke/>
                <div className={"col-span-1 md:col-span-2"}>
                    <ReservatieProblemen/>
                </div>
            </div>
        </TabContainerAnimation>
    );
}

export default DashboardPage;
