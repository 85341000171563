import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import TabContainer from "../../components/global/TabContainer";
import BestuurslidForm from "../../components/bestuursleden/BestuurslidForm";
import useFetchBestuurslid from "../../data/bestuursleden/useFetchBestuurslid";
import useUpsertBestuurslid from "../../data/bestuursleden/useUpsertBestuurslid";
import moment from "moment";

function BestuursledenEditPage() {
    const {discordId} = useParams();
    const [bestuurslid, setBestuurslid] = useState({
        discordId: "",
        voornaam: "",
        familienaam: "",
        email: "",
        functie: "",
        afbeelding: null,
        afbeeldingType: "",
        afdelingId: "",
        priveTel: "",
        priveEmail: ""
    })
    const {isSuccess, data} = useFetchBestuurslid(discordId)
    const {mutate: editBestuurslid,isLoading: isUpdating, isSuccess: isUpdated} = useUpsertBestuurslid()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            setBestuurslid(old => ({...old,...data, geboortedatum: moment(data.geboortedatum).format("YYYY-MM-DD")}))
        }
    }, [isSuccess, data])

    useEffect(() => {
        if (isUpdated) navigate("/bestuursleden")
    }, [isUpdated, navigate])

    return (
        <TabContainer>
            <h4>Bestuurslid wijzigen</h4>
            <BestuurslidForm
                bestuurslid={bestuurslid}
                setBestuurslid={setBestuurslid}
                buttonLabel={"Wijzigen"}
                action={editBestuurslid}
                update={true}
                isLoading={isUpdating}
            />
        </TabContainer>
    );
}

export default BestuursledenEditPage;
