import React from 'react';
import {useNavigate} from "react-router-dom";

function BackButton({to = "/dashboard"}, props) {
    const navigate= useNavigate()
    return (
        <div className={"mt-2 md:mt-0"}>
            <div
                onClick={() => {navigate(-1)}}
                className="duration-300 cursor-pointer text-white border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 w-24 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-900 dark:border-gray-900"
                {...props}
            >
                Terug
            </div>
        </div>

    );
}

export default BackButton;
