import React from 'react';
import Cell from "../global/listitems/Cell";
import Checkbox from "../global/listitems/Checkbox";
import BoldText from "../global/listitems/BoldText";
import NormalText from "../global/listitems/NormalText";
import Row from "../global/listitems/Row";

function BestuursledenBezittingListItem({toegang, checked, onChange}) {
    return (
        <Row>
            <Cell>
                <Checkbox checked={checked} onChange={onChange} id={toegang.id}/>
            </Cell>
            <Cell>
                <BoldText text={toegang.naam}/>
            </Cell>
            <Cell>
                <NormalText text={toegang.beschrijving}/>
            </Cell>
        </Row>    );
}

export default BestuursledenBezittingListItem;
