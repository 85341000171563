import jwt_decode from "jwt-decode";

export const useGetPermissies = () => {
    try {
        const token = localStorage.getItem("token")
        const {data} = jwt_decode(token)
        return data?.permissies.map(p => p.permissieId)
    } catch (e) {
        localStorage.removeItem("token")
        return []
    }
};

export default useGetPermissies
