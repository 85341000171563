import React, {Fragment} from 'react';
import TabContainer from "../../components/global/TabContainer";
import {useParams} from "react-router-dom";
import Loading from "../../components/global/Loading";
import moment from "moment";
import BackButton from "../../components/global/forms/BackButton";
import useFetchBestuurslid from "../../data/bestuursleden/useFetchBestuurslid";
import BestuursledenBezittingItem from "../../components/bestuursleden/BestuursledenBezittingItem";

function BestuursledenDetailPage() {
    const {discordId} = useParams()
    const {isSuccess, data: bestuurslid} = useFetchBestuurslid(discordId)
    if (!isSuccess) {
        return (<TabContainer><Loading/></TabContainer>)
    }

    return (
        <TabContainer>
            <h4>{bestuurslid.voornaam} {bestuurslid.familienaam}</h4>
            <div className={"flex flex-row grid md:grid-cols-3 md:gap-6"}>
                <div className={"flex flex-col"}>
                    <h5 className={"mt-3 mb-2"}>E-mail</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{bestuurslid.email}</p>
                    <h5 className={"mt-3 mb-2"}>Discord ID</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{bestuurslid.discordId}</p>
                    {bestuurslid.priveEmail && (
                        <Fragment>
                            <h5 className={"mt-3 mb-2"}>Privé e-mail</h5>
                            <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{bestuurslid.priveEmail}</p>
                        </Fragment>
                    )}
                    {bestuurslid.priveTel && (
                        <Fragment>
                            <h5 className={"mt-3 mb-2"}>Privé telefoonnummer</h5>
                            <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{bestuurslid.priveTel}</p>
                        </Fragment>
                    )}
                    <div className={"flex flex-col"}>
                        <h5 className={"mt-3 mb-2"}>Bezittingen</h5>
                        {bestuurslid.bezittingen?.length > 0 ? (
                            <div className={"ml-4 flex"}>
                                {bestuurslid.bezittingen.map(b => (<BestuursledenBezittingItem key={b.bezitting.id} bezitting={b.bezitting}/>))}
                            </div>
                        ) : (
                            <p className={"ml-2 text-gray-800 dark:text-gray-300"}>Geen bezittingen gevonden.</p>
                        )}
                    </div>
                </div>
                <div className={"flex flex-col"}>
                    <h5 className={"mt-3 mb-2"}>Afdeling</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{bestuurslid.afdeling.naam}</p>
                    <h5 className={"mt-3 mb-2"}>Functie</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{bestuurslid.functie}</p>
                    <h5 className={"mt-3 mb-2"}>Startdatum</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{moment(bestuurslid.startdatum).format("DD/MM/YYYY")} ({moment().diff(bestuurslid.startdatum, 'months', false)} maanden)</p>
                    {bestuurslid.geboortedatum && (
                        <Fragment>
                            <h5 className={"mt-3 mb-2"}>Geboortedatum</h5>
                            <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{moment(bestuurslid.geboortedatum).format("DD/MM/YYYY")} ({moment().diff(bestuurslid.geboortedatum, 'years', false)} jaar)</p>
                        </Fragment>
                    )}
                </div>
                <div className={"flex flex-col"}>
                    <h5 className={"mt-3 mb-2"}>Foto</h5>
                    <div className={"rounded-full max-h-80 max-w-80 overflow-hidden"}>
                        <img src={bestuurslid.afbeelding ? bestuurslid.afbeelding : "https://s3.eu-west-1.amazonaws.com/odt.website/bestuursleden/768893910487138335.png"}
                             alt={bestuurslid.voornaam}/>
                    </div>
                </div>
            </div>
            <div className={"mb-3 mt-5"}>
                <BackButton to={"/bestuursleden"}/>
            </div>
        </TabContainer>
    );
}

export default BestuursledenDetailPage;
