import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import TabContainer from "../../../components/global/TabContainer";
import TapkalenderForm from "../../../components/tappen/tapkalender/TapkalenderForm";
import moment from "moment";
import useUpsertTapweek from "../../../data/tappen/tapkalender/useUpsertTapweek";

function TapkalenderAddPage() {
    const [tapweek, setTapweek] = useState({
        bestuurslidId: "",
        weeknummer: moment().isoWeek(),
        jaar: moment().year(),
        opmerking: "",
    })
    const navigate = useNavigate()
    const {mutate: bekertypeToevoegen, isLoading, isSuccess: isAdded} = useUpsertTapweek()

    useEffect(() => {
        if (isAdded) navigate("/tappen/tapkalender")
    }, [isAdded, navigate])

    return (
        <TabContainer>
            <h4>Tapweek toevoegen</h4>
            <TapkalenderForm
                tapweek={tapweek}
                setTapweek={setTapweek}
                buttonLabel={"Toevoegen"}
                action={bekertypeToevoegen}
                isLoading={isLoading}
            />
        </TabContainer>
    );
}

export default TapkalenderAddPage;
