import React from 'react';

function Checkbox({checked, onChange, id}) {
    return (
        <div className="flex items-center">
            <input
                type="checkbox"
                id={id}
                checked={checked ? checked : false}
                onChange={onChange}
                className="w-4 h-4 text-yellow-600 accent-amber-300 bg-gray-100 rounded border-gray-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
        </div>
    );
}

export default Checkbox;
