import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import TabContainer from "../../components/global/TabContainer";
import BestuurslidForm from "../../components/bestuursleden/BestuurslidForm";
import useUpsertBestuurslid from "../../data/bestuursleden/useUpsertBestuurslid";

function BestuursledenAddPage() {
    const [bestuurslid, setBestuurslid] = useState({
        discordId: "",
        voornaam: "",
        familienaam: "",
        email: "",
        functie: "",
        afbeelding: undefined,
        afbeeldingType: "",
        afdelingId: "",
        priveTel: "",
        priveEmail: "",
        geboortedatum: ""
    })
    const navigate = useNavigate()
    const {mutate: createBestuurslid, isLoading, isSuccess: isAdded} = useUpsertBestuurslid()

    useEffect(() => {
        if (isAdded) navigate("/bestuursleden")
    }, [isAdded, navigate])

    return (
        <TabContainer>
            <h4>Bestuurslid toevoegen</h4>
            <BestuurslidForm
                bestuurslid={bestuurslid}
                setBestuurslid={setBestuurslid}
                buttonLabel={"Toevoegen"}
                action={createBestuurslid}
                update={false}
                isLoading={isLoading}
            />
        </TabContainer>
    );
}

export default BestuursledenAddPage;
