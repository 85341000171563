import {useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const fetchDrankVdMaand = (jaartal, maand) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/drankenvdmaand/${jaartal}/${maand}`, {headers: authHeader()})
}

function useFetchDrankVdMaand(jaartal, maand) {
    const {setAlert} = useAlerts()
    return useQuery(['fetchDrankVdMaand', jaartal, maand], () => fetchDrankVdMaand(jaartal, maand), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: (data => data.data)
    })
}

export default useFetchDrankVdMaand
