import React from 'react';
import ListContainer from "../../../components/global/ListContainer";
import BekertypeListItem from "../../../components/bekerverhuur/bekertypes/BekertypeListItem";
import useFetchBekertypes from "../../../data/bekerverhuur/bekertypes/useFetchBekertypes";
import useCheckPermissies from "../../../hooks/useCheckPermissies";
import BekertypesListLoading from "../../../components/bekerverhuur/bekertypes/BekertypesListLoading";

function BekertypeListPage() {
    return (
        <ListContainer
            type={"Bekertypes"}
            dataFunction={useFetchBekertypes}
            addPath={"toevoegen"}
            addPermissie={useCheckPermissies("createBekertype")}
            listItemLambda={bekertype => (
                <BekertypeListItem key={bekertype.naam} bekertype={bekertype}/>
            )}
            placeholderLambda={index => (
                <BekertypesListLoading key={index}/>
            )}
        />
    );
}

export default BekertypeListPage;
