import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const deleteDrankVdMaand = (jaartal, maand) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/drankenvdmaand/${jaartal}/${maand}`, {headers: authHeader()})
}

function useDeleteDrankVdMaand(jaartal, maand) {
    const {setAlert} = useAlerts()
    const queryClient = useQueryClient()
    return useMutation(['deleteDrankVdMaand', jaartal, maand], () => deleteDrankVdMaand(jaartal, maand), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        onSuccess: (data) => {
            setAlert(data?.data?.message, "SUCCESS")
            queryClient.invalidateQueries('fetchDrankenVdMaand')
        },
        select: (data => data.data)
    })
}

export default useDeleteDrankVdMaand
