import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import authHeader from "../../util/auth-header";
import {useAlerts} from "../../components/global/alerts/AlertContext";

const deleteEvenement = (id) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/evenementen/${id}`, {headers: authHeader()})
}

function useDeleteEvenement(id) {
    const {setAlert} = useAlerts()
    const queryClient = useQueryClient()
    return useMutation(['deleteEvenement', id], () => deleteEvenement(id), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        onSuccess: (data) => {
            setAlert(data?.data?.message, "SUCCESS")
            queryClient.invalidateQueries('fetchEvenementen')
        },
        select: (data => data.data)
    })
}

export default useDeleteEvenement
