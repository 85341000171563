import React, {useState} from 'react';
import Row from "../global/listitems/Row";
import Cell from "../global/listitems/Cell";
import BoldTextLoading from "../global/listitems/loading/BoldTextLoading";
import BoxTextLoading from "../global/listitems/loading/BoxTextLoading";
import DetailsButtonLoading from "../global/listitems/loading/DetailsButtonLoading";

function UpcomingEvenementenListLoading() {
    const heights = ["h-24", "h-36", "h-48"]
    const widths = ["w-24", "w-36", "w-48"]
    const [width] = useState(widths[Math.floor(Math.random() * (widths.length - 1))])
    const [height] = useState(heights[Math.floor(Math.random() * (heights.length - 1))])

    return (
        <Row loading={true}>
            <Cell className={"hidden md:block"}>
                <div
                    className={`my-2 rounded-md ${width} ${height} object-contain rounded-md mx-auto bg-gray-100 dark:bg-gray-500`}
                />
            </Cell>
            <Cell>
                <BoldTextLoading/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-[100px]"}/>
            </Cell>
            <Cell>
                <DetailsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default UpcomingEvenementenListLoading;
