import React, {Fragment, useState} from 'react';
import {motion} from "framer-motion";
import SidebarLogo from "./SidebarLogo";
import SidebarProfile from "./SidebarProfile";
import SidebarLinks from "./SidebarLinks";
import SidebarHeaderMobile from "./SidebarHeaderMobile";

function Sidebar() {
    const isMobile = window.innerWidth < 768;
    const sideBarVariants = isMobile ? {
        "shown": {
            display: "block",
            y: ["-100%", "0%"],
        },
        "hidden": {
            y: "-100%",
            transitionEnd: {display: "none"}
        }
    } : {
        "shown": {
            display: "block",
            x: ["-100%", "0%"],
            opacity: [0, 1]
        },
        "hidden": {
            x: "-100%", opacity: 0,
            transitionEnd: {display: "none"}
        }
    }

    const showMenuVariants = isMobile ? {
        shown: {
            display: "flex",
            y: "0%"
        },
        hidden: {
            y: "-100%",
            transitionEnd: {display: "none"}
        }
    } : {}

    const [showMenu, setShowMenu] = useState(false)

    const toggleMenu = () => {
        setShowMenu(old => !old)
    }



    return (
        <Fragment>
            <motion.div
                className="w-full md:w-64 absolute sm:relative md:bg-white md:shadow h-max md:h-max flex-col justify-between flex md:dark:bg-gray-800 sm:bg-transparent"
                transition={{type: "spring", duration: .5}}
                variants={sideBarVariants} // Desktop
                animate={"shown"}
                exit={"hidden"}
            >
                <SidebarHeaderMobile
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    toggleMenu={toggleMenu}
                />
                <motion.div className={"bg-white dark:bg-gray-800 h-max md:h-screen flex flex-col justify-between"}
                            variants={showMenuVariants} // Mobile
                            initial={"hidden"}
                            transition={{duration: .2}}
                            animate={showMenu ? "shown" : "hidden"}
                            close={"hidden"}
                >
                    <div className="px-8">
                        <SidebarLogo/>
                        <SidebarLinks hideMenu={() => setShowMenu(false)}/>
                    </div>
                    <SidebarProfile/>
                </motion.div>
            </motion.div>
        </Fragment>
    );
}


export default Sidebar;
