import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import TabContainer from "../../../components/global/TabContainer";
import useUpsertConsumptie from "../../../data/dranken/assortiment/useUpsertConsumptie";
import AssortimentForm from "../../../components/dranken/assortiment/AssortimentForm";
import useFetchConsumptie from "../../../data/dranken/assortiment/useFetchConsumptie";

function AssortimentEditPage() {
    const {naam: oldNaam} = useParams();
    const [consumptie, setConsumptie] = useState({oldNaam, naam: "", verkoopprijs: 0, categorieId: ""})
    const {isSuccess, data} = useFetchConsumptie(oldNaam)
    const {mutate: updateConsumptie, isLoading: isUpdating, isSuccess: isUpdated} = useUpsertConsumptie()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            console.log(data)
            setConsumptie({...data, oldNaam: oldNaam})
        }
    }, [isSuccess, data, oldNaam])

    useEffect(() => {
        if (isUpdated) navigate("/dranken/assortiment")
    }, [isUpdated, navigate])

    return (
        <TabContainer>
            <h4>Consumptie wijzigen</h4>
            <AssortimentForm
                buttonLabel={"Wijzigen"}
                consumptie={consumptie}
                setConsumptie={setConsumptie}
                action={updateConsumptie}
                isLoading={isUpdating}
            />
        </TabContainer>
    );
}

export default AssortimentEditPage;
