import React from 'react';
import ListContainer from "../../components/global/ListContainer";
import EvenementListItem from "../../components/evenementen/EvenementListItem";
import useFetchEvenementen from "../../data/evenementen/useFetchEvenementen";
import useCheckPermissies from "../../hooks/useCheckPermissies";
import EvenementenListLoading from "../../components/evenementen/EvenementenListLoading";

function EvenementenListPage() {
    return (
        <ListContainer
            type={"Evenementen"}
            addPath={"/evenementen/toevoegen"}
            addPermissie={useCheckPermissies("createEvenement")}
            dataFunction={useFetchEvenementen}
            useFilter={true}
            listItemLambda={evenement => (
                <EvenementListItem key={evenement.id} evenement={evenement}/>
            )}
            placeholderLambda={index => (
                <EvenementenListLoading key={index}/>
            )}
        />
    );
}

export default EvenementenListPage;
