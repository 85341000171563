import {useMutation} from "react-query";
import axios from "axios";
import authHeader from "../../util/auth-header";
import {useAlerts} from "../../components/global/alerts/AlertContext";

const resetBestuurslidWachtwoord = (discordId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/bestuursleden/${discordId}/reset`, {headers: authHeader()})
}

function useResetBestuurslidWachtwoord(discordId) {
    const {setAlert} = useAlerts()
    return useMutation('resetBestuurslidWachtwoord', () => resetBestuurslidWachtwoord(discordId), {
        onSuccess: (data) => {
            setAlert(data?.data?.message, "SUCCESS")
        },
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        }
    })
}

export default useResetBestuurslidWachtwoord
