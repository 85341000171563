import React from 'react';
import HeaderRow from "../../../global/listitems/HeaderRow";
import Cell from "../../../global/listitems/Cell";
import BoldText from "../../../global/listitems/BoldText";

function BestellingDetailHeader() {
    return (
        <HeaderRow>
            <Cell>
                <BoldText text={"Consumptie"}/>
            </Cell>
            <Cell>
                <BoldText text={"Type"}/>
            </Cell>
            <Cell>
                <BoldText text={"Aantal"}/>
            </Cell>
        </HeaderRow>
    );
}

export default BestellingDetailHeader;
