import React from 'react';
import Row from "../../global/listitems/Row";
import Cell from "../../global/listitems/Cell";
import RoundImageLoading from "../../global/listitems/loading/RoundImageLoading";
import NormalTextLoading from "../../global/listitems/loading/NormalTextLoading";
import BoxTextLoading from "../../global/listitems/loading/BoxTextLoading";
import OptionsButtonLoading from "../../global/listitems/loading/OptionsButtonLoading";

function TapkalenderListLoading() {
    return (
        <Row loading={true}>
            <Cell>
                <RoundImageLoading/>
            </Cell>
            <Cell>
                <NormalTextLoading/>
            </Cell>
            <Cell>
                <NormalTextLoading width={"w-[170px]"}/>
            </Cell>
            <Cell>
                <BoxTextLoading className={"w-20"}/>
            </Cell>
            <Cell>
                <NormalTextLoading/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default TapkalenderListLoading;
