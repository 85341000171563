import {useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const fetchBekertypes = (pagina) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/bekertypes?limit=10&pagina=${pagina}`, {headers: authHeader()})
}

function useFetchBekertypes(pagina = 1) {
    const {setAlert} = useAlerts()
    return useQuery(['fetchBekertypes', pagina], () => fetchBekertypes(pagina), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: (data => data.data)
    })
}

export default useFetchBekertypes
