import React from 'react';
import TabContainer from "./TabContainer";
import Pagination from "./Pagination";
import {Link} from "react-router-dom";
import SearchField from "./SearchField";
import {NumberParam, StringParam, useQueryParams, withDefault} from 'use-query-params';

function ListContainer({
                           type,
                           dataFunction,
                           listItemLambda,
                           placeholderLambda,
                           addPath,
                           useFilter = false,
                           addPermissie = true,
                           amountPerPage = 10,
                           header = null
                       }) {
    const searchParam = withDefault(StringParam, "");
    const pageParam = withDefault(NumberParam, 1);
    const [query, setQuery] = useQueryParams({filter: searchParam, pagina: pageParam})
    const {isLoading, isSuccess, isError, data} = dataFunction(query.pagina, query.filter);

    const changeFilter = (f) => {
        setQuery({filter: f, pagina: 1})
    }

    const changePagina = (p) => {
        setQuery({pagina: p})
    }

    return (
        <TabContainer>
            <div className={"flex flex-col md:flex-row justify-between mb-3"}>
                <h4 className={"align-text-bottom"}>Overzicht {type}</h4>
                <div className={"flex flex-col md:flex-row mt-4 md:mt-0"}>
                    {useFilter && (
                        <SearchField filter={query.filter} setFilter={changeFilter} isError={isError}/>
                    )}
                    {addPath && addPermissie && (
                        <Link
                            to={addPath}
                            className="md:ml-2 cursor-pointer duration-300 text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-2 py-2 text-center mb-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                        >Toevoegen</Link>
                    )}
                </div>
            </div>
            {!isError && (
                <div className="overflow-y-auto overflow-x-auto xl:overflow-visible">
                    <table className="w-full whitespace-nowrap">
                        <tbody>
                        {isLoading && [...Array(amountPerPage).keys()].map(placeholderLambda)}
                        {isSuccess && data.data.length === 0 && (
                            <tr>
                                <td>
                                    <p className={"text-gray-800 dark:text-gray-300"}>Geen resultaten gevonden.</p>
                                </td>
                            </tr>
                        )}
                        {isSuccess && header}
                        {isSuccess && data.data.map(listItemLambda)}
                        </tbody>
                    </table>
                </div>
            )}
            <Pagination
                isLoading={isLoading}
                isSuccess={isSuccess}
                curr={data?.pagina?.curr}
                total={data?.pagina?.total}
                amountPerPage={amountPerPage}
                next={data?.pagina?.next}
                prev={data?.pagina?.prev}
                setPagina={changePagina}
            />
        </TabContainer>
    );
}

export default ListContainer;
