import React, {Fragment} from 'react';
import TabContainer from "../../components/global/TabContainer";
import useFetchEvenement from "../../data/evenementen/useFetchEvenement";
import {useParams} from "react-router-dom";
import Loading from "../../components/global/Loading";
import moment from "moment";
import BackButton from "../../components/global/forms/BackButton";
import Button from "../../components/global/Button";

function EvenementDetailPage() {
    const {id} = useParams()
    const {isSuccess, data: evenement} = useFetchEvenement(id)
    if (!isSuccess) {
        return (<TabContainer><Loading/></TabContainer>)
    }
    return (
        <TabContainer>
            <h4>{evenement.naam}</h4>
            <div className={"flex flex-row grid md:grid-cols-2 md:gap-6"}>
                <div className={"col-span-1"}>
                    <h5 className={"mt-3 mb-2"}>Datum</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{moment(evenement.datum).format("DD/MM/YYYY")}</p>
                    <h5 className={"mt-3 mb-2"}>Verantwoordelijke</h5>
                    <div className={"ml-2 flex flex-row items-center"}>
                        <div className={"rounded-full overflow-hidden w-8 h-8"}>
                            <img
                                src={evenement.verantwoordelijke ? evenement.verantwoordelijke.afbeelding : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}
                                alt={evenement.verantwoordelijke?.naam}
                            />
                        </div>
                        <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{evenement.verantwoordelijke ? `${evenement.verantwoordelijke.voornaam} ${evenement.verantwoordelijke.familienaam}` : "Geen verantwoordelijke aangesteld"}</p>
                    </div>
                    <h5 className={"mt-3 mb-2"}>Categorie</h5>
                    <p className={"ml-2 text-gray-800 dark:text-gray-300"}>{evenement.categorie.naam}</p>
                    {(evenement.facebookURL || evenement.driveURL) && (
                        <Fragment>
                            <h5 className={"mt-3 mb-2"}>Links</h5>
                            <div className={"flex flex-row"}>
                                {evenement.facebookURL && (
                                    <Button type={"Facebook"}
                                            label={"Facebook"}
                                            onClick={() => window.open(evenement.facebookURL)}
                                    />
                                )}
                                {evenement.driveURL && (
                                    <Button type={"Google Drive"}
                                            label={"Google Drive"}
                                            onClick={() => window.open(evenement.driveURL)}
                                    />
                                )}
                            </div>
                        </Fragment>
                    )
                    }
                </div>
                <div className={"col-span-1"}>
                    <h5 className={"mt-3 mb-2"}>Affiche</h5>
                    <div>
                        <img src={evenement.afbeelding} className={"rounded-md w-full md:w-1/2"} alt={evenement.naam}/>
                    </div>
                </div>
            </div>
            <div className={"mb-3 mt-5"}>
                <BackButton to={"/evenementen"}/>
            </div>
        </TabContainer>
    );
}

export default EvenementDetailPage;
