import React from 'react';
import TabContainer from "../components/global/TabContainer";
import TabContainerAnimation from "../components/global/TabContainerAnimation";
import Button from "../components/global/Button";
import {useNavigate} from "react-router-dom";

function ErrorPage({errormsg = "Error 404: Pagina niet gevonden.", header = true}) {
    const navigate = useNavigate()

    if (header) {
        return (
            <TabContainerAnimation>
                <h3 className="mb-8">Oeps!</h3>
                <TabContainer>
                    <h4>De opgevraagde pagina kon niet worden geladen...</h4>
                    <div>
                        {errormsg && (<p className={"text-gray-800 dark:text-gray-300 mt-8"}>{errormsg}</p>)}
                        <Button
                            label={"Terug naar dashboard"}
                            className={"mt-4"}
                            onClick={() => navigate("/dashboard")}
                        />
                    </div>
                </TabContainer>
            </TabContainerAnimation>
        );
    }

    return (
        <TabContainer>
            <h4>De opgevraagde pagina kon niet worden gevonden...</h4>
            <div>
                {errormsg && (<p className={"text-gray-800 dark:text-gray-300 mt-8"}>{errormsg}</p>)}
                <Button
                    label={"Terug naar dashboard"}
                    className={"mt-4"}
                    onClick={() => navigate("/dashboard")}
                />
            </div>
        </TabContainer>
    )
}

export default ErrorPage;
