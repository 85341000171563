import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import TabContainer from "../../../components/global/TabContainer";
import TapPrestatieForm from "../../../components/tappen/tapprestaties/TapPrestatieForm";
import useAddTapPrestatie from "../../../data/tappen/tapprestaties/useAddTapPrestatie";
import moment from "moment";

function TapPrestatiesAddPage() {
    const [tapprestatie, setTapprestatie] = useState({
        tappers: [],
        geldmeeBestuurslidId: null,
        datum: moment().format("YYYY-MM-DD"),
        inkomsten: 0,
        opmerking: ""
    })
    const navigate = useNavigate()
    const {mutate: addTapprestatie, isLoading, isSuccess: isAdded} = useAddTapPrestatie()

    useEffect(() => {
        if (isAdded) navigate("/tappen/tapprestaties")
    }, [isAdded, navigate])

    return (
        <TabContainer>
            <h4>Tapprestatie toevoegen</h4>
            <TapPrestatieForm
                tapprestatie={tapprestatie}
                setTapprestatie={setTapprestatie}
                buttonLabel={"Toevoegen"}
                action={addTapprestatie}
                isLoading={isLoading}
            />
        </TabContainer>
    );
}

export default TapPrestatiesAddPage;
