import React from 'react';

function RoundImage({source, alt, profilepic = false}) {
    return (
        <div className={"overflow-hidden w-8 h-8 rounded-full"}>
            <img
                className={profilepic ? "" : "w-full h-full object-cover"}
                src={source ? source : "https://s3.eu-west-1.amazonaws.com/odt.website/bestuursleden/768893910487138335.png"}
                alt={alt}
            />
        </div>
    );
}

export default RoundImage;
