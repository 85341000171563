import Modal from "../../../global/Modal";
import {Fragment} from "react";
import SelectField from "../../../global/forms/SelectField";
import InputField from "../../../global/forms/InputField";
import useFetchEenheidstypes from "../../../../data/dranken/assortiment/useFetchEenheidstypes";
import {capitalizeFirstLetter} from "../../../../util/formatter";
import {validateAantal, validateConsumptieId, validatePrijs} from "../../../../util/validator";
import {useAlerts} from "../../../global/alerts/AlertContext";

export default function EenheidModal({shown, setShown, action, setEenheid, eenheid, title, update = false}) {
    const {data: types} = useFetchEenheidstypes()
    const {setAlert} = useAlerts()

    const onChange = (e) => {
        setEenheid(old => ({...old, [e.target.name]: e.target.value}))
    }

    const handleSubmit = () => {
        if (controlData()) {
            action(eenheid)
            setShown(false)
        }
    }

    const controlData = () => {
        const consumptieId = validateConsumptieId(eenheid.consumptieId)
        if (!consumptieId) {
            return setAlert("Ongeldige consumptie.", "ERROR")
        }
        const typeNaam = eenheid.typeNaam
        if (!typeNaam) {
            return setAlert("Ongeldige typenaam.", "ERROR")
        }
        const aankoopprijs = validatePrijs(eenheid.aankoopprijs)
        if (!aankoopprijs) {
            return setAlert("Ongeldige aankoopprijs.", "ERROR")
        }
        const standaardInStock = validateAantal(eenheid.standaardInStock, true)
        if (!standaardInStock && standaardInStock !== 0) {
            return setAlert("Ongeldige standaardstock.", "ERROR")
        }
        const standaardInKoeling = validateAantal(eenheid.standaardInKoeling, true)
        if (!standaardInKoeling && standaardInKoeling !== 0) {
            return setAlert("Ongeldig aantal in koeling.", "ERROR")
        }
        return true
    }

    return (
        <Modal
            buttons={(
                <Fragment>
                    <button
                        type="button"
                        className="duration-300 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-500 text-base font-medium text-white hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {capitalizeFirstLetter(title)}
                    </button>
                    <button
                        type="button"
                        className="duration-300 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-800 dark:text-gray-300 dark:border-gray-700 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setShown(false)}
                    >
                        Annuleer
                    </button>
                </Fragment>
            )}
            body={(
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full sm:mr-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Eenheid {title}
                        </h3>
                        <div className="mt-2">
                            {!update && (
                                <SelectField
                                    label={"Type"}
                                    value={eenheid.typeNaam}
                                    name={"typeNaam"}
                                    onChange={onChange}
                                    options={types ? types : []}
                                    optionMapper={(opt) => (
                                        <option key={opt.naam} value={opt.naam}>{opt.naam}</option>)}
                                />
                            )}
                            <InputField
                                label={"Aankoopprijs"}
                                type={"number"}
                                name={"aankoopprijs"}
                                value={eenheid.aankoopprijs}
                                onChange={onChange}
                                placeholder={"Aankoopprijs..."}
                            />
                            <InputField
                                label={"Standaard aantal consumpties in stock"}
                                name={"standaardInStock"}
                                value={eenheid.standaardInStock}
                                onChange={onChange}
                                type={"number"}
                                placeholder={"Standaard in stock..."}
                            />
                            <InputField
                                value={eenheid.standaardInKoeling}
                                name={"standaardInKoeling"}
                                onChange={onChange}
                                label={"Standaard aantal consumpties in koeling"}
                                type={"number"}
                                placeholder={"Standaard in koeling..."}
                            />
                        </div>
                    </div>
                </div>
            )}
            setShown={setShown}
            shown={shown}
        />
    )
}
