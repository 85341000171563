import React from 'react';
import Row from "../../global/listitems/Row";
import Cell from "../../global/listitems/Cell";
import BoldText from "../../global/listitems/BoldText";
import BoxText from "../../global/listitems/BoxText";
import RoundText from "../../global/listitems/RoundText";
import OptionsButton from "../../global/listitems/OptionsButton";
import NormalText from "../../global/listitems/NormalText";
import Tooltip from "../../global/listitems/Tooltip";
import useChangeZichtbaarheidConsumptie from "../../../data/dranken/assortiment/useChangeZichtbaarheidConsumptie";
import {useNavigate} from "react-router-dom";
import useDeleteConsumptie from "../../../data/dranken/assortiment/useDeleteConsumptie";
import useCheckPermissies from "../../../hooks/useCheckPermissies";
import DetailsButton from "../../global/listitems/DetailsButton";
import useChangeBeschikbaarheidConsumptie from "../../../data/dranken/assortiment/useChangeBeschikbaarheidConsumptie";

function AssortimentListItem({consumptie}) {
    const navigate = useNavigate()
    const {mutate: changeZichtbaarheid} = useChangeZichtbaarheidConsumptie()
    const {mutate: changeBeschikbaarheid} = useChangeBeschikbaarheidConsumptie()
    const {mutate: verwijderConsumptie} = useDeleteConsumptie(consumptie.id)

    const options = [
        {
            action: () => navigate(`/dranken/assortiment/${consumptie.naam}/wijzigen`),
            label: "Wijzig",
            condition: useCheckPermissies("upsertConsumptie")
        },
        {
            action: () => changeZichtbaarheid({naam: consumptie.naam, zichtbaar: !consumptie.zichtbaar}),
            label: consumptie.zichtbaar ? "Maak onzichtbaar" : "Maak zichtbaar",
            condition: useCheckPermissies("upsertConsumptie")
        },
        {
            action: () => changeBeschikbaarheid({naam: consumptie.naam, beschikbaar: !consumptie.beschikbaar}),
            label: consumptie.beschikbaar ? "Maak niet beschikbaar" : "Maak beschikbaar",
            condition: useCheckPermissies("stocktelling")
        },
        {
            action: () => verwijderConsumptie(),
            label: "Verwijder uit stock",
            condition: useCheckPermissies("verwijderConsumptie")
        }
    ]

    return (
        <Row>
            <Cell>
                <BoldText text={consumptie.naam}/>
            </Cell>
            <Cell>
                <BoxText text={consumptie.categorie.naam} width={"w-36"}/>
            </Cell>
            <Cell>
                <NormalText text={`€ ${consumptie.verkoopprijs.toFixed(2).replace(".", ",")}`}/>
            </Cell>
            <Cell>
                <Tooltip tooltipText={consumptie.beschikbaar ? "Beschikbaar" : "Niet beschikbaar"}>
                    <RoundText text={"B"} color={consumptie.beschikbaar ? "green" : "red"}/>
                </Tooltip>
                <Tooltip tooltipText={consumptie.zichtbaar ? "Zichtbaar" : "Onzichtbaar"}>
                    <RoundText text={"Z"} color={consumptie.zichtbaar ? "green" : "red"}/>
                </Tooltip>
            </Cell>
            <Cell>
                <DetailsButton to={`/dranken/assortiment/${consumptie.naam}`}/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButton options={options}/>
            </Cell>
        </Row>
    );
}

export default AssortimentListItem;
