import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import TabContainer from "../../../components/global/TabContainer";
import useFetchTapkalenderWeek from "../../../data/tappen/tapkalender/useFetchTapkalenderWeek";
import TapkalenderForm from "../../../components/tappen/tapkalender/TapkalenderForm";
import useUpsertTapweek from "../../../data/tappen/tapkalender/useUpsertTapweek";
import moment from "moment";

function TapkalenderEditPage() {
    const {jaar, weeknummer} = useParams();
    const [tapweek, setTapweek] = useState({
        bestuurslidId: "",
        weeknummer: moment().isoWeek(),
        jaar: moment().year(),
        opmerking: "",
    })
    const {isSuccess, data} = useFetchTapkalenderWeek(jaar, weeknummer)
    const {mutate: upsertTapweek, isLoading: isUpdating, isSuccess: isUpdated} = useUpsertTapweek()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            setTapweek(data)
        }
    }, [isSuccess, data])

    useEffect(() => {
        if (isUpdated) navigate("/tappen/tapkalender")
    }, [isUpdated, navigate])

    return (
        <TabContainer>
            <h4>Tapweek wijzigen</h4>
            <TapkalenderForm
                buttonLabel={"Wijzigen"}
                tapweek={tapweek}
                setTapweek={setTapweek}
                action={upsertTapweek}
                isLoading={isUpdating}
            />
        </TabContainer>
    );
}

export default TapkalenderEditPage;
