import React from 'react';
import ListContainer from "../../components/global/ListContainer";
import useFetchBestuursleden from "../../data/bestuursleden/useFetchBestuursleden";
import BestuursledenListItem from "../../components/bestuursleden/BestuursledenListItem";
import useCheckPermissies from "../../hooks/useCheckPermissies";
import BestuursledenListLoading from "../../components/bestuursleden/BestuursledenListLoading";

function BestuursledenListPage() {
    return (
        <ListContainer
            type={"Bestuursleden"}
            addPath={"/bestuursleden/toevoegen"}
            addPermissie={useCheckPermissies("upsertBestuurslid")}
            dataFunction={useFetchBestuursleden}
            useFilter={true}
            listItemLambda={bestuurslid => (
                <BestuursledenListItem key={bestuurslid.discordId} bestuurslid={bestuurslid}/>
            )}
            placeholderLambda={index => (
                <BestuursledenListLoading key={index}/>
            )}
        />
    );
}

export default BestuursledenListPage;
