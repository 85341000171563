import React from 'react';
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {motion} from "framer-motion";

function Modal({shown, setShown, body, buttons}) {
    const modalRef = useOnClickOutside(() => setShown(false))
    const variants = {
        "shown": {
            opacity: 1,
            display: "initial"
        },
        "hidden": {
            opacity: 0,
            transitionEnd: {display: "none"}
        }
    }

    const modalVariants = {
        "shown": {
            opacity: 1,
            y: 0,
            transition: {duration: .5}
        },
        "hidden": {
            opacity: 0,
            y: "-100%",
            transition: {duration: .5}
        }
    }

    return (
        <motion.div variants={variants}
                    initial={"hidden"}
                    animate={shown ? "shown" : "hidden"}
                    close={"hidden"}
                    className="fixed z-10 inset-0 overflow-y-auto"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm"/>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true">&#8203;</span>
                <motion.div
                    variants={modalVariants}
                    initial={"hidden"}
                    animate={shown ? "shown" : "hidden"}
                    close={"hidden"}
                    ref={modalRef}
                    className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        {body}
                    </div>
                    <div className="dark:bg-gray-900 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        {buttons}
                    </div>
                </motion.div>
            </div>
        </motion.div>
    )
}

export default Modal;
