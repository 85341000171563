import React from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import PrivateRoute from "../../components/login/PrivateRoute";
import BekerReservatiesListPage from "./reservaties/BekerReservatiesListPage";
import BekerReservatiesDetailPage from "./reservaties/BekerReservatieDetailPage";
import DozenListPage from "./dozen/DozenListPage";
import BekertypeListPage from "./bekertypes/BekertypeListPage";
import {AnimatePresence} from "framer-motion";
import TabContainerAnimation from "../../components/global/TabContainerAnimation";
import BekertypeAddPage from "./bekertypes/BekertypeAddPage";
import DoosAddPage from "./dozen/DoosAddPage";
import BekertypeEditPage from "./bekertypes/BekertypeEditPage";
import DoosEditPage from "./dozen/DoosEditPage";
import BekerReservatieAddPage from "./reservaties/BekerReservatieAddPage";

function BekerverhuurStack({location}) {
    return (
        <div>
            <TabContainerAnimation>
                <h3 className="mb-8">Bekerverhuur</h3>
                <AnimatePresence exitBeforeEnter>
                    <Routes location={location} key={location.pathname}>
                        <Route
                            path={"/"}
                            element={<Navigate to={"reservaties"}/>}
                        />
                        <Route
                            path={"reservaties"}
                            element={<PrivateRoute><BekerReservatiesListPage/></PrivateRoute>}
                        />
                        <Route
                            path={"reservaties/toevoegen"}
                            element={<PrivateRoute permissie={"createReservatie"}><BekerReservatieAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"reservaties/:id"}
                            element={<PrivateRoute><BekerReservatiesDetailPage/></PrivateRoute>}
                        />
                        <Route
                            path={"dozen"}
                            element={<PrivateRoute><DozenListPage/></PrivateRoute>}
                        />
                        <Route
                            path={"dozen/toevoegen"}
                            element={<PrivateRoute permissie={"createDoos"}><DoosAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"dozen/wijzigen/:naam"}
                            element={<PrivateRoute permissie={"wijzigDoos"}><DoosEditPage/></PrivateRoute>}
                        />
                        <Route
                            path={"bekertypes"}
                            element={<PrivateRoute><BekertypeListPage/></PrivateRoute>}
                        />
                        <Route
                            path={"bekertypes/toevoegen"}
                            element={<PrivateRoute permissie={"createBekertype"}><BekertypeAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"bekertypes/wijzigen/:naam"}
                            element={<PrivateRoute permissie={"wijzigBekertype"}><BekertypeEditPage/></PrivateRoute>}
                        />
                    </Routes>
                </AnimatePresence>
            </TabContainerAnimation>
        </div>
    );
}

export default BekerverhuurStack;
