import React from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import TabContainerAnimation from "../../components/global/TabContainerAnimation";
import TapkalenderListPage from "./tapkalender/TapkalenderListPage";
import TapkalenderAddPage from "./tapkalender/TapkalenderAddPage";
import TapkalenderEditPage from "./tapkalender/TapkalenderEditPage";
import PrivateRoute from "../../components/login/PrivateRoute";
import TapPrestatiesListPage from "./tapprestaties/TapPrestatiesListPage";
import TapPrestatiesAddPage from "./tapprestaties/TapPrestatiesAddPage";
import TapPrestatiesEditPage from "./tapprestaties/TapPrestatiesEditPage";

function TappenStack({location}) {
    return (
        <div>
            <TabContainerAnimation>
                <h3 className="mb-8">Tappen</h3>
                <AnimatePresence exitBeforeEnter>
                    <Routes location={location} key={location.pathname}>
                        <Route
                            path={"/"}
                            element={<Navigate to={"tapkalender"}/>}
                        />
                        <Route
                            path={"tapkalender"}
                            element={<PrivateRoute><TapkalenderListPage/></PrivateRoute>}
                        />
                        <Route
                            path={"tapkalender/toevoegen"}
                            element={<PrivateRoute permissie={"upsertTapweek"}><TapkalenderAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"tapkalender/wijzigen/:jaar/:weeknummer"}
                            element={<PrivateRoute permissie={"upsertTapweek"}><TapkalenderEditPage/></PrivateRoute>}
                        />
                        <Route
                            path={"tapprestaties"}
                            element={<PrivateRoute><TapPrestatiesListPage/></PrivateRoute>}
                        />
                        <Route
                            path={"tapprestaties/toevoegen"}
                            element={<PrivateRoute><TapPrestatiesAddPage/></PrivateRoute>}
                        />
                        <Route
                            path={"tapprestaties/wijzigen/:id"}
                            element={<PrivateRoute permissie={"wijzigTapprestatie"}><TapPrestatiesEditPage/></PrivateRoute>}
                        />
                    </Routes>
                </AnimatePresence>
            </TabContainerAnimation>
        </div>
    );
}

export default TappenStack;
