import React from 'react';
import {motion} from "framer-motion";

function TabContainerAnimation({children}) {
    const variants = {
        "shown": {
            display: "block",
            opacity: [0, 1],
            y: [-100, 0]
        },
        "hidden": {
            opacity: [1, 0],
            y: [0, 100],
            transitionEnd: {display: "none"}
        }
    }

    return (
        <motion.div
            variants={variants}
            initial={"hidden"}
            animate={"shown"}
            exit={"hidden"}
            transition={{ delay:.2, duration: 0.4 }}
        >
            <div className="container px-6 mt-28 mb-10 md:mt-16 md:mb-12 ">
                {children}
            </div>
        </motion.div>
    );
}

export default TabContainerAnimation;
