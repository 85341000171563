import React from 'react';
import {validateAantal, validateBekertypeNaam, validateInhoud, validatePrijs} from "../../../util/validator";
import InputField from "../../global/forms/InputField";
import SubmitButton from "../../global/forms/SubmitButton";
import {useAlerts} from "../../global/alerts/AlertContext";
import BackButton from "../../global/forms/BackButton";

function BekertypeForm({bekertype, setBekertype, isLoading, action, buttonLabel}) {
    const {setAlert} = useAlerts()

    const onChange = (e) => {
        setBekertype(old => ({...old, [e.target.name]: e.target.value}))
    }

    const onClick = (e) => {
        e.preventDefault()
        if (controlData()) {
            action(bekertype)
        }
    }

    const controlData = () => {
        const naam = validateBekertypeNaam(bekertype.naam)
        if (!naam) {
            setAlert("Ongeldige bekertypenaam.", "ERROR")
            return false
        }
        const aantalBekers = validateAantal(bekertype.aantalBekersPerDoos)
        if (!aantalBekers) {
            setAlert("Ongeldig aantal bekers per doos.", "ERROR")
            return false
        } else {
            setBekertype(old => ({...old, aantalBekersPerDoos: parseInt(aantalBekers)}))
        }
        const huurprijs = validatePrijs(bekertype.huurprijs)
        if (!huurprijs || huurprijs < 0) {
            setAlert("Ongeldige huurprijs.", "ERROR")
            return false
        } else {
            setBekertype(old => ({...old, huurprijs: parseFloat(huurprijs)}))
        }
        const waarborg = validatePrijs(bekertype.waarborgprijs)
        if (!waarborg || waarborg < 0) {
            setAlert("Ongeldige waarborg.", "ERROR")
            return false
        } else {
            setBekertype(old => ({...old, waarborgprijs: parseFloat(waarborg)}))
        }
        const inhoud = validateInhoud(bekertype.inhoud)
        if (!inhoud) {
            setAlert("Ongeldige inhoud.", "ERROR")
            return false
        } else {
            setBekertype(old => ({...old, inhoud: parseInt(inhoud)}))
        }
        return true
    }

    return (
        <form>
            <div className={"mt-5 grid xl:grid-cols-2 xl:gap-6"}>
                <div className={"w-full"}>
                    <InputField
                        label={"Naam"}
                        name={"naam"}
                        type={"text"}
                        placeholder={"Naam..."}
                        value={bekertype.naam}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Aantal bekers per doos"}
                        name={"aantalBekersPerDoos"}
                        type={"number"}
                        placeholder={"Aantal bekers..."}
                        value={bekertype.aantalBekersPerDoos}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Huurprijs (in €)"}
                        name={"huurprijs"}
                        type={"number"}
                        placeholder={"Huurprijs..."}
                        value={bekertype.huurprijs}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Waarborg (in €)"}
                        name={"waarborgprijs"}
                        type={"number"}
                        placeholder={"Waarborg..."}
                        value={bekertype.waarborgprijs}
                        onChange={onChange}
                    />
                </div>
                <div className={"w-full"}>
                    <InputField
                        label={"Bekerinhoud (in Cl)"}
                        name={"inhoud"}
                        type={"number"}
                        placeholder={"Inhoud..."}
                        value={bekertype.inhoud}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className={"flex flex-row"}>
                <BackButton
                    to={"/bekerverhuur/bekertypes"}
                />
                <SubmitButton
                    label={buttonLabel}
                    isLoading={isLoading}
                    onClick={onClick}
                />
            </div>
        </form>);
}

export default BekertypeForm;
