import React, {useEffect, useState} from 'react';
import EenheidModal from "./EenheidModal";
import useUpsertConsumptieEenheid from "../../../../data/dranken/assortiment/useUpsertConsumptieEenheid";
import useFetchEenheid from "../../../../data/dranken/assortiment/useFetchEenheid";

function EenheidEditModal({consumptieId, id, setShown, shown}) {
    const [eenheid, setEenheid] = useState({
        typeNaam: "",
        consumptieId: consumptieId,
        standaardInKoeling: 0,
        standaardInStock: 0,
        aankoopprijs: 0
    })
    const {data, isSuccess} = useFetchEenheid(id)
    const {mutate:addEenheid} = useUpsertConsumptieEenheid()

    useEffect(() => {
        if (isSuccess) {
            setEenheid(data)
        }
    }, [data, isSuccess])

    return (
        <EenheidModal setShown={setShown} shown={shown} eenheid={eenheid} setEenheid={setEenheid} title={"wijzigen"} action={addEenheid} update={true}/>
    );
}

export default EenheidEditModal;
