import React from 'react';
import Row from "../../../global/listitems/Row";
import NormalText from "../../../global/listitems/NormalText";
import Cell from "../../../global/listitems/Cell";
import BoldText from "../../../global/listitems/BoldText";

function TeBestellenListItem({eenheid}) {
    return (
        <Row>
            <Cell>
               <BoldText text={eenheid.consumptie}/>
            </Cell>
            <Cell>
                <NormalText text={eenheid.type}/>
            </Cell>
            <Cell>
                <NormalText text={`${eenheid.aantal} ${eenheid.suffix}`}/>
            </Cell>
        </Row>
    );
}

export default TeBestellenListItem;
