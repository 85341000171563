import React, {useState} from 'react';
import ShoppingBag from "../icons/ShoppingBag";
import {NavLink, useLocation} from "react-router-dom";
import {motion} from "framer-motion";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import Coffee from "../icons/Coffee";
import Droplet from "../icons/Droplet";

function SidebarDropDown({name = "Dropdown", icon = "Verhuur", sublink, links, hideMenu}) {
    const [isShown, setIsShown] = useState(false)
    const ref = useOnClickOutside(() => setIsShown(false))
    const {pathname} = useLocation()

    const icons = {
        "Verhuur": <ShoppingBag className={`inline w-6 h-6 stroke-current dark:stroke-gray-300`}/>,
        "Dranken": <Coffee className={`inline w-6 h-6 stroke-current dark:stroke-gray-300`}/>,
        "Tappen": <Droplet className={`inline w-6 h-6 stroke-current dark:stroke-gray-300`}/>,
    }

    const variantsArrow = {
        open: {
            rotateZ: [0, 180],
            transition: {
                duration: .2
            },
        },
        closed: {
            rotateZ: [180, 0],
            transition: {
                duration: .2
            },
        }
    }

    const variantsText = {
        open: {
            strokeWidth: [2, 3],
            fontWeight: [400, 500]
        },
        closed: {
            strokeWidth: [3, 2],
            fontWeight: [500, 400],
        }
    }

    const variantsSubmenu = {
        open: {
            display: "block",
            opacity: [0, 1],
            transition: {
                duration: .2
            },
        },
        closed: {
            opacity: [1, 0],
            transition: {
                duration: .2
            },
            transitionEnd: {display: "none"},
        }
    }

    const toggleShown = () => {
        setIsShown(old => !old)
    }

    return (
        <div className="relative mb-4" ref={ref}>
            <button onClick={toggleShown}
                    className={`duration-300 w-full md:w-44 pl-2 p-1.5 mb-2 text-sm text-left rounded-lg md:block ${pathname.includes(sublink) ? "text-gray-900 bg-gray-200 dark:text-gray-300 dark:bg-gray-700" : ""} dark:text-gray-300 dark:hover:text-gray-300 dark:hover:bg-gray-700 dark:focus:text-gray-300 dark:focus:bg-gray-700 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
            >
                <div className={"flex flex-row justify-between"}>
                    <motion.div
                        variants={variantsText}
                        initial={"closed"}
                        animate={pathname.includes(sublink) ? "open" : "closed"}
                    >
                        {icons[icon]}
                        <span className={`text-sm ml-2 ${isShown && "font-medium"}`}>{name}</span>
                    </motion.div>
                    <motion.div
                        variants={variantsArrow}
                        initial={"closed"}
                        animate={isShown ? "open" : "closed"}
                    >
                        <svg fill="currentColor"
                             viewBox="0 0 20 20"
                             className="inline align-middle w-5 h-5"
                        >
                            <path fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"/>
                        </svg>
                    </motion.div>
                </div>
            </button>
            <motion.div
                variants={variantsSubmenu}
                initial={"closed"}
                animate={isShown ? "open" : "closed"}
                className={`absolute left-0 w-full rounded-md shadow-lg z-20 md:w-44`}
            >
                <div
                    className="px-2 py-2 bg-white rounded-md shadow dark:bg-gray-700"
                >
                    {links.map(l => (
                        <NavLink
                            onClick={() => {hideMenu(); setIsShown(false)}}
                            key={l.title}
                            className={`block px-4 py-2 mt-2 text-sm bg-transparent rounded-lg md:mt-0 ${pathname.includes(l.to) ? "text-gray-900 bg-gray-200 dark:text-gray-300 dark:bg-gray-600" : ""} hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline my-0.5 dark:text-gray-300 dark:hover:text-gray-300 dark:hover:bg-gray-600 dark:focus:text-gray-300 dark:focus:bg-gray-600`}
                            to={sublink + l.to}
                        >{l.title}</NavLink>
                    ))}
                </div>
            </motion.div>
        </div>);
}

export default SidebarDropDown;
