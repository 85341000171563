import React from 'react';

function RoundText({text, color}) {
    const colorStyles = {
        red: 'text-red-600 dark:text-red-900 bg-red-100 dark:bg-red-400',
        green: 'text-green-600 dark:text-green-900 bg-green-100 dark:bg-green-400',
        orange: 'text-orange-600 dark:text-orange-900 bg-orange-100 dark:bg-orange-400',
    }

    return (
        <button
            disabled
            className={`text-sm leading-none ${colorStyles[color]} mr-1 p-3 w-10 h-10 rounded-full`}
        >
            {text}
        </button>
    );
}

export default RoundText;
