import React, {useState} from 'react';
import Cell from "../../global/listitems/Cell";
import BoldTextLoading from "../../global/listitems/loading/BoldTextLoading";
import NormalTextLoading from "../../global/listitems/loading/NormalTextLoading";
import {randomIntFromInterval} from "../../../util/formatter";
import RoundImageLoading from "../../global/listitems/loading/RoundImageLoading";
import RoundTextLoading from "../../global/listitems/loading/RoundTextLoading";
import OptionsButtonLoading from "../../global/listitems/loading/OptionsButtonLoading";
import Row from "../../global/listitems/Row";

function TapPrestatieListLoading() {
    const [tappers] = useState([...Array(randomIntFromInterval(1, 5)).keys()])

    return (
        <Row loading={true}>
            <Cell>
                <BoldTextLoading width={"w-[90px]"}/>
            </Cell>
            <Cell>
                <NormalTextLoading width={Math.random() < .7 ? "w-[57px]" : "w-[67px]"}/>
            </Cell>
            <Cell>
                {tappers.map(index => (
                    <div key={index} className={"border-white dark:border-gray-800 border-2 rounded-full -ml-4"}>
                        <RoundImageLoading/>
                    </div>
                ))}
            </Cell>
            <Cell>
                <NormalTextLoading/>
            </Cell>
            <Cell>
                <RoundTextLoading/>
                <RoundTextLoading/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButtonLoading/>
            </Cell>
        </Row>
    );
}

export default TapPrestatieListLoading;
