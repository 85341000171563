import React from 'react';
import {useNavigate} from "react-router-dom";
import moment from "moment";
import Row from "../../global/listitems/Row";
import Cell from "../../global/listitems/Cell";
import RoundImage from "../../global/listitems/RoundImage";
import NormalText from "../../global/listitems/NormalText";
import BoxText from "../../global/listitems/BoxText";
import OptionsButton from "../../global/listitems/OptionsButton";
import useCheckPermissies from "../../../hooks/useCheckPermissies";
import useDeleteTapweek from "../../../data/tappen/tapkalender/useDeleteTapweek";

function TapkalenderListItem({tapweek}) {
    const navigate = useNavigate()
    const {mutate: verwijderTapweek} = useDeleteTapweek(tapweek.jaar, tapweek.weeknummer)
    const periode = `${moment().year(tapweek.jaar).isoWeek(tapweek.weeknummer).startOf('isoWeek').format("DD/MM/YYYY")} - ${moment().year(tapweek.jaar).isoWeek(tapweek.weeknummer).endOf('isoWeek').format("DD/MM/YYYY")}`

    const options = [
        {
            action: () => navigate(`/tappen/tapkalender/wijzigen/${tapweek.jaar}/${tapweek.weeknummer}`),
            label: "Wijzig",
            condition: useCheckPermissies("upsertTapweek")
        }, {
            action: verwijderTapweek,
            label: "Verwijder",
            condition: useCheckPermissies("verwijderTapweek")
        }
    ]

    return (
        <Row>
            <Cell>
                <RoundImage source={tapweek.bestuurslid.afbeelding} alt={tapweek.bestuurslid.voornaam} profilepic={true}/>
            </Cell>
            <Cell>
                <NormalText text={`${tapweek.bestuurslid.voornaam} ${tapweek.bestuurslid.familienaam}`}/>
            </Cell>
            <Cell>
                <NormalText text={periode}/>
            </Cell>
            <Cell>
                <BoxText text={`Week ${tapweek.weeknummer}`} width={"w-20"}/>
            </Cell>
            <Cell>
                <NormalText text={tapweek.opmerking}/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButton options={options}/>
            </Cell>
        </Row>
    );
}

export default TapkalenderListItem;
