import React from 'react';
import { Navigate } from 'react-router-dom'
import useAuth from "../../hooks/useAuth";
import useGetPermissies from "../../hooks/useGetPermissies";
import ErrorPage from "../../pages/ErrorPage";

const PrivateRoute = ({ children, permissie }) => {
    const isAuthenticated = useAuth();
    const permissies = useGetPermissies()
    if (isAuthenticated && permissie) {
        return permissies.includes(permissie) ? children : <ErrorPage header={false} errormsg={"Error 401: Onvoldoende rechten."}/>
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
