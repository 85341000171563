import React from 'react';
import ListContainer from "../../../components/global/ListContainer";
import useFetchTapPrestaties from "../../../data/tappen/tapprestaties/useFetchTapPrestaties";
import TapPrestatieListItem from "../../../components/tappen/tapprestaties/TapPrestatieListItem";
import useCheckPermissies from "../../../hooks/useCheckPermissies";
import TapPrestatieListLoading from "../../../components/tappen/tapprestaties/TapPrestatieListLoading";
import TapPrestatieHeaderRow from "../../../components/tappen/tapprestaties/TapPrestatieHeaderRow";

function TapPrestatiesListPage() {
    return (
        <ListContainer
            type={"Tapprestaties"}
            addPath={"/tappen/tapprestaties/toevoegen"}
            addPermissie={useCheckPermissies("createTapprestatie")}
            dataFunction={useFetchTapPrestaties}
            useFilter={true}
            header={<TapPrestatieHeaderRow/>}
            listItemLambda={tapprestatie => (
                <TapPrestatieListItem key={tapprestatie.id} tapprestatie={tapprestatie}/>
            )}
            placeholderLambda={index => (
                <TapPrestatieListLoading key={index}/>
            )}
        />
    );
}

export default TapPrestatiesListPage;
