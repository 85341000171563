import {useMutation, useQueryClient} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const createReservatie = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/reservaties`, data, {headers: authHeader()})
}

export const useCreateReservatie = () => {
    const {setAlert} = useAlerts()
    const queryClient = useQueryClient()
    return useMutation('createReservatie', createReservatie, {
        onSuccess: () => {
            setAlert("Reservatie succesvol geplaatst!", "SUCCESS")
            queryClient.invalidateQueries('fetchReservaties')
        },
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        }
    })
}
