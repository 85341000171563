import React from 'react';
import HeaderRow from "../../global/listitems/HeaderRow";
import Cell from "../../global/listitems/Cell";
import BoldText from "../../global/listitems/BoldText";

function ReservatieDoosHeader() {
    return (
        <HeaderRow>
            <Cell>
                <BoldText text={"Doosnaam"}/>
            </Cell>
            <Cell>
                <BoldText text={"Type"}/>
            </Cell>
            <Cell>
                <BoldText text={"Aantal Bekers"}/>
            </Cell>
            <Cell>
                <BoldText text={"Huurprijs"}/>
            </Cell>
            <Cell>
                <BoldText text={"Waarborg"}/>
            </Cell>
            <Cell>
                <BoldText text={"Totaal"}/>
            </Cell>
        </HeaderRow>
    );
}

export default ReservatieDoosHeader;
