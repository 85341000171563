import React from 'react';
import Row from "../../global/listitems/Row";
import Cell from "../../global/listitems/Cell";
import BoldText from "../../global/listitems/BoldText";
import moment from "moment";
import BoxText from "../../global/listitems/BoxText";
import NormalText from "../../global/listitems/NormalText";
import OptionsButton from "../../global/listitems/OptionsButton";
import RoundImage from "../../global/listitems/RoundImage";
import Tooltip from "../../global/listitems/Tooltip";
import {useNavigate} from "react-router-dom";
import useDeleteDrankVdMaand from "../../../data/dranken/drankvdmaand/useDeleteDrankVdMaand";
import useCheckPermissies from "../../../hooks/useCheckPermissies";

function DrankVdMaandListItem({drank}) {
    const navigate = useNavigate()
    const periode = moment().year(drank.jaartal).month(drank.maand - 1).format("MM/YYYY")
    const omschrijving = drank.omschrijving.length > 40 ? `${drank.omschrijving.substring(0,40)}...` : drank.omschrijving
    const {mutate: deleteDrank} = useDeleteDrankVdMaand(drank.jaartal, drank.maand)
    const options = [
        {
            action: () => navigate(`/dranken/drankenvdmaand/${drank.jaartal}/${drank.maand}/wijzigen`),
            label: "Wijzig",
            condition: useCheckPermissies("upsertDrankVdMaand")
        },
        {
            action: () => deleteDrank(),
            label: "Verwijder",
            condition: useCheckPermissies("verwijderDrankVdMaand")
        }
    ]
    return (
        <Row>
            <Cell>
                <RoundImage alt={drank.naam} source={drank.afbeelding}/>
            </Cell>
            <Cell>
                <BoldText text={drank.naam}/>
            </Cell>
            <Cell>
                <Tooltip tooltipText={"Periode"}>
                    <BoxText text={periode} width={"w-24"}/>
                </Tooltip>
            </Cell>
            <Cell>
                <NormalText text={`€ ${drank.prijs.toFixed(2).replace(".",",")}`}/>
            </Cell>
            <Cell>
                <NormalText text={omschrijving}/>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButton options={options}/>
            </Cell>
        </Row>
    );
}

export default DrankVdMaandListItem;
