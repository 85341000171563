import React, {Fragment} from 'react';
import TabContainer from "../global/TabContainer";
import {useFetchUpcomingEvenementen} from "../../data/evenementen/useFetchUpcomingEvenementen";
import Button from "../global/Button";
import {useNavigate} from "react-router-dom";
import UpcomingEvenementenListItem from "./UpcomingEvenementenListItem";
import UpcomingEvenementenListLoading from "./UpcomingEvenementenListLoading";

function UpcomingEvenementen() {
    const {data: evenementen, isSuccess, isLoading, isError} = useFetchUpcomingEvenementen()
    const navigate = useNavigate()

    return (
        <TabContainer>
            <h4 className={"mb-6"}>Upcoming evenementen</h4>
            <div className="overflow-y-auto overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                    <tbody>
                    {isLoading && (<Fragment>
                        <UpcomingEvenementenListLoading/>
                        <UpcomingEvenementenListLoading/>
                    </Fragment>)}
                    {(!isLoading && isSuccess && evenementen.length > 0) && evenementen.map(ev => (
                        <UpcomingEvenementenListItem key={ev.naam} evenement={ev}/>))}
                    {!isLoading && isSuccess && evenementen.length === 0 && (
                        <tr>
                            <td>
                                <p className={"text-gray-800 dark:text-gray-200"}>Geen evenementen gevonden.</p>
                                <Button
                                    label={"Evenement toevoegen"}
                                    className={"mt-6"}
                                    onClick={() => navigate("/evenementen")}
                                />
                            </td>
                        </tr>
                    )}
                    {(!isLoading && isError) && (
                        <tr>
                            <td>
                                <p className={"text-gray-800 dark:text-gray-200"}>Oeps! Er liep iets mis bij het ophalen van de evenementen.</p>
                            </td>
                        </tr>

                    )}
                    </tbody>
                </table>
            </div>
        </TabContainer>
    );
}

export default UpcomingEvenementen;
