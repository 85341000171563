import {useQuery} from "react-query";
import axios from "axios";
import authHeader from "../../../util/auth-header";
import {useAlerts} from "../../../components/global/alerts/AlertContext";

const fetchStocktelling = (id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/stocktellingen/${id}`, {headers: authHeader()})
}

function useFetchStocktelling(id) {
    const {setAlert} = useAlerts()
    return useQuery(['fetchStocktelling', id], () => fetchStocktelling(id), {
        onError: (error) => {
            setAlert(error?.response?.data?.message, "ERROR")
        },
        select: (data => data.data)
    })
}

export default useFetchStocktelling
