import React from 'react';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import {QueryParamProvider} from 'use-query-params';
import { createRoot } from 'react-dom/client';
import {ReactRouter6Adapter} from "use-query-params/adapters/react-router-6";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Router>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <App/>
            </QueryParamProvider>
        </Router>
    </React.StrictMode>
);
