import React from 'react';
import Row from "../../global/listitems/Row";
import Cell from "../../global/listitems/Cell";
import BoldText from "../../global/listitems/BoldText";
import moment from "moment";
import NormalText from "../../global/listitems/NormalText";
import RoundText from "../../global/listitems/RoundText";
import RoundImage from "../../global/listitems/RoundImage";
import OptionsButton from "../../global/listitems/OptionsButton";
import {useNavigate} from "react-router-dom";
import useChangeTapPrestatieStatus from "../../../data/tappen/tapprestaties/useChangeTapPrestatieStatus";
import Tooltip from "../../global/listitems/Tooltip";
import useDeleteTapPrestatie from "../../../data/tappen/tapprestaties/useDeleteTapPrestatie";
import useCheckPermissies from "../../../hooks/useCheckPermissies";
import useChangeTapPrestatieTapminimum from "../../../data/tappen/tapprestaties/useChangeTapPrestatieTapminimum";

function TapPrestatieListItem({tapprestatie}) {
    const navigate = useNavigate()
    const {mutate: changeStatus} = useChangeTapPrestatieStatus()
    const {mutate: changeTapminimum} = useChangeTapPrestatieTapminimum()
    const {mutate: deletePrestatie} = useDeleteTapPrestatie(tapprestatie.id)
    const options = [
        {
            action: () => navigate(`/tappen/tapprestaties/wijzigen/${tapprestatie.id}`),
            label: "Wijzig",
            condition: useCheckPermissies("wijzigTapprestatie")
        }, {
            action: () => changeStatus({id: tapprestatie.id}),
            label: "Markeer als ontvangen",
            condition: useCheckPermissies("wijzigTapprestatieOntvangenstatus")
        }, {
            action: () => changeTapminimum({id: tapprestatie.id, tapminimum: !tapprestatie.tapminimum}),
            label: tapprestatie.tapminimum ? "Verwijderen uit tapminimum" : "Toevoegen aan tapminimum",
            condition: useCheckPermissies("wijzigTapprestatieTapminimum")
        }, {
            action: () => deletePrestatie(tapprestatie.id),
            label: "Verwijder",
            condition: useCheckPermissies("verwijderTapprestatie")
        }
    ]

    return (
        <Row>
            <Cell>
                <BoldText text={moment(tapprestatie.datum).format("DD/MM/YYYY")}/>
            </Cell>
            <Cell>
                <NormalText text={`€ ${tapprestatie.inkomsten.toFixed(2).replace(".", ",")}`}/>
            </Cell>
            <Cell>
                {tapprestatie.bestuursleden.map(bl => (
                    <Tooltip key={bl.bestuurslid.voornaam}
                             tooltipText={`${bl.bestuurslid.voornaam} ${bl.bestuurslid.familienaam}`} left={-20}>
                        <div className={"border-white dark:border-gray-800 border-2 rounded-full -ml-4"}>
                            <RoundImage source={bl.bestuurslid.afbeelding} alt={bl.bestuurslid.voornaam} profilepic={true}/>
                        </div>
                    </Tooltip>
                ))}
            </Cell>
            <Cell>
                <NormalText text={tapprestatie.opmerking ? (tapprestatie.opmerking.length > 80 ? tapprestatie.opmerking.substring(0,80).trimEnd() + "..." : tapprestatie.opmerking) : "Geen opmerkingen"}/>
            </Cell>
            <Cell>
                {tapprestatie.geldmee && (
                    <Tooltip tooltipText={`${tapprestatie.geldmee.voornaam} ${tapprestatie.geldmee.familienaam}`} left={-20}>
                    <div className={"border-white dark:border-gray-800 border-2 rounded-full -ml-4"}>
                    <RoundImage source={tapprestatie.geldmee.afbeelding} alt={tapprestatie.geldmee.voornaam} profilepic={true}/>
                    </div>
                    </Tooltip>
                    )}
            </Cell>
            <Cell>
                <Tooltip tooltipText={tapprestatie.betaald ? "Ontvangen" : "Nog niet ontvangen"}>
                    <RoundText text={'O'} color={tapprestatie.betaald ? "green" : "red"}/>
                </Tooltip>
                <Tooltip tooltipText={tapprestatie.tapminimum ? "In tapminimum" : "Niet in tapminimum"}>
                    <RoundText text={'T'} color={tapprestatie.tapminimum ? "green" : "red"}/>
                </Tooltip>
            </Cell>
            <Cell justify={"justify-end"}>
                <OptionsButton options={options}/>
            </Cell>
        </Row>
    );
}

export default TapPrestatieListItem;
