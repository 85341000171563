import React from 'react';
function Cell({children, justify = "justify-start", className = ""}) {
    return (
        <td>
            <div className={`flex px-5 ${justify} ${className}`}>
                {children}
            </div>
        </td>
    );
}

export default Cell;
