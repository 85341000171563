import React, {useEffect, useState} from 'react';
import TabContainer from "../../../components/global/TabContainer";
import {useNavigate, useParams} from "react-router-dom";
import useFetchBekertype from "../../../data/bekerverhuur/bekertypes/useFetchBekertype";
import BekertypeForm from "../../../components/bekerverhuur/bekertypes/BekertypeForm";
import useUpdateBekertype from "../../../data/bekerverhuur/bekertypes/useUpdateBekertype";

function BekertypeEditPage() {
    const {naam: oldNaam} = useParams();
    console.log(oldNaam)
    const [bekertype, setBekertype] = useState({
        oldNaam,
        naam: "",
        aantalBekersPerDoos: 0,
        huurprijs: 0,
        waarborgprijs: 0,
        inhoud: 0
    })
    const {isSuccess, data} = useFetchBekertype(oldNaam)
    const {mutate: updateBekertype, isLoading: isUpdating, isSuccess: isUpdated} = useUpdateBekertype()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            setBekertype({...data, oldNaam: oldNaam})
        }
    }, [isSuccess, data, oldNaam])

    useEffect(() => {
        if (isUpdated) navigate("/bekerverhuur/bekertypes")
    }, [isUpdated, navigate])

    return (
        <TabContainer>
            <h4>Bekertype wijzigen</h4>
            <BekertypeForm
                buttonLabel={"Wijzigen"}
                bekertype={bekertype}
                setBekertype={setBekertype}
                action={updateBekertype}
                isLoading={isUpdating}
            />
        </TabContainer>
    );
}

export default BekertypeEditPage;
