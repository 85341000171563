import React from 'react';

function DrankVdMaandLoading() {
    return (
        <div className={"animate-pulse"}>
            <div className={"h-72 w-56 my-4 mx-auto rounded-md bg-gray-100 dark:bg-gray-500"}/>
            <div className={"flex justify-between lg:mx-10 items-center"}>
                <div className={"bg-gray-100 dark:bg-gray-500 rounded w-[160px] h-[28px]"}/>
                <div className={"bg-gray-100 dark:bg-gray-500 rounded w-[70px] h-[40px]"}/>
            </div>
        </div>
    );
}

export default DrankVdMaandLoading;
